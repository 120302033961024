import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import ApiService from "../../../../Services/apiService";
import { validation } from "../../../../Util/Validation";

import FormInput from "../../../../Components/FormInput";
import DialogTemplate from "../../../../Components/DialogTemplate";

import home from "../../../../assets/main_css/home.module.css";
import main_css from "../../../../assets/main_css/main.module.css";


const ParentSignUpForm = ({ token, verifyToken = null, def_name = "", def_email = "" }) => {
  const navigate = useNavigate();
  const apiService = new ApiService();

  const montedRef = useRef(true)
  const [openDialog, setOpenDialog] = useState(null);
  const [parentName, setParentName] = useState(def_name);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(def_email);
  const [phone, setPhone] = useState("");
  const [school, setSchool] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassVali, setConfirmPassVali] = useState({
    pattern: validation.password.pattern,
    message: validation.password.message,
  });

  useEffect(() => {
    return () => {
      montedRef.current = false
    }
  }, [])

  // Utilized useState for the purpose of setting error message on incorrect email verification code
  const [verifCodeErr, setVerifCodeErr] = useState({
    initial: true,
    close: true,
    classes: null,
    message: null,
  });

  // Utilized useEffect for opening email_verif_dialog with error message after the state of verifCodeErr has been set
  useEffect(() => {
    if (verifCodeErr.initial === false && verifCodeErr.close === false) {
      email_verif_dialog();
    }
  }, [verifCodeErr]);

  useEffect(() => {
    if (password !== confirmPass) {
      setConfirmPassVali({
        pattern: "err",
        message: "Your passwords do not match",
      });
    } else {
      setConfirmPassVali({
        pattern: validation.password.pattern,
        message: validation.password.message,
      });
    }
  }, [password, confirmPass]);


  // Sets emailVerifCode to the users input
  let emailVerifCode = "";
  const setEmailVerifCode = (e) => {
    emailVerifCode = e;
  };
  // function to delay between dialogs to achive drop down effect on 2nd dialog
  const drop_dialog = (dialog) => {
    setTimeout(() => {
      dialog();
    }, 100);
  };

  // Set var for dialogs to keep code neat
  const email_verif_dialog = () => {
    setOpenDialog({
      mainText: "Confirm Your Email",
      mainTextClass: "fs-2 px-3",
      subText: "Please Enter the code that has been emailed to you.",
      subTextClass: "fs-2 px-3 pt-0",
      input: {
        type: "text",
        placeholder: "000000",
        classes: `dialog_input ${verifCodeErr.classes}`,
        onChangeHandler: setEmailVerifCode,
        message: verifCodeErr.message,
      },
      button1Text: "Confirm Email",
      onClickButton1: createAccount,
      closeHandler: () => {
        setOpenDialog(null);
        setVerifCodeErr({
          initial: false,
          close: true,
          classes: null,
          message: null,
        });
      },
      buttonClass: "pb-2",
      duration: "1000ms",
    });
  };

  // Set var for dialogs to keep code neat
  const user_exists_dialog = () => {
    setOpenDialog({
      mainText: `There is already an account associated with this email address`,
      mainTextClass: "fs-2 px-lg-3",
      subText: "Try logging in or use another email address.",
      button1Text: "Login",
      onClickButton1: () => navigate("/login"),
      button2Text: "Back To Sign-Up",
      onClickButton2: () => setOpenDialog(null),
    });
  };

  // Set var for dialogs to keep code neat
  const error_dialog = () => {
    setOpenDialog({
      mainText: "Oops!",
      subText:
        "Something went wronge!\nPlease check the informtion you entered and try again.",
      subTextClass: "px-lg-3",
      button1Text: "Back To Sign-Up",
      onClickButton1: () => setOpenDialog(false),
      closeHandler: () => setOpenDialog(false),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenDialog({
      mainText: "Loading.....\n Please Wait! ",
      closeHandler: () => setOpenDialog(null),
      duration: "0ms",
    });

    if (verifyToken) {
      createAccount();
    }
    else {
      try {
        const [emailVerifResp, error] = await apiService.post({
          path: "/email_verif_code",
          data: {
            username: email,
            name: parentName,
            token: token,
          },
          dataType: "sign_up",
        });

        //Sign up is locked and the user did not have a proper access token
        if (error && error.response.request.status === 401) {
          let token_err_dialog = {
            mainText: "Request Sent!",
            subText: "Thank you for signing up!\nWe are reviewing your account and will email you shortly with your new account!",
            button1Text: "Back To Home Page",
            onClickButton1: () => navigate("/"),
          };
          if (token) {
            if (error.response.data.message === 'invalid') {
              token_err_dialog = {
                mainText: "Uh Oh!",
                subText: "Your link seems to be working!\n Please contact support for a new link",
                button1Text: "Back To Home Page",
                onClickButton1: () => navigate("/"),
              };
            }
            else if (error.response.data.message === 'expired') {
              token_err_dialog = {
                mainText: "Uh Oh!",
                subText: `Your link seems to be expired!\nPlease contact\n${error.response.data.email}\nfor a new link`,
                button1Text: "Back To Home Page",
                onClickButton1: () => navigate("/"),
              };
            }
            else if (error.response.data.message === 'notFound') {
              token_err_dialog = {
                mainText: "Uh Oh!",
                subText: `We could not find your special link!\nPlease contact\n${error.response.data.email}\nfor a new link`,
                button1Text: "Back To Home Page",
                onClickButton1: () => navigate("/"),
              };
            }
          }
          setOpenDialog(null);
          drop_dialog(() => setOpenDialog(token_err_dialog));
          setTimeout(() => {
            if (montedRef.current) navigate("/");
          }, 15000);
        }
        // After response first setOpenDialog is set to null to close previous dialog and together with the drop_dialog function the 2nd dialog has a drop down effect
        else if (emailVerifResp && emailVerifResp.status === 200) {
          setOpenDialog(null);
          drop_dialog(email_verif_dialog);
        } else if (
          error.response &&
          error.response.data.message === "User exists"
        ) {
          setOpenDialog(null);
          drop_dialog(user_exists_dialog);
        } else {
          setOpenDialog(null);
          drop_dialog(error_dialog);
        }
      } catch (error) {
        console.log(error);
        setOpenDialog(null);
        drop_dialog(error_dialog);
      }
    }
  };

  const createAccount = async (event) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    setOpenDialog(null);
    setOpenDialog({
      mainText: "Hang Tight \n while We Sign You Up!",
    });
    try {
      const [signUpResp, error] = await apiService.post({
        path: "/parent",
        data: {
          code: emailVerifCode,
          verifyToken: verifyToken,
          name: parentName,
          username: email,
          password: password,
          phone: phone,
          school: school,
          role: "parent",
        },
        dataType: "sign_up",
      });

      if (signUpResp && signUpResp.status === 200) {
        Cookies.set('user', JSON.stringify(signUpResp.data), { expires: 1 });
        setOpenDialog(null);
        drop_dialog(() =>
          setOpenDialog({
            mainText: `Welcome ${parentName}!`,
            subText: "Thank you for joining Super Me!",
          })
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 4000);
      }
      // if incorrect code was entered the setVerifcodeErr will be set with the key "classes" and the value "err" which is passed to the email_verif_dialog function which passes that to the dialog component as a prop
      else if (
        error.response &&
        error.response.data.message === "Incorrect email verification Code"
      ) {
        setVerifCodeErr({
          initial: false,
          close: false,
          classes: "err",
          message: "Incorrect Code",
        });
      } else {
        setOpenDialog(null);
        drop_dialog(error_dialog);
      }
    } catch (error) {
      setOpenDialog(null);
      drop_dialog(error_dialog);
    }
  };

  const mainContent = (
    <div className={`${home.form_box_main} ${home.teacher_signup} ps-sm-3`}>
      <form className={home.black} onSubmit={handleSubmit} id="submit_parent">
        <div className="row">
          <div className="col-12" style={{ marginTop: '2%' }}>
            <div className="form-group ajax-field">
              <FormInput
                type={"text"}
                value={parentName}
                placeholder={"Parent Name"}
                name={"name"}
                onChangeHandler={setParentName}
                message={validation.name.message}
                pattern={validation.name.pattern}
              />
            </div>
          </div>
          <div className="col-12" style={{ marginTop: '2%' }}>
            <div className="form-group ajax-field">
              <FormInput
                type={"text"}
                value={email}
                placeholder={"Parent Email"}
                name={"email"}
                onChangeHandler={setEmail}
                pattern={validation.email.pattern}
                message={validation.email.message}
              />
            </div>
          </div>
          <div className="col-12" style={{ marginTop: '2%' }}>
            <div className="form-group ajax-field">
              <FormInput
                type={"password"}
                value={password}
                placeholder={"Create Password"}
                name={"password"}
                onChangeHandler={setPassword}
                pattern={validation.password.pattern}
                message={validation.password.message}
              />
            </div>
          </div>
          <div className="col-12" style={{ marginTop: '2%' }}>
            <div className="form-group ajax-field">
              <FormInput
                type={"password"}
                value={confirmPass}
                placeholder={"Confirm Password"}
                name={"confirmPass"}
                onChangeHandler={setConfirmPass}
                pattern={confirmPassVali.pattern}
                message={confirmPassVali.message}
              />
            </div>
          </div>
          {/* <div className="col-12 ajax-field">
            <FormInput
              type={"tel"}
              value={phone}
              placeholder={"Phone Number"}
              classes={`${home.form_control} parent_form mb-0`}
              onChangeHandler={setPhone}
              name={"phone"}
              required={false}
            />
          </div> */}
          {/* <div className="col-12 ajax-field">
            <FormInput
              type={"text"}
              value={school}
              name={"school_name"}
              placeholder={"School Name"}
              onChangeHandler={setSchool}
              classes={`${home.form_control} parent_form mb-0`}
              required={false}
            />
          </div> */}
        </div>
        <div className="text-center pt-2">
          <button
            type="submit"
            id="parent_update"
            className={main_css.basic_btnInv}
            style={{ padding: '5px 10px' }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {openDialog && <DialogTemplate {...openDialog} />}
      {mainContent}
    </>
  );
};

export default ParentSignUpForm;
