import { React, useContext } from "react";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";

import { InfoContext } from "../AdminCampaignsPage";
import useUpdateInfo from "../../../Hooks/useUpdateInfo";
import useUpdateCampaign from "../../../Hooks/useUpdateCampaign";

const participatingGradesTableSetup = [
  { accessorKey: "grade", header: "Grade" },
  { accessorKey: "studentGradeCount", header: "Students" },
  { accessorKey: "gradeProgress", header: "Grade Progress" },
];

const GradeTable = ({ campaignId, gradeTable, role }) => {
  const { setCurrentCampaign } = useContext(InfoContext);
  const updateInfo = useUpdateInfo();
  const updateCampaignContingencies = useUpdateCampaign();

  const route = "/update_campaign_stats";
  const arrayFilters = [{ "campaign._id": campaignId }];
  const onSaveHandler = ({
    itemId,
    path,
    newValue,
    noneTableContingencies,
  }) => {
    updateCampaignContingencies(
      setCurrentCampaign,
      noneTableContingencies,
      newValue
    );
    updateInfo(route, itemId, path, newValue, arrayFilters);
  };
  return (
    <MissionsTable
      title="Participating Grades"
      tableSetup={participatingGradesTableSetup}
      data={gradeTable}
      editable={role !== "parent"}
      onSaveHandler={onSaveHandler}
    />
  );
};

export default GradeTable;
