import axios from "axios";
import { backend_url, basePaths } from "../config";

export default class ApiService {
  constructor(auth = false, baseUrl = backend_url) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({ withCredentials: true });
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          window.location.pathname !== "/login" &&
          window.location.pathname.startsWith !== "/sign-up"
        ) {
          window.location.href = "/login"; // Redirect to login page
        }
        return Promise.reject(error);
      }
    );
    // if (auth){
    // axios.defaults.headers.common["session_token"] = `${Cookies.get("session_token")}`;
    // }
  }

  /*
   * For the following 4 functions, the inputs and outputs are defined as follows:
   *
   * @param dataType:  The backend table we are sending the data to. The options are defined in config.js
   * @param path:      The path for getting, updating, creating or deleting
   * @param data:      The data to send to the backend
   *
   * @return result:   The data retrieved from backend or the error
   */
  async post({ path, data = {}, dataType = "" }) {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.post(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async patch({ path, data = {}, dataType = "" }) {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.patch(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async put({ path, data = {}, dataType = "" }) {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.put(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async get({ path, data = {}, dataType = "" }) {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.get(
        `${this.baseUrl}/${rootPath}${path}`,
        { params: data }
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async delete({ path, data = {}, dataType = "" }) {
    try {
      const rootPath = dataType ? basePaths[dataType] : dataType;
      const results = await this.instance.delete(
        `${this.baseUrl}/${rootPath}${path}`,
        { data: data }
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }
}
