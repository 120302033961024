import React from 'react';
import clsx from 'clsx';

import * as functionalCalc from '../../../../Util/functionalCalc';

import styles from '../questPage.module.scss';

import treasure from '../../../../assets/images/userSite/quests/quest-treasure.png';

const displayData = {
  questCompleted: {
    calc: "questCompleted",
    message: "You\'ve completed $ quests",
  },
}

export const QuestSideBoard = ({ data, statsConfig }) => {

  return (
    <div className={clsx(styles.sideBoard, styles.glassBg)}>
      <img src={treasure} className={styles.treasureImage} alt="" />
      <div className={styles.sideBoardContent}>
        <h2>Quest</h2>
        <div className={`${styles.sideBoardStats} ${styles.whiteScrollBar}`} style={{ display: 'grid', paddingLeft: '2%', paddingRight: '5%', paddingTop: '5%' }}>

          {/* Config Based Stats */}
          {Object.keys(statsConfig).map((key, index) => {
            const statSplit = statsConfig[key].message.split('$');
            return (
              <>
                <div key={key} className={styles.sideBoardStatsGroup}>
                  <p style={{ flex: '1' }}>{statSplit[0]}</p>
                  <p style={{ flex: '2', textAlign: 'end' }}>{functionalCalc.evaluateField(statsConfig[key].calc, data)} {statSplit[1]}</p>
                </div>

                <div style={{ height: '2px', width: '90%', backgroundColor: 'rgba(255, 255, 255, 0.35)', margin: 'auto' }}></div>
              </>);
          })}

          {/* General Stats */}
          {Object.keys(displayData).map((key, index) => {
            const statSplit = displayData[key].message.split('$');
            return (
              <>
                <div key={key} className={styles.sideBoardStatsGroup}>
                  <p style={{ flex: '1' }}>{statSplit[0]}</p>
                  <p style={{ flex: '2', textAlign: 'end' }}>{functionalCalc.evaluateField(displayData[key].calc, data)} {statSplit[1]}</p>
                </div>

                {index < Object.keys(displayData).length - 1 && (
                  <div style={{ height: '2px', width: '90%', backgroundColor: 'rgba(255, 255, 255, 0.35)', margin: 'auto' }}></div>
                )}
              </>
            )
          })}
          <div style={{ height: '2px', width: '90%', backgroundColor: 'rgba(255, 255, 255, 0.35)', margin: 'auto' }}></div>
          <div className={styles.sideBoardStatsGroup} style={{ alignItems: 'flex-start' }}>
            <p style={{ flex: '1.5' }}>{"Till your next reward"}</p>
            <div style={{ flex: '2', textAlign: 'end' }}>{Object.keys(data.nextReward).map((key, index) => {
              if (data.nextReward[key].count !== Infinity) {
                return <p key={index}>{data.nextReward[key].count} {data.nextReward[key].description}</p>
              }
            })}</div>
          </div>

        </div>
      </div>
    </div>
  );
};

