import React from "react";

import cssModule from "./progressInfo.module.css";

const ProgressInfo = ({ progress, timeLeft, goal, barTitle }) => {
  const mainContent = (
    <div className={cssModule.mainDiv}>
      <div className={cssModule.flexContainer}>
        <div className={cssModule.stepsLeftMainDiv}>
          <div className={cssModule.stepsLeftText}>STEPS LEFT:</div>
          <div className={cssModule.stepsLeftValue}>
            {(goal - progress).toLocaleString()}
          </div>
        </div>
        <div className={cssModule.stepsTimeCounterMainDiv}>
          <table
            className={`table table-borderless ${cssModule.stepsTimeCounterTable}`}
          >
            <thead>
              <tr>
                <th className="text-center" scope="col">
                  STEPS
                </th>
                <th
                  style={{
                    borderRight: "5px solid rgb(149 178 249)",
                    borderRadius: "5px",
                    width: "1px",
                    height: "100%",
                    padding: "0px",
                  }}
                ></th>
                <th className="text-center" scope="col">
                  TIME LEFT
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">{progress.toLocaleString()}</td>
                <td
                  style={{
                    borderRight: "5px solid rgb(149 178 249)",
                    borderRadius: "5px",
                    height: "100%",
                    padding: "0px",
                  }}
                ></td>
                <td className="text-center">{timeLeft}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={cssModule.progressBarMainDiv}>
          <div
            className={cssModule.progressBarText}
            style={{
              textShadow:
                " 1px 1px 2px rgba(0, 0, 0, 0.7), -1px -1px 2px rgba(0, 0, 0, 0.7), 1px -1px 2px rgba(0, 0, 0, 0.7), -1px 1px 2px rgba(0, 0, 0, 0.7)",
              color: "rgb(255 255 255)",
              fontFamily: "sans-serif",
              fontWeight: "900",
            }}
          >{barTitle}</div>
          <div
            style={{
              width: "100%",
              height: "20px",
              border: "2px solid transparent",
              borderRadius: "10px",
              backgroundColor: "rgb(255 255 255)",
              margin: "10px 0px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: (progress / goal) * 100 + "%",
                borderRadius: "10px",
                backgroundColor: "rgb(67 93 168)",
                zIndex: "100",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{mainContent}</>;
};

export default ProgressInfo;
