import React, { useState } from "react";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import { Mission, RenderableContent, SetState } from "../../../../types";
import Overlay from "../../../../Components/overlay/Overlay";
import CreateGame from "./CreateGame";
import { CreatorData, CreatorGameboardConfig, GameboardNames } from "../types";
import useAddGameHandler from "../hooks/useAddGameHandler";

type Props = {
  gameboardConfigs: CreatorGameboardConfig[];
  gameboardNames: GameboardNames;
  currentMissions: Mission[];
  setCreatorData: SetState<CreatorData | null>;
};

const tableSetup = [
  { accessorKey: "name", header: "Name" },
  { accessorKey: "gameboardName", header: "Gameboard" },
  { accessorKey: "totalMissions", header: "Missions" },
];

const Games = ({
  gameboardConfigs,
  gameboardNames,
  currentMissions,
  setCreatorData,
}: Props) => {
  const addGameHandler = useAddGameHandler();
  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    content: RenderableContent;
  }>({ open: false, content: null });

  const onClickAddGameHandler = (
    game: Partial<
      Pick<CreatorGameboardConfig, "gameboardId" | "missionIds" | "name">
    >
  ) => {
    return addGameHandler(game, setOpenDialog, setCreatorData, gameboardNames);
  };

  const createGameDialog = (
    <CreateGame
      gameboardNames={gameboardNames}
      currentMissions={currentMissions}
      onClickAddGameHandler={onClickAddGameHandler}
    />
  );

  const displayAddGame = (): void => {
    setOpenDialog({
      open: true,
      content: createGameDialog,
    });
  };

  return (
    <>
      {openDialog.open && (
        <Overlay
          closeHandler={(close: boolean) =>
            setOpenDialog({
              open: close,
              content: null,
            })
          }
          content={openDialog.content}
        />
      )}
      <MissionsTable
        title="Games"
        tableSetup={tableSetup}
        data={gameboardConfigs}
        tooltip={{ value: "addIcon", action: displayAddGame }}
      />
    </>
  );
};

export default Games;
