
exports.validation = {
  name: {
    pattern: '^[A-Za-z ]*',
    message: 'Please enter a valid name'
  },
  email: {
    pattern: '^\\w[-A-Za-z0-9.]+@[a-zA-Z]+?\\.[a-zA-Z.]{2,}$',
    message: 'Please enter a valid email'
  },
  password: {
    pattern: '^[a-zA-Z0-9!@#$%^&*\\(\\)_+\\?\\-\\=\\[\\]\\{\\};\':"\\|,\\.<>\\/\\\\?]{6,}$',
    message: 'Please enter a password at least 6 characters long'
  },
  studentBodySize: {
    pattern: '^[0-9]*',
    message: 'Please enter a valid number'
  },
  grade: {
    pattern: '^[0-9]*',
    message: 'Please enter a valid grade'
  },
  phone: {
    pattern: '^[0-9-]{7,}$',
  }
}
