import React, { useState } from "react";
import { Dialog, TextField } from "@mui/material";

import missionsCSS from "../missionsPage.module.scss";

const images = {
  close: require("../../../../assets/images/close.png"),
  submit: require("../../../../assets/images/submit.png"),
};

const taskImages = require.context("../../../../assets/images/userSite/gameboard", true);

const ChooseDialog = ({ open, handleClose, task, handleCompleted, x, y }) => {
  const [answer, setAnswer] = useState("");
  const [selectedButton, setSelectedButton] = useState(null);
  var taskContent = "";

  const handleInputChange = (event) => {
    setAnswer(event.target.value);
  };

  const handleClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  switch (task["optionsType"]) {
    case "message":
      taskContent = (
        <div
          className={missionsCSS.modal_choose_task_message}
        >
          <TextField
            label={"Your Mission"}
            fullWidth
            className={missionsCSS.textField}
            InputLabelProps={{
              style: {
                color: "white",
              },
            }}
            inputProps={{
              style: {
                height: '3vh',
                color: 'white',
              },
            }}
            onChange={handleInputChange}
            variant="outlined"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "2px solid white",
                },
                "&:hover fieldset": {
                  border: "2px solid white",
                },
                "&.Mui-focused fieldset": {
                  border: "3px solid white",
                },
              },
            }}
          />
          <img
            className="task_mission_submit_btn"
            id=""
            onClick={() => {
              if (answer !== "") {
                handleCompleted(y, x, {
                  category: task["category"],
                  task: `${task["task"]} ${answer}`,
                  comDate: Date.now(),
                  rewardType: task["rewardType"],
                  rewardAmount: task["rewardAmount"],
                  image: task.image,
                });
              }
              setAnswer("");
            }}
            src={images["submit"]}
            alt="Close"
          />
        </div>
      );
      break;
    case "image":
      taskContent = (
        <div
          className={`${missionsCSS.modal_choose_task_image} ${missionsCSS.whiteScrollBar}`}
        >
          {Object.keys(task["options"]).map((key) => (
            <img
              className="taskmission-img"
              src={taskImages(`./${task["options"][key]}`)}
              key={key}
              alt={key}
              onClick={() => {
                handleCompleted(y, x, {
                  category: task["category"],
                  task: `${task["task"].replace("___", key)}`,
                  comDate: Date.now(),
                  rewardType: task["rewardType"],
                  rewardAmount: task["rewardAmount"],
                  image: task.image,
                });
              }}
            />
          ))}
        </div>
      );
      break;
    case 'solo':
      taskContent = (
        <div
          className={missionsCSS.modal_choose_task_message}
        >

          <button
            className={`${missionsCSS.soloTaskButtons} ${selectedButton === 'did' ? missionsCSS.soloTaskSelected : missionsCSS.soloTaskDeselected}`}
            onClick={() => {
              handleClick('did');
              handleCompleted(y, x, {
                category: task["category"],
                task: task["task"],
                comDate: Date.now(),
                rewardType: task["rewardType"],
                rewardAmount: task["rewardAmount"],
                image: task.image,
              });
              setSelectedButton(null);
            }
            }
          >
            I did it!
          </button>
          {/* <button
              className={`${missionsCSS.soloTaskButtons} ${selectedButton === 'didNot' ? missionsCSS.soloTaskSelected : missionsCSS.soloTaskDeselected}`}
              onClick={() => handleClick('didNot')}
            >
              I did not do it
            </button> */}
        </div>
      );
      break;
    default:
      taskContent = <div></div>;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: missionsCSS.mission_modal_dialog,
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          justifyContent: 'center',
        },
      }}
    >
      <div className={missionsCSS.modal_content}>
        <div className={missionsCSS.close_btn} onClick={() => {
          setAnswer("");
          setSelectedButton(null);
          handleClose("choose");
        }} alt="Close">&#10005;</div>
        <div className={missionsCSS.modal_details}>
          <div className={missionsCSS.modalCat}>
            {task["category"]}
          </div>
          <div className={missionsCSS.modalTask}>
            {task["task"]}
          </div>
        </div>
        <div className={missionsCSS.model_result}>
          {taskContent}
        </div>
      </div>
    </Dialog>
  );
};

export default ChooseDialog;
