import React from "react";

import { MissionsTable } from "../missionTable/missionsTable";
import { MissionVerificationLogic } from "./MissionVerificationLogic";

const tableSetup = [
  { accessorKey: "fname", header: "First Name" },
  { accessorKey: "lname", header: "Last Name" },
  { accessorKey: "mission", header: "Mission" },
  { accessorKey: "date", header: "Date" },
];

const MissionVerification = ({ unverifiedMissions, reloadHandler }) => {
  const missionVerificationHandler = (
    status,
    student_mission_ids,
    reloadHandler
  ) => {
    MissionVerificationLogic(status, student_mission_ids, reloadHandler);
  };
  return (
    <>
      <MissionsTable
        title="Verify Missions"
        data={unverifiedMissions}
        tableSetup={tableSetup}
        verify
        checkbox
        buttonHandler={(status, student_mission_ids) =>
          missionVerificationHandler(status, student_mission_ids, reloadHandler)
        }
        tooltipSelected
      />
    </>
  );
};

export default MissionVerification;
