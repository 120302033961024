import React from "react";

import cssModule from "./centerBox.module.css";

const images = {
  ach_mobile_banner: require("../../../assets/images/userSite/achievements_banner.png"),
};

const CenterBox = ({ boxContent, banner = true }) => {
  const mainContent = (
    <div className={cssModule.achievements_container}>
      {banner && (
        <div className={cssModule.ach_banner}>
          <img src={images.ach_mobile_banner} alt="" />
        </div>
      )}
      <div className={cssModule.page}>{boxContent}</div>
    </div>
  );
  return <>{mainContent}</>;
};

export default CenterBox;
