import { SetState } from "../../../../types";
import { Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
};

const filterImgs = [
  "achievement_box_empty.png",
  "achievements_banner.png",
  "achievementsIcon.png",
  "campaign.png",
  "coins_icon.png",
  "gameboard.png",
  "gameboard_2.png",
  "gameboard_3.png",
  "learn.png",
  "mark_missions.png",
  "missionsIcon.png",
  "rank_icon.png",
  "streak_icon.png",
];

const taskImages = require.context(
  "../../../../assets/images/userSite/",
  false
) as any;

const CategoryIconStep = ({ data, setData }: Props) => {
  return (
    <CreatorDialogTemplate
      name="categoryIcon"
      mainText="Select A Category Icon"
      subText={`Select 1 icon. The category icon will be displayed in the mission drawer`}
      data={data}
      setData={setData}
      imgBank={taskImages}
      filterImgs={filterImgs}
    />
  );
};

export default CategoryIconStep;
