import React from 'react';
import loadingCSS from './loading.module.css';

const Loading = ({ isLoading }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <div className={loadingCSS.loadingOverlay}>
            <div className={loadingCSS.loadingSpinner} />
        </div>
    );
};

export default Loading;
