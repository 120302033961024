import { React, useEffect, useState, useRef } from "react";

import cssModule from "./steps.module.css";

import ProgressInfo from "./stepsComponents/ProgressInfo";

import UserNavBar from "../../../../UserPages/Components/userNavbar/UserNavBar";
import FullPageScroll from "../../../fullPageScroll/FullPageScroll"

const imageAssets = require.context(
  "../../../../assets/images/campaign/steps",
  true
);

const Steps = ({ currentCampaign, role }) => {
  const [campaign, setCampaign] = useState({ ...currentCampaign });
  const [timeLeft, setTimeLeft] = useState(0);
  const [progress, setProgress] = useState(0);
  const [svgWidth, setSvgWidth] = useState();
  const [stepsTimeCounter, setStepsTimeCounter] = useState(0);
  const [charXY, setCharXY] = useState({ x: 0, y: 0 });
  const pathRef = useRef();
  const elementRef = useRef();
  const svgRef = useRef();

  useEffect(() => {
    setCampaign({ ...currentCampaign });
  }, [currentCampaign]);

  useEffect(() => {
    setProgress(campaign.progress);
    let time = campaign.endDate - Date.now();
    if (time / 2629743000 > 2) {
      setTimeLeft(`${Math.floor(time / 2629743000)} Months`);
    } else if (time / 86400000 > 2) {
      setTimeLeft(`${Math.floor(time / 86400000)} Days`);
    } else if (time / 2629743000 > 1) {
      setTimeLeft(`${Math.floor(time / 2629743000)} Month`);
    } else if (time / 86400000 > 2) {
      setTimeLeft(`${Math.floor(time / 86400000)} Day`);
    }
    if (!elementRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      let svgW = svgRef.current?.width.baseVal.value;
      setSvgWidth(svgW);
      let outerdivWidth = elementRef.current?.offsetWidth;
      let rightMarginSize = (outerdivWidth - svgW) / 2;
      setStepsTimeCounter(rightMarginSize + svgW);
    });

    resizeObserver.observe(elementRef.current);

    return () => {
      resizeObserver.disconnect(); // Clean up when the component unmounts
    };
  }, [campaign]);

  useEffect(() => {
    let stop = progress / campaign.goalCompleted;
    const moveBoy = (start, stop) => {
      let characterPath = pathRef.current;
      if (!characterPath) return;
      let pathLength = characterPath.getTotalLength() - 150;
      // Get the position of a point at <scrollPercentage> along the path.
      var pt = characterPath.getPointAtLength(start * pathLength);

      // Position the red dot at this point
      setCharXY(pt);
      setTimeout(() => {
        requestAnimationFrame(() => {
          if (start < stop) {
            moveBoy(start + 0.006, stop);
          }
        });
      }, 0.5);
    };
    moveBoy(0, stop);
  }, [progress]);

  const mainContent = (
    <div
      style={{
        backgroundColor: "#445eaa",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div className={cssModule.mainDiv} ref={elementRef}>
        <ProgressInfo
          svgWidth={svgWidth}
          progress={progress}
          timeLeft={timeLeft}
          stepsTimeCounter={stepsTimeCounter}
          goal={campaign.goalCompleted}
          barTitle={currentCampaign?.config.originToDestination}
        />
        <svg
          ref={svgRef}
          className="ms-3"
          version="1.1"
          viewBox="0 0 6227.8398 3827.2"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs id="defs1" />
          <g id="g1">
            <image
              width="6227.8398"
              height="3827.2"
              preserveAspectRatio="none"
              href={`${imageAssets(`${currentCampaign?.config.campaignImg}`)}`}
              id="image1"
            />
            <path
              id="characterPath"
              ref={pathRef}
              style={{ fill: "none", stroke: "none", strokeWidth: "4.7811" }}
              d={currentCampaign?.config.svgPath}
            />
          </g>
          <image
            id="char"
            transform={
              "translate(" + (charXY.x - 150) + "," + (charXY.y - 700) + ")"
            }
            href={`${imageAssets(`${currentCampaign?.config.chars[0]}`)}`}
            width="7%"
            alt=""
          />
        </svg>
      </div>
    </div>
  );

  return (
    <>
      {currentCampaign && role !== "student" ? (
        mainContent
      ) : (
        <FullPageScroll
          sections={[
            {
              value: mainContent,
              dynamic: true,
              header: <UserNavBar />,
            },
          ]}
        />
      )}
    </>
  );
};

export default Steps;
