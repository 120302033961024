import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import home from "../../../../assets/main_css/home.module.css";

import ApiService from "../../../../Services/apiService";
import { validation } from "../../../../Util/Validation";

import FormInput from "../../../../Components/FormInput";
import DialogTemplate from "../../../../Components/DialogTemplate";
import main_css from "../../../../assets/main_css/main.module.css";

const SchoolSignUpForm = ({ onChangeForm }) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [school, setSchool] = useState("")
  const [studentCount, setStudentCount] = useState("")
  const [submitMessage, setSubmitMessage] = useState(null)

  const navigate = useNavigate();
  const apiService = new ApiService();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage('loading')
    try {
      const [signUpResp, error] = await apiService.post({
        path: '/schoolSignUp',
        data: {
          name: name,
          email: email,
          school: school,
          address: address,
          phone: phone,
          studentCount: studentCount,
        },
        dataType: "sign_up",
      })

      if (signUpResp && signUpResp.status === 200) {
        setSubmitMessage({
          mainText: "Thank you for your interest!",
          subText: "Please check your email and you will here from us shortly!",
        });
      } else if (error) {
        setSubmitMessage({
          mainText: "Oops!",
          subText:
            "Something went wronge!\nPlease check the informtion you entered and try again.",
        });
      }
    } catch (error) {
      setSubmitMessage({
        mainText: "Oops!",
        subText:
          "Something went wronge!\nPlease check the informtion you entered and try again.",
      });
    }
  };

  const mainContent = (
    <div className={`${home.form_box_main} ${home.teacher_signup}`}>
      <form className={home.black} onSubmit={handleSubmit} id="submit_school">
        <div className="row">
          <div className="col-12 ajax-field">
            <FormInput type={"text"} value={name} name={"name"} placeholder={"Name"} onChangeHandler={setName} classes={`${home.form_control} parent_form mb-0`} message={validation.name.message} pattern={validation.name.pattern} />
          </div>
          <div className="col-12 ajax-field">
            <FormInput type={"email"} value={email} name={"email"} placeholder={"E-mail"} onChangeHandler={setEmail} classes={`${home.form_control} parent_form mb-0`} message={validation.email.message} pattern={validation.email.pattern} />
          </div>
          <div className="col-12 ajax-field">
            <FormInput type={"tel"} value={phone} placeholder={"Phone Number"} classes={`${home.form_control} parent_form mb-0`} onChangeHandler={setPhone} name={'phone'} required={false} />
          </div>
          <div className="col-12 ajax-field">
            <FormInput type={"text"} value={address} name={"address"} placeholder={"Address"} onChangeHandler={setAddress} classes={`${home.form_control} parent_form mb-0`} required={false} />
          </div>
          <div className="col-12 ajax-field">
            <FormInput type={"text"} value={school} name={"school"} placeholder={"School Name"} onChangeHandler={setSchool} classes={`${home.form_control} parent_form mb-0`} />
          </div>
          <div className="col-12 ajax-field">
            <FormInput type={"text"} value={studentCount} name={"Number_Of_students"} placeholder={"Number Of Students"} onChangeHandler={setStudentCount} classes={`${home.form_control} parent_form mb-0`} message={validation.studentBodySize.message} pattern={validation.studentBodySize.pattern} required={false} />
          </div>
          <div className="text-center pt-4">
            <button
              type="submit"
              id="school_update"
              className={main_css.basic_btnInv}
              style={{ padding: '5px 10px' }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {mainContent}

      {submitMessage === 'loading' && <DialogTemplate mainText={'Hang Tight \n while We Sign You Up!'} duration="0ms" />}

      {submitMessage && submitMessage['mainText'] === 'Oops!' && <DialogTemplate
        mainText={submitMessage['mainText']}
        subText={submitMessage['subText']}
        subTextClass={'px-lg-3'}
        closeHandler={() => setSubmitMessage(null)}
        button1Text={'Back To Sign-up'}
        onClickButton1={() => setSubmitMessage(null)}
      />}

      {submitMessage && submitMessage['mainText'] === "Thank you for your interest!" && (
        <DialogTemplate
          mainText={submitMessage["mainText"]}
          subText={submitMessage["subText"]}
          subTextClass={'px-3'}
          closeHandler={() => setSubmitMessage(null)}
          button1Text={'Back To Home Page'}
          onClickButton1={() => navigate('/')}
        />
      )}
    </>
  );
};

export default SchoolSignUpForm;
