import React from "react";

import CarouselCard from "./missionsComponents/CarouselCard";
import CenterBox from "../../../../UserPages/Components/centerBox/CenterBox";
import CarouselTest from "../../../carousel/CarouselTest";
import UserPageTemplate from "../../../../UserPages/Components/userPageTemplate/UserPageTemplate";

import { sortObjectByValues } from "../../../../Util/utils";

import cssModule from "./missions.module.css";

const GradeENUM = {
  1: "1st Grade",
  2: "2nd Grade",
  3: "3rd Grade",
  4: "4th Grade",
  5: "5th Grade",
  6: "6th Grade",
  7: "7th Grade",
  8: "8th Grade",
};

const Missions = ({ currentCampaign, paticipants, role }) => {
  console.log(currentCampaign);
  let schoolRankings = [];
  if (currentCampaign?.schoolTotals) {
    for (let schoolKey of sortObjectByValues(
      currentCampaign.schoolTotals,
      "desc"
    ).keys()) {
      schoolRankings.push(schoolKey);
    }
  }
  schoolRankings = [
    ...schoolRankings,
    "Hasmonean",
    "Hillel",
    "South Florida Jewish academy",
    "Gesher",
  ];

  let gradeRankings = [];
  if (currentCampaign) {
    for (let gradeKey of sortObjectByValues(
      currentCampaign.gradeTotals,
      "desc"
    ).keys()) {
      gradeRankings.push(GradeENUM[gradeKey]);
    }
  }

  let individualRankings = [];
  if (currentCampaign) {
    for (let studentKey of sortObjectByValues(
      currentCampaign.studentTotals,
      "desc"
    ).keys()) {
      if (role !== "student") {
        studentKey = currentCampaign.participants.find(
          (participant) => participant._id === studentKey
        );
        console.log(studentKey);
        studentKey = `${studentKey.fname} ${studentKey.lname}`;
      }
      individualRankings.push(studentKey);
    }
  }

  const carouselItems = [
    <CarouselCard title={"school"} rankingsList={schoolRankings} />,
    <CarouselCard title={"grade"} rankingsList={gradeRankings} />,
    <CarouselCard title={"individual"} rankingsList={individualRankings} />,
  ];

  const boxContent = (
    <>
      <div className={cssModule.statsContainer}>
        <div className={cssModule.desktop_stats}>{carouselItems}</div>
        <div className={cssModule.mobile_stats}>
          <CarouselTest items={carouselItems} startIndex={0} />
        </div>
      </div>
    </>
  );

  const mainContent = <CenterBox boxContent={boxContent} />;

  return (
    <>
      {role === "student" ? (
        <UserPageTemplate
          pageContent={mainContent}
          bgClass={cssModule.bg}
          footer
          footerClass={"scrollableFooter"}
        />
      ) : (
        <div className={cssModule.adminContainer}>{mainContent}</div>
      )}
    </>
  );
};

export default Missions;
