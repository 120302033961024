import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

import SelectedTooltip from "./SelectedTooltip";

import styles from "../missionsTable.module.scss";

const Tooltip = ({
  title,
  verify,
  selected,
  tooltip,
  tooltipSelected,
  buttonHandler,
  selectSingle,
}) => {
  if (tooltip?.value === "addIcon") {
    tooltip.value = (
        <div className={styles.iconContainer}>
          <IconButton onClick={tooltip.action}>
            <AddIcon fontSize="medium" className={styles.addIcon} />
          </IconButton>
        </div>
    )
  }
  return (
    <div
      className={`${selected.length > 0 ? styles.selected : undefined} ${
        styles.headerContainer
      }`}
    >
      <div>
        {selected.length > 0 ? (
          <h5>{`${selected.length} Selected`}</h5>
        ) : (
          <h2>{title}</h2>
        )}
      </div>
      <div className={styles.tooltip}>
        {selected.length > 0 && tooltipSelected && (
          <SelectedTooltip
            verify={verify}
            selected={selected}
            buttonHandler={buttonHandler}
            selectSingle={selectSingle}
            tooltipSelected={tooltipSelected}
          />
        )}
        {selected.length < 1 && tooltip && <div>{tooltip.value}</div>}
      </div>
    </div>
  );
};

export default Tooltip;
