import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";

const FormInput = ({
  type,
  value,
  placeholder,
  name,
  onChangeHandler,
  message,
  pattern,
  classes,
  required,
  disabled = false,
  autoFocus,
}) => {
  const [focused, setFocused] = useState(false);
  if (value === "" && focused === false) {
    message = "This field is required";
  }

  useEffect(() => {
    setFocused(true);
  }, [value, name]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <input
        type={type}
        className={`form-control ${focused === false && "err"} ${classes}`}
        value={value}
        placeholder={placeholder}
        name={name}
        onBlur={() => setFocused(false)}
        required={required}
        require={required.toString()}
        pattern={pattern}
        onFocus={() => setFocused(true)}
        autoFocus={autoFocus}
        onChange={(event) => {
          onChangeHandler(event.target.value.replace("  ", " "));
        }}
        disabled={disabled}
      />
      <>
        <span className="err_text text-center">{message}</span>
      </>
    </div>
  );
};

export default FormInput;

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  message: PropTypes.string,
  pattern: PropTypes.string,
  classes: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

FormInput.defaultProps = {
  required: true,
  autoFocus: true,
};
