import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import FormInput from "../../../../Components/FormInput";
import ApiService from "../../../../Services/apiService";
import DialogTemplate from "../../../../Components/DialogTemplate";

import { validation } from "../../../../Util/Validation";

import main_css from "../../../../assets/main_css/main.module.css";


const ForgotPassword = () => {
  const apiService = new ApiService();
  const navigate = useNavigate("/");
  const [email, setEmail] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    mainText: "",
    subText: "",
    duration: "0ms",
    // closeHandler: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (sendingEmail) return;

    setSendingEmail(true);
    setDialog({
      open: true,
      mainText: "Sending Email...",
      subText: "Please wait while we send you an email",
    });

    try {
      const [response, error] = await apiService.post({
        path: "/forgot_password",
        dataType: "login",
        data: {
          email: email,
        },
      });

      setSendingEmail(true);
      setDialog({
        open: false,
        mainText: "",
        subText: "",
        duration: "0ms",
      });

      if (!error) {
        //show a modal confirmation
        setDialog({
          open: true,
          mainText: "Email Sent!",
          subText: "Please check your email to reset your password!",
          duration: "1000ms",
        });

        setTimeout(() => {
          //   navigate("/profile");
          navigate("/login");
        }, 1500);
      } else {
        setDialog({
          open: true,
          mainText: "Forgot Password failed!",
          subText: "Please double check your email and try again!",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });
      }
    } catch (error) {
      setDialog({
        open: true,
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: handleCloseDialog,
      });

      setTimeout(() => {
        setDialog({
          open: false,
          mainText: "",
          subText: "",
          duration: "",
          closeHandler: null,
        });
      }, 5000);
    }
  };

  const handleCloseDialog = () => {
    setDialog({
      open: false,
      mainText: "",
      subText: "",
      duration: "",
      closeHandler: null,
    });
  };

  const mainContent = (
    <>
      <div className="d-flex flex-column align-items-center">
        <form onSubmit={handleSubmit}>
          {/* <div className="forgotPassBox"> */}
          <div
            className="d-flex flex-column justify-content-center pt-4"
            style={{ height: "100%" }}
          >
            <div className="d-flex flex-column align-items-center">
              <p
                className={`fs-1 ${main_css.title}`}
                style={{ color: "white" }}
              >
                Forgot Password
              </p>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div style={{ width: "80%" }}>
                <h4 className="heading_foam" style={{ color: "white", paddingTop: '20px' }}>Existing User </h4>
                <div className="d-flex flex-column align-items-center mb-0 mb-sm-3 ajax-field">
                  <FormInput
                    type={"email"}
                    value={email}
                    placeholder={"Email Address"}
                    name={"email"}
                    onChangeHandler={setEmail}
                    message={validation.email.message}
                    pattern={validation.email.pattern}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <button type="submit" className={main_css.basic_btnInv}>
                Continue
              </button>
            </div>
            <div className="d-flex flex-column align-items-center pt-xxl-2">
              <Link
                to="/login"
                className="back_btn"
                style={{ margin: "10px", color: "white" }}
              >
                Back To Login
              </Link>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
    </>
  );

  return (
    <>
      {dialog.open === true && <DialogTemplate {...dialog} />}
      {mainContent}
    </>
  );
};

export default ForgotPassword;
