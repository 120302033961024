import { Mission, SetState } from "../../../../types";
import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import { Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
  currentMissions: Mission[];
};

const tableSetup = [
  { accessorKey: "category", header: "Category" },
  { accessorKey: "task", header: "Task" },
];

const SelectMissionsStep = ({ data, setData, currentMissions }: Props) => {
  const selectMissionsHandler = (selected: string[]) => {
    setData((prev) => {
      return {
        ...prev,
        missions: {
          ...prev["missions"],
          value: selected,
        },
      };
    });
  };
  const customFields = [
    <div key={"selectMissionsTable"} className="px-2">
      <MissionsTable
        title=""
        data={currentMissions}
        tableSetup={tableSetup}
        checkbox
        setSelectedProp={selectMissionsHandler}
      />
    </div>,
  ];

  return (
    <CreatorDialogTemplate
      name="missions"
      data={data}
      setData={setData}
      mainText="Select Missions To Add To Your New Game"
      customFields={customFields}
    />
  );
};

export default SelectMissionsStep;
