import { React, useState } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Hidden,
  Drawer,
  List,
  ListItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import "../../../assets/css_new/main.css";
import main_css from "../../../assets/main_css/main.module.css";

import mainLogo from "../../../assets/images/website/supermelogo.png";

const navbarButton_style = {
  color: "rgb(68, 94, 170)",
  fontSize: "18px",
  fontWeight: 600,
  letterSpacing: ".5px",
  padding: "20px",
  textTransform: "none",
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      // You can add a custom breakpoint
      custom: 1000,
    },
  },
});

const MainNavBar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div
      style={{
        backgroundColor: "rgb(68, 94, 170)",
        borderRadius: "0 0 20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box height="75px"></Box>
      <List>
        {["Sign up", "Login"].map((text, index) => (
          <ListItem
            button
            key={text}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Link
              to={`/${text.toLowerCase().replace(" ", "-")}`}
            >
              <Button
                style={{ ...navbarButton_style, color: "white" }}
              // href={`/${text.toLowerCase().replace(" ", "-")}`}
              >
                {text}
              </Button>
            </Link>
          </ListItem>
        ))}
        <ListItem
          button
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link
            to="/bookdemo"
          >
            <Button
              style={{
                ...navbarButton_style,
                height: "auto",
                backgroundColor: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            // href="/bookdemo"
            >
              Book Demo
            </Button></Link>
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar className="_navbar">
      <ThemeProvider theme={theme}>
        <Toolbar>
          <div style={{ marginRight: "auto" }}>
            <Link to="/" >
              <img src={mainLogo} style={{ maxHeight: '50px' }} alt="" />
            </Link>
          </div>
          <Hidden mdDown implementation="css">
            {/* <Button className={main_css.navbarButton} href="/">
              Home
            </Button>
            <Button style={navbarButton_style} href="/about">
              About
            </Button>
            <Button style={navbarButton_style} href="/team">
              Team
            </Button> */}
            <Link
              to={"/sign-up?_form=student"}
            >
              <Button className={main_css.navbarButton}>
                Sign up
              </Button>
            </Link>
            <Link
              to={"/login"}
            >
              <Button className={main_css.navbarButton}>
                Login
              </Button>
            </Link>
            <Link
              to={"/bookdemo"}
            >
              <Button
                className={main_css.basic_btn}
              >
                Book Demo
              </Button>
            </Link>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button onClick={handleDrawerToggle}>
              <MenuIcon style={{ color: "rgb(68, 94, 170)" }} />
            </Button>
          </Hidden>
        </Toolbar>
        <nav>
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              anchor="top"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              PaperProps={{ style: { backgroundColor: "transparent" } }} // Make Drawer background transparent
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </ThemeProvider>
    </AppBar>
  );
};

export default MainNavBar;
