import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../homePage.module.scss';
import background from '../../../../assets/images/website/banner.png';
import girlHeader from '../../../../assets/images/website/girl.png';
import boyHeader from '../../../../assets/images/website/boy2.png';
import superMeLogo from '../../../../assets/images/website/logo_icon.png';


export const HeroSection = (props) => {
  return (
    <section style={{ backgroundImage: `url('${background}')` }} className={styles.heroSection}>
      <div className={styles.heroSectionContainer}>
        <img src={boyHeader} alt="Boy" className={styles.boyHeader} />
        <div className={styles.heroInfo}>
          <img src={superMeLogo} alt="Super Me Logo" className={styles.heroInfoLogo} />
          <h1>Where Children create healthy habits through gamification</h1>
          <div className="text-center">
            <Link
              to="/bookdemo"
              className={styles.basic_btn}
              style={{ fontSize: 'larger', paddingBlock: '10px', }}
            >
              Book Demo
            </Link>
          </div>
        </div>
        <img src={girlHeader} alt="Girl" className={styles.girlHeader} />
      </div>
    </section>
  );
};
