import { useEffect, useState } from "react";

import { CreatorData, GameboardNames, CreatorGameboardConfig } from "./types";
import { GameboardType } from "../../../types";

import AdminTemplate from "../../Components/adminTemplate/AdminTemplate";
import Missions from "./components/Missions";

import ApiService from "../../../Services/apiService";
import Gameboard from "./components/Gameboard";
import Games from "./components/Games";

const CreatorPage = () => {
  const apiService = new ApiService();
  const [creatorData, setCreatorData] = useState<CreatorData | null>(null);
  const [gameboardNames, setGameboardNames] = useState<GameboardNames>([
    { name: "", id: "" },
  ]);

  useEffect(() => {
    const fetchCreatorData = async () => {
      try {
        const [creatorDataResp, err] = await apiService.get({
          path: "/creator_data",
          dataType: "admin",
        });
        return creatorDataResp;
      } catch (error) {
        console.log(error);
      }
    };
    fetchCreatorData().then((creatorDataResp: any) => {
      if (!creatorDataResp) return;
      console.log(creatorDataResp);
      setGameboardNames(
        creatorDataResp.data.gameboards.map((gameboard: GameboardType) => ({
          name: gameboard.image,
          id: gameboard._id,
        }))
      );
      setCreatorData(() => {
        let gameboards = creatorDataResp.data.gameboards;
        let gameboardConfigs = creatorDataResp.data.gameboardConfigs;
        gameboards = gameboards.map(
          (gameboard: GameboardType, index: number) => {
            const gameboardName = gameboard.name ? gameboard.name : `Gameboard ${index + 1}`
            return {
              ...gameboard,
              name: gameboardName,
            };
          }
        );
        gameboardConfigs = gameboardConfigs.map(
          (config: CreatorGameboardConfig, index: number) => {
            const gameboard = gameboards.find(
              (gameboard: GameboardType) => gameboard._id === config.gameboardId
            );
            const gameName = config.name ? config.name : `Game ${index + 1}`;

            return {
              ...config,
              name: gameName,
              gameboardName: gameboard.name,
              totalMissions: config.missionIds.length,
            };
          }
        );
        return {
          ...creatorDataResp.data,
          gameboards,
          gameboardConfigs,
        };
      });
    });
  }, []);

  const mainContent = creatorData && (
    <>
      <Missions
        missions={creatorData.missions}
        gameboardConfigs={creatorData.gameboardConfigs}
        setCreatorData={setCreatorData}
      />
      <Gameboard gameboards={creatorData.gameboards} />
      <Games
        gameboardConfigs={creatorData.gameboardConfigs}
        gameboardNames={gameboardNames}
        currentMissions={creatorData.missions}
        setCreatorData={setCreatorData}
      />
    </>
  );
  return <AdminTemplate carousel={false} pageContent={mainContent} />;
};

export default CreatorPage;
