import React, { useEffect, useState, useRef } from "react";
import styles from "../missionsTable.module.scss";
import { flexRender } from "@tanstack/react-table";

const EditableRow = ({
  cell,
  updateDom,
  updateDomHandler,
  checkValidation,
  editingBtnValue,
}) => {
  const [currentValue, setCurrentValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState();
  const difference = useRef(null);
  let accessorKey = cell.column.columnDef.accessorKey;
  let type;

  const inputTags = {
    className: `text-center ${styles.blue_border}`,
    text: "text",
  };

  if (cell.row.original.rawData && cell.row.original.rawData[accessorKey]) {
    type = cell.row.original.rawData[accessorKey].type;
  } else {
    type = cell.row.original.type;
  }

  if (cell.row.original.rawData[accessorKey]) {
    if (type === "date") {
      inputTags.placeholder = "MM/DD/YYYY";
      inputTags.pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
      inputTags.errormsg = "Please enter the date in MM/DD/YYYY format";
    } else if (type === "number") {
      inputTags.pattern = /^[0-9,]+$/;
      inputTags.errormsg = "Please enter a valid number";
    } else {
      inputTags.value = currentValue;
    }
  }

  useEffect(() => {
    if (cell.row.original.rawData[accessorKey]) {
      const initialValue = cell.row.original.rawData[accessorKey].value;
      if (type === "number") {
        setCurrentValue(initialValue.toLocaleString());
      } else {
        setCurrentValue(initialValue);
      }
    }
  }, []);

  useEffect(() => {
    if (updateDom.id !== cell.row.original.id) {
      return;
    }

    const calculateDifference = () => {
      let newValue = currentValue;
      let originalValue = cell.row.original.rawData[accessorKey].value;
      let diff;

      if (type === "date") {
        const [month, day, year] = newValue.split("/");
        const newDate = new Date(year, month - 1, day).getTime();
        const originalDate = new Date(originalValue).getTime();
        diff = newDate - originalDate;
      } else if (type === "number") {
        newValue = parseFloat(newValue.replace(/,/g, ""));
        diff = newValue - originalValue;
      } else {
        diff = newValue;
      }

      return diff;
    };

    if (
      isEditing &&
      currentValue !== cell.row.original.rawData[accessorKey].value
    ) {
      const diff = calculateDifference();
      difference.current = diff;

      updateDomHandler(
        updateDom.id,
        accessorKey,
        diff,
        cell.row.original.rawData[accessorKey].path,
        cell.row.original.rawData[accessorKey].arrayFilters
      );
    }
  }, [updateDom]);

  return (
    <>
      {cell.row.original.editable.includes(accessorKey) &&
      editingBtnValue[cell.row.original.id] &&
      editingBtnValue[cell.row.original.id].btnValue ? (
        <div className={styles.editableRow}>
          <input
            {...inputTags}
            value={currentValue}
            onChange={(e) => {
              let newValue = e.target.value;
              if (type === "number") {
                newValue = newValue.replace(/,/g, "");
                if (newValue === "") {
                  newValue = "";
                } else if (!isNaN(newValue)) {
                  newValue = parseFloat(newValue).toLocaleString();
                }
              }
              let status = checkValidation(
                newValue,
                inputTags.pattern,
                cell.row.original.id
              );
              if (!status) {
                setError(inputTags.errormsg);
              } else {
                setError("");
              }
              setCurrentValue(newValue);
              setIsEditing(true);
            }}
          />
          {error && <div className={styles.error}>{error}</div>}{" "}
        </div>
      ) : type === "number" && !currentValue ? (
        ""
      ) : (
        flexRender(cell.column.columnDef.cell, cell.getContext())
      )}
    </>
  );
};

export default EditableRow;
