import { useCallback } from "react";

import { CreatorData } from "../types";
import { Mission, RenderableContent, SetState } from "../../../../types";

import useAddMission from "./useAddMission";

import ActionSuccessStatus from "../components/ActionSuccessStatus";

const useAddMissionHandler = () => {
  const addMission = useAddMission();
  const addMissionHandler = useCallback(
    async (
      mission: Omit<Mission, "_id">,
      addToGame: string,
      setCreatorData: SetState<CreatorData | null>,
      setOpenDialog: SetState<{ open: boolean; content: RenderableContent }>
    ): Promise<RenderableContent> => {
      let message: RenderableContent = <></>;
      try {
        const addMissionResp = await addMission(mission, addToGame);

        if (!addMissionResp) {
          message = (
            <>
              Something went wrong. <br></br> Please try again.
            </>
          );
        } else {
          console.log(addMissionResp);

          message = (
            <>
              Completed <br></br> Mission Successfully <br></br> Added
            </>
          );
          setCreatorData((prev) => {
            const gameboardConfigsCopy = [...prev!.gameboardConfigs];
            for (let i = 0; i < gameboardConfigsCopy.length; i++) {
              if (gameboardConfigsCopy[i]._id === addToGame) {
                gameboardConfigsCopy[i].missionIds.push(addMissionResp.id);
                gameboardConfigsCopy[i].totalMissions += 1;
                break;
              }
            }

            return {
              ...prev!,
              missions: [
                ...prev!.missions,
                { _id: addMissionResp.id, ...mission },
              ],
              gameboardConfigs: gameboardConfigsCopy,
            };
          });
        }
      } catch (error) {
        message = (
          <>
            Something went wrong. <br></br> Please try again.
          </>
        );
      }
      return (
        <ActionSuccessStatus
          message={message}
          closeHandler={() => setOpenDialog({ open: false, content: null })}
        />
      );
    },
    []
  );
  return addMissionHandler;
};

export default useAddMissionHandler;
