import { Mission, SetState } from "../../../../types";
import InfoTable from "../../../Components/infoTable/InfoTable";
import styles from "../creatorPage.module.scss";
import { Data, GameboardNames } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
  currentMissions: Mission[];
  gameboardNames: GameboardNames;
};

type TableInfo = {
  label: string | boolean;
  value: string | number;
}[];

const CreateGameSummary = ({
  data,
  setData,
  currentMissions,
  gameboardNames,
}: Props) => {
  console.log(data);

  const checkfieldSet = (key: keyof Data): boolean => {
    if (
      !data[key].value ||
      data[key].value[0] === "choose" ||
      data[key].value[0] === "" ||
      !tableLabels[key]
    ) {
      return false;
    } else {
      return true;
    }
  };

  const dataKeys = Object.keys(data);

  const tableLabels: Record<keyof Data, boolean | string> = {
    gameName: "Game Name",
    gameboard: "Gameboard",
    missions: "Mission",
  };

  const tableInfo: TableInfo = [];
  dataKeys.forEach((key) => {
    if (!checkfieldSet(key as keyof Data)) return;
    let value: string | string[];
    if (
      data[key as keyof Data]?.value &&
      typeof data[key as keyof Data]?.value !== "string"
    ) {
      value = [...data[key as keyof Data]?.value];
    } else {
      value = data[key as keyof Data]?.value;
    }

    if (key === "gameboard") {
      const gamboard = gameboardNames.find(
        (gameboard) => gameboard.id === value[0]
      );
      if (gamboard) value[0] = gamboard.name;
    } else if (key === "missions") {
      const valueCopy = [...value];
      value = [];
      valueCopy.forEach((selectedMissionId) => {
        const mission = currentMissions.find(
          (currentMission) => currentMission._id === selectedMissionId
        );
        if (mission && typeof value !== "string") {
          value.push(mission.task);
        }
      });
    }

    if (value.length > 1) {
      value.forEach((v, index) => {
        tableInfo.push({
          label: `${tableLabels[key as keyof Data]} ${index + 1}`,
          value: v,
        });
      });
    } else {
      value = value[0];
      tableInfo.push({
        label: tableLabels[key as keyof Data],
        value: value,
      });
    }
  });

  const additionaltableFields = [
    { label: "Game Name", value: data.gameName.inputField?.value! },
  ];

  additionaltableFields.forEach((field) => {
    if (field.value) {
      if (field.label === "Game Name") {
        tableInfo.unshift(field);
      } else {
        tableInfo.push(field);
      }
    }
  });

  const mainContent = (
    <div className={styles.infoTableContainer}>
      <InfoTable
        tableTitle=""
        route="null"
        tableInfo={tableInfo}
        onSaveHandler={null}
        handleRowClick={() => {}}
      />
    </div>
  );
  const subText =
    "Review and confirm the game details before creating the game.";

  return (
    <CreatorDialogTemplate
      name="createGameSummary"
      mainText="New Game Summary"
      subText={subText}
      data={data}
      setData={setData}
      customFields={mainContent}
    />
  );
};

export default CreateGameSummary;
