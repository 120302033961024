import styles from "../creatorPage.module.scss";

import { RenderableContent, SetState } from "../../../../types";
import { Data, CreatorInputField, SelectField, UploadReq } from "../types";
import SelectInput from "./SelectInput";
import FormInput from "../../../../Components/FormInput";
import useUpdateData from "../hooks/useUpdateData";
import SelectImg from "./SelectImg";

type Props = {
  name: string;
  data: Data;
  setData: SetState<Data>;
  mainText: string;
  subText?: string;
  displayInfo?: RenderableContent;
  selectField?: SelectField[];
  inputField?: CreatorInputField[];
  customFields?: RenderableContent;
  imgBank?: any;
  filterImgs?: string[];
};

// Type guard to check if the object has the correct structure
function hasSubFieldData(
  obj: any,
  subField: string
): obj is Record<
  string,
  { err: string; value: string; display: string; pattern?: string }
> {
  return (
    typeof obj === "object" &&
    obj !== null &&
    subField in obj &&
    typeof obj[subField] === "object" &&
    "err" in obj[subField] &&
    "value" in obj[subField] &&
    "display" in obj[subField]
  );
}

const CreatorDialogTemplate = ({
  name,
  data,
  setData,
  mainText,
  subText,
  displayInfo,
  selectField,
  inputField,
  customFields,
  imgBank,
  filterImgs,
}: Props) => {
  const updateData = useUpdateData();
  const nameKey = name as keyof Data;
  const uploadReq = data[nameKey].img?.uploadReq as UploadReq;

  return (
    <>
      <div className={`${styles.mainText} text-center`}>{mainText}</div>
      {subText && (
        <div className={`${styles.subText} text-center`}>{subText}</div>
      )}
      <div className={styles.data}>
        {displayInfo && displayInfo}
        <div className={styles.fields}>
          {customFields && customFields}
          {selectField &&
            selectField
              .map((field, index) => (
                <SelectInput
                  value={field.value && field.value}
                  key={`select_${index}_${nameKey}_${field.value}`}
                  data={data}
                  currentStepName={nameKey}
                  options={field.options}
                  handleChange={field.handleChange}
                />
              ))
              .filter((field) => field !== null)}
          <div className={styles[`inputField_${nameKey}`]}>
            {inputField &&
              inputField
                .map((field, index) => {
                  let inputError: string = "";
                  let inputValue: string = "";
                  let inputDisplay: string = "";
                  let pattern: { [key: string]: string } = {};

                  const subField = field.subField;

                  if (hasSubFieldData(data[nameKey], subField)) {
                    const fieldData = data[nameKey] as Record<
                      string,
                      {
                        err: string;
                        value: string;
                        display: string;
                        pattern?: string;
                      }
                    >;

                    inputError = fieldData[subField].err || "";
                    inputValue = fieldData[subField].value || "";
                    inputDisplay = fieldData[subField].display || "";
                    pattern = fieldData[subField].pattern
                      ? { pattern: fieldData[subField].pattern }
                      : {};
                  }
                  const patternErr = field.patternErr
                    ? { message: field.patternErr }
                    : {};

                  return (
                    <div
                      key={`input_${index}_${nameKey}_${field}`}
                      className={`${
                        styles.inputField
                      } ${inputError} ${inputDisplay} ${
                        styles[`inputfield_${index}_${field}`]
                      }`}
                    >
                      <FormInput
                        key={`input_${index}_${nameKey}_${field}`}
                        type="text"
                        value={inputValue ? inputValue : ""}
                        name={name}
                        autoFocus={false}
                        required={false}
                        placeholder={field.placeholder}
                        {...pattern}
                        {...patternErr}
                        onChangeHandler={(v: string) =>
                          updateData(data, v, name, field.subField, setData, [
                            { name: "", url: "" },
                          ])
                        }
                      />
                    </div>
                  );
                })
                .filter((field) => field !== null)}
          </div>
        </div>
        {uploadReq.required && (
          <SelectImg
            data={data}
            setData={setData}
            imgBank={imgBank}
            nameKey={nameKey}
            uploadReq={uploadReq}
            filterImgs={filterImgs}
          />
        )}
      </div>{" "}
    </>
  );
};

export default CreatorDialogTemplate;
