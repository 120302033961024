import { useQuery } from '@tanstack/react-query'

import ApiService from "../../Services/apiService";

const useUserQuests = () => {
    const apiService = new ApiService(true);

    const fetchUserData = async () => {

        let questTiles = [];

        let statsConfig = {};

        let reward = [];

        let userData = {};

        let questboardPos = -1;

        const [response, error] = await apiService.get({
            path: "/quests",
            dataType: "user",
            data: {
                role: "student",
                questboardPos: -1,
            },
        });

        if (response) {
            questTiles = response.data.questTiles;
            statsConfig = response.data.statsConfig;
            reward = response.data.reward;
            userData = response.data.userData;
            questboardPos = response.data.questboardPos
        }

        return {
            questTiles: questTiles,
            statsConfig: statsConfig,
            reward: reward,
            userData: userData,
            questboardPos: questboardPos
        };

    };

    return useQuery({
        queryKey: ['userQuests'],
        queryFn: fetchUserData,
        staleTime: 0,
    });


};

export default useUserQuests;
