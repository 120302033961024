import React from 'react';
import styles from './footer.module.scss';

import useUserConfig from "../../UserPages/Hooks/useUserConfig";

const assets = require.context("../../assets/images", true);

export const Footer = ({ footerClass = "adminFooter" }) => {

  const { data: userConfig, isLoading } = useUserConfig();

  if (isLoading || !userConfig?.sponsorConfig) return;

  const sponsorConfig = userConfig.sponsorConfig;

  const sponsorsList = sponsorConfig.sponsors.length > 0 ? sponsorConfig.sponsors.map((str, index) => (
    <>
      <p>{str}</p> {/* Render the string */}
      {index !== sponsorConfig.sponsors.length - 1 && ( // Render "&" if not the last item
        <p className={styles.and}>&</p>
      )}
    </>
  )) : null;

  const sponsorsImgList = sponsorConfig.sponsorsImg.length > 0 ? sponsorConfig.sponsorsImg.map((str, index) => (
    <>
      <img src={assets(str)} alt={`Image ${index}`} /> {/* Render the image */}
      {index !== sponsorConfig.sponsorsImg.length - 1 && ( // Render "&" if not the last item
        <p className={styles.and}>&</p>
      )}
    </>
  )) : null;

  return (
    <footer className={styles[footerClass]} >
      <h1>Our Partners:</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <p>Thank you</p>
        {!sponsorsImgList &&
          sponsorsList
        }
        {sponsorsImgList &&
          sponsorsImgList
        }
        <p>{sponsorConfig.sponsorFor}</p>
      </div>
    </footer>
  );

};
