import { useEffect, useState } from "react";
import { Outlet, useNavigate} from "react-router-dom";
import Axios from "axios";

import { backend_url } from "../config";


const Authentication = ({ redirect, require, }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    async function checkAuth() {
      const response = await Axios({
        method: "GET",
        withCredentials: true,
        url: `${backend_url}/checkAuth`,
      });
      if (
        response.data.authenticated === false &&
        require.authenticated !== false
      ) {
        return setAuthenticated(navigate("/login"));
       } else {
        setAuthenticated(
          (response.data.authenticated === require.authenticated &&
            require.role.includes(response.data.role) === true) ? (
            <Outlet />
          ) : (
            navigate(redirect)
          )
        );
      }
      
    }
    checkAuth();
  }, [redirect, require]);
  return authenticated;
};

export default Authentication;
