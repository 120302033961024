import { React, createContext, useState } from "react";

import SelectCampaign from "./components/SelectCampaign";
import DisplayCampaign from "./components/DisplayCampaign";

import { Header } from "../../Components/header/header";
import { SideMenu } from "../../Components/sideMenu/sideMenu";

export const InfoContext = createContext();

const AdminCampaigns = () => {
  const [currentCampaign, setCurrentCampaign] = useState();

  const gradeTableUpdater = (
    campaignId,
    goalType,
    student,
    gradeTableCopy,
    gradeTotals
  ) => {
    let index = gradeTableCopy.findIndex((grade) => grade.id === student.grade);
    if (index !== -1) {
      gradeTableCopy[index].rawData.studentGradeCount.value += 1;
    } else {
      index = gradeTableCopy.length;
      gradeTableCopy.push({
        id: student.grade,
        editable: ["gradeProgress"],
        noneTableContingencies: {
          gradeProgress: ["progress"],
        },
        rawData: {
          grade: { value: student.grade, suffix: "" },
          studentGradeCount: { value: 1, suffix: "" },
          gradeProgress: {
            value: gradeTotals[student.grade] ? gradeTotals[student.grade] : 0,
            suffix: goalType,
            path: [
              `campaigns.$[campaign].gradeTotals.${student.grade}`,
              `campaigns.$[campaign].progress`,
            ],
            arrayFilters: [{ "campaign._id": campaignId }],
            type: "number",
          },
        },
      });
    }
    return gradeTableCopy;
  };

  return (
    <>
      <InfoContext.Provider
        value={{
          currentCampaign,
          setCurrentCampaign,
          gradeTableUpdater,
        }}
      >
        {currentCampaign ? (
          <DisplayCampaign
            currentCampaign={currentCampaign}
            setCurrentCampaign={setCurrentCampaign}
            gradeTableUpdater={gradeTableUpdater}
            header={<Header menu={<SideMenu />} />}
          />
        ) : (
          <SelectCampaign />
        )}
      </InfoContext.Provider>
    </>
  );
};

export default AdminCampaigns;
