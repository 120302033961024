import { useEffect, useRef } from "react";

import styles from "../creatorPage.module.scss";
import { Data, UploadReq } from "../types";
import { SetState } from "../../../../types";
import CurrrentSelectedImg from "./CurrrentSelectedImg";

type Props = {
  data: Data;
  setData: SetState<Data>;
  imgBank: any;
  nameKey: keyof Data;
  uploadReq: UploadReq;
  filterImgs?: string[];
};

const SelectImg = ({
  data,
  setData,
  imgBank,
  nameKey,
  uploadReq,
  filterImgs,
}: Props) => {
  const dataImg = data[nameKey].img;
  const selected = useRef<{ [key: string]: number }>({});
  let imgBankKeys = [...imgBank.keys()];
  if (filterImgs) {
    imgBankKeys = imgBankKeys.filter((key) => {
      key = key.replace("./", "");

      return !filterImgs.includes(key);
    });
  }

  const unselect = (src: string, index: number) => {
    const element = document.getElementById(`imgBank_${src}`);
    if (element) {
      element.classList.remove(styles.selectedHighlight);
    }
    delete selected.current[src];
    src = src.replace("./", "");
    setData((prev) => {
      let valueCopy = [...prev[nameKey].img.value];
      valueCopy = valueCopy.filter((value) => value.url !== src);
      return {
        ...prev,
        [nameKey]: {
          ...prev[nameKey],
          img: {
            ...prev[nameKey].img,
            value: [...valueCopy],
          },
        },
      };
    });
  };

  const handleImgClick = (src: string, index: number) => {
    src = src.replace("./", "");

    const selectedKeys = Object.keys(selected.current);

    if (selectedKeys.includes(src)) {
      unselect(src, index);
      return;
    }

    if (uploadReq.max === dataImg.value.length) return;
    const element = document.getElementById(`imgBank_${src}`);

    if (element) {
      element.classList.add(styles.selectedHighlight);
    }

    selected.current[src] = index;
    setData((prev) => {
      return {
        ...prev,
        [nameKey]: {
          ...prev[nameKey],
          img: {
            ...prev[nameKey].img,
            value: [...prev[nameKey].img.value, { name: "", url: src }],
          },
        },
      };
    });
  };

  useEffect(() => {
    dataImg.value.forEach((v) => {
      const element = document.getElementById(`imgBank_${v.url}`);
      if (element) {
        element.classList.add(styles.selectedHighlight);
      }
      selected.current[v.url] = 1000;
    });
  }, []);

  return (
    <div className={styles.chooseImgContainer}>
      {dataImg.uploadReq.max > 1 && <div className={`${styles.mainText} ${styles.selectImgText} text-center`}>
        Selected Images
      </div>}
      {dataImg.value.length < 1 && (
        <div className={`${styles.noImgSelectedText} text-center`}>
          A minimum of {dataImg.uploadReq.min} image is required
        </div>
      )}
      <div className={styles.selectedImgsContainer}>
        {dataImg.value.map((value, index) => {
          return (
            <CurrrentSelectedImg
              key={index}
              unselect={unselect}
              url={value.url}
              imgBank={imgBank}
              selected={selected.current}
              data={data}
              setData={setData}
              nameKey={nameKey}
              nameRequired={uploadReq.nameRequired}
            />
          );
        })}
      </div>
      <div className={`${styles.mainText} ${styles.selectImgText}`}>
        Choose Image
      </div>
      <div className={styles.imgBank}>
        {imgBank &&
          imgBankKeys.map((src: string, index: number) => {
            return (
              <div
                className={styles.imgContainer}
                id={`imgBank_${src.replace("./", "")}`}
                onClick={() => handleImgClick(src.replace("./", ""), index)}
                key={index}
              >
                <img className={styles.imgTag} src={imgBank(src)} alt="" />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SelectImg;
