import React from "react";

import ApiService from "../../../Services/apiService";

export const MissionVerificationLogic = (
  status,
  student_mission_ids,
  reloadHandler = null
) => {
  const apiService = new ApiService();

  const missionVerification = async (status, student_mission_ids) => {
    if (!student_mission_ids) return;
    student_mission_ids = student_mission_ids.map((ids) => {
      ids = ids.split("_");
      return {
        studentId: ids[0],
        studentDb: ids[1],
        missionId: ids[2],
      };
    });
    const [missionVerificationResp, err] = await apiService.post({
      path: "/mission_verification",
      data: {
        student_mission_ids: student_mission_ids,
        status: status,
      },
      dataType: "admin",
    });
    if (reloadHandler) {
      reloadHandler((prev) => prev + 1);
    } else {
      if (missionVerificationResp.status === 200) {
        return true;
      }
    }
  };
  return missionVerification(status, student_mission_ids);
};
