import React from 'react';
import { Link } from "react-router-dom";
import { Button } from '../../../../Components/button/button';

import styles from '../homePage.module.scss';

import miniGirl from '../../../../assets/images/website/girlSmall.png';
import miniBoy from '../../../../assets/images/website/boySmall.png';

export const JoinSection = (props) => {
    return (
        <div className={styles.joinSection}>
            <div className="row" style={{ margin: '0 auto', width: '75%', alignItems: 'center' }}>
                <div className="col-lg-3 col-md-12">
                    <img src={miniGirl} class="img-fluid" alt="" style={{ maxHeight: '50vh' }} />
                </div>
                <div class="col-lg-6 col-md-12" >
                    <h3 style={{ margin: '50px' }}>Ready to join Super Me?</h3>
                    <Link to={"/sign-up/?_form=student"}>
                        <Button>
                            Join Super Me
                        </Button>
                    </Link>
                </div>
                <div className="col-lg-3 col-md-12" >
                    <img src={miniBoy} class="img-fluid" alt="" style={{ maxHeight: '50vh' }} />
                </div>
            </div>
        </div>
    );
};