import { useEffect, useRef, useState } from "react";

import { MissionsTable } from "../../../Components/missionTable/missionsTable";
import CreateMission from "./CreateMission";
import EditMission from "./EditMission";
import Overlay from "../../../../Components/overlay/Overlay";

import { Mission, RenderableContent, SetState } from "../../../../types";
import {
  CreatorData,
  CreatorGameboardConfig,
  CurrentCategories,
} from "../types";

import useAddMissionHandler from "../hooks/useAddMissionHandler";

type Props = {
  missions: Mission[];
  gameboardConfigs: CreatorGameboardConfig[];
  setCreatorData: SetState<CreatorData | null>;
};

const tableSetup = [
  { accessorKey: "category", header: "Category" },
  { accessorKey: "task", header: "Task" },
];

const Missions = ({ missions, gameboardConfigs, setCreatorData }: Props) => {
  const addMissionHandler = useAddMissionHandler();
  const [currentMission, setCurrentMission] = useState<Mission | null>(null);
  const currentRewardTypes = useRef<string[]>([]);
  const currentCategories = useRef<CurrentCategories>([
    { name: "", img: [""] },
  ]);
  const [openDialog, setOpenDialog] = useState<{
    open: boolean;
    content: RenderableContent;
  }>({ open: false, content: null });

  useEffect(() => {
    missions.forEach((mission) => {
      if (
        !currentCategories.current.some(
          (category) => category.name === mission.category
        )
      ) {
        currentCategories.current.push({
          name: mission.category,
          img: [mission.image],
        });
      }
      if (!currentRewardTypes.current.includes(mission.rewardType)) {
        currentRewardTypes.current.push(mission.rewardType);
      }
    });
  }, []);

  const onClickAddMissionHandler = (
    mission: Omit<Mission, "_id">,
    addToGame: string
  ) => {
    return addMissionHandler(mission, addToGame, setCreatorData, setOpenDialog);
  };

  const creatMission = (
    <CreateMission
      missions={missions}
      gameboardConfigs={gameboardConfigs}
      addMissionHandler={onClickAddMissionHandler}
      currentCategories={currentCategories.current}
      currentRewardTypes={currentRewardTypes.current}
    />
  );

  const displayAddMission = (): void => {
    setOpenDialog({
      open: true,
      content: creatMission,
    });
  };

  const editMissionBtn = {
    text: "Edit",
    clickHandler: (missionId: string[]): void => {
      const mission = missions.find((mission) => mission._id === missionId[0]);
      if (!mission) return;
      setCurrentMission(mission);
    },
  };

  return (
    <>
      {currentMission && (
        <EditMission
          setCreatorData={setCreatorData}
          currentMission={currentMission}
          currentCategories={currentCategories.current}
          currentRewardTypes={currentRewardTypes.current}
          closeEditMissions={() => setCurrentMission(null)}
        />
      )}
      {openDialog.open && (
        <Overlay
          closeHandler={(close: boolean) =>
            setOpenDialog({
              open: close,
              content: null,
            })
          }
          content={openDialog.content}
        />
      )}

      <MissionsTable
        title={"Missions"}
        tableSetup={tableSetup}
        data={missions}
        customBtn={editMissionBtn}
        tooltip={{ value: "addIcon", action: displayAddMission }}
        clickHandler={(missionId) => editMissionBtn.clickHandler([missionId])}
      />
    </>
  );
};

export default Missions;
