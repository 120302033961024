import * as React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import styles from "./DropdownBtn.module.css";

export default function DropdownBtn({ dropdownButton, id }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuRef = useRef(null);

  // Handle button click to open menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing the menu
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // Handle click outside menu
  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
      handleClose();
    }
  }, [handleClose, anchorEl]);

  // Handle scroll event to close the menu
  const handleScroll = useCallback(() => {
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (open) {
      window.addEventListener("scroll", handleScroll, true);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      window.removeEventListener("scroll", handleScroll, true);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listeners on unmount or menu close
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleScroll, handleClickOutside]);

  return (
    <div className={styles.btn_container} ref={menuRef}>
      <button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={styles.dropdown_button}
        onClick={handleClick}
      >
        {dropdownButton.text}
        <span className={styles.arrow_down}>▼</span>
      </button>
      {open && (
        <ul
          id="demo-customized-menu"
          className={styles.dropdown_menu}
          aria-labelledby="demo-customized-button"
        >
          {dropdownButton.btns.map((btn) => (
            <li key={btn.text} className={styles.dropdown_menu_item}>
              <button
                id={id}
                onClick={(e) => {
                  btn.handleClick(e);
                  handleClose();
                }}
                className={styles.menu_item_button}
              >
                {btn.text}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
