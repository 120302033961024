import React from 'react';
import { Header } from './components/header/header';
import { MainMenu } from '../../Components/mainMenu/mainMenu';
import { HeroSection } from './components/hero-section';
import { CoreValuesSection } from './components/core-values-section';
import { HowItWorksSection } from './components/how-it-works-section';
import { WhySection } from './components/why-section';
import { JoinSection } from './components/join-section';
import MainFooter from "../../Components/mainFooter/MainFooter";


import styles from './homePage.module.scss';

export const HomePage = (props) => {
  return (
    <div className={styles.homePage}>
      <Header menu={<MainMenu />} sticky />
      <HeroSection />
      <HowItWorksSection />
      <CoreValuesSection />
      <WhySection />
      <JoinSection />
      <MainFooter />
    </div>
  );
};
