import React from "react";

import FormInput from "../../../../Components/FormInput";

import { validation } from "../../../../Util/Validation";
import main_css from "../../../../assets/main_css/main.module.css";

const ChangePassForm = ({
  currentPass,
  setCurrentPass,
  newPassVali,
  newPass,
  setNewPass,
  confirmPass,
  setConfirmPass,
  handleChangePassword,
}) => {
  const mainContent = (
    <div

      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <form onSubmit={handleChangePassword} id="ChangePasswordTab">
        <div className="m-3 password">
          <div className="align-items-center d-flex" style={{ flexDirection: "column" }}>
            <h4 className={`text-center ${main_css.themeFontFamily} ${main_css.themeColor_Bold}`} style={{ fontWeight: 'bold' }}>Student Password Change</h4>
            <div className="row" style={{ padding: '12px', maxWidth: '400px', }}>
              <div className="col-12">
                <div className="form-group ajax-field">
                  <FormInput
                    type={"password"}
                    value={currentPass}
                    placeholder={"Current Password"}
                    classes={"changePassInput"}
                    name={"currentPass"}
                    onChangeHandler={setCurrentPass}
                    pattern={validation.password.pattern}
                    message={validation.password.message}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group ajax-field">
                  <FormInput
                    type={"password"}
                    value={newPass}
                    placeholder={"New Password"}
                    classes={"changePassInput"}
                    name={"newPass"}
                    onChangeHandler={setNewPass}
                    pattern={newPassVali.pattern}
                    message={newPassVali.message}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group ajax-field">
                  <FormInput
                    type={"password"}
                    value={confirmPass}
                    placeholder={"Confirm Password"}
                    classes={"changePassInput"}
                    name={"confirmPass"}
                    onChangeHandler={setConfirmPass}
                    pattern={newPass}
                    message={"You'r passwords do not match"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className={main_css.basic_btnInv}
              style={{ padding: '5px 10px', }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
  return (<>{mainContent} </>);
};

export default ChangePassForm;
