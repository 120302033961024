import React from "react";
import { Data } from "../types";
import styles from "../creatorPage.module.scss";
import { RenderableContent } from "../../../../types";

type Props = {
  data: Data;
  currentStepName: keyof Data;
  options: RenderableContent[];
  value?: { field: string; subField: string };
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SelectInput = ({
  data,
  currentStepName,
  options,
  handleChange,
  value,
}: Props) => {
  let displayValue: string | number = "";
  if (!value) {
    displayValue = data[currentStepName].value[0];
  } else {
    const fieldData = data[value.field as keyof Data];
    if (typeof fieldData === "object" && value.subField in fieldData) {
      displayValue = (fieldData as any)[value.subField].value || "";
    } else {
      displayValue = "";
    }
  }

  return (
    <div
      className={`${styles.selectInputContainer} ${data[currentStepName].err}`}
    >
      <select
        className={`${styles.selectInput} form-select`}
        onChange={(e) => {
          handleChange(e);
        }}
        value={displayValue}
      >
        {options}
      </select>
    </div>
  );
};

export default SelectInput;
