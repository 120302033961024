import React from "react";

import CustomBtnComponent from "./CustomBtnComponent";
import TableDropdownBtn from "./TableDropdownBtn";

const SelectedTooltip = ({
  verify,
  selected,
  buttonHandler,
  selectSingle,
  tooltipSelected,
}) => {
  if (verify)
    return (
      <TableDropdownBtn
        ids={selected}
        buttonHandler={buttonHandler}
        selectSingle={selectSingle}
      />
    );
  return (
    <CustomBtnComponent
      text={tooltipSelected.text}
      clickHandler={tooltipSelected.clickHandler}
      rowId={selected}
    />
  );
};

export default SelectedTooltip;
