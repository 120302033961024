import { Mission, RenderableContent, SetState } from "../../../../types";
import { CreatorData, Data } from "../types";
import useNavigateDialog from "./useNavigateDialog";
import useSetDialogHook from "./useSetDialogHook";
import useEditMission from "./useEditMission";

const useUpdateEditDialog = () => {
  const navigateDialog = useNavigateDialog();
  const editMission = useEditMission();
  const setDialogHook = useSetDialogHook();

  const updateEditDialog = (
    setCreatorData: SetState<CreatorData | null>,
    currentMission: Mission,
    setDialogContent: SetState<RenderableContent>,
    currentStep: RenderableContent,
    name: string,
    data: Data,
    setData: SetState<Data>,
    currentStepNum: number,
    setCurrentStepNum: SetState<number>,
    stepDataKeys: string[],
    closeEditMissions: () => void
  ) => {
    const stepLength = stepDataKeys.length;
    let continueBtn: "Next" | "Update" = "Next";
    let handleContinueBtnClick: () => void;

    if (stepLength - 1 === currentStepNum) {
      continueBtn = "Update";
      handleContinueBtnClick = () =>
        editMission(
          setCreatorData,
          currentMission,
          data,
          stepDataKeys,
          setDialogContent,
          closeEditMissions
        );
    } else {
      handleContinueBtnClick = () =>
        navigateDialog(
          name,
          data,
          setData,
          currentStepNum,
          setCurrentStepNum,
          1,
          stepLength
        );
    }

    setDialogHook({
      setDialogContent,
      currentStep,
      handleContinueBtnClick,
      continueBtn,
      name,
      data,
      setData,
      currentStepNum,
      setCurrentStepNum,
      stepLength,
    });
  };
  return updateEditDialog;
};

export default useUpdateEditDialog;
