import { useCallback } from "react";

const useUpdateCampaign = () => {
  const updateCampaignContingencies = useCallback(
    (setCurrentCampaign, noneTableContingencies, newValue) => {
      setCurrentCampaign((prev) => {
        const prevCopy = { ...prev };
        noneTableContingencies?.forEach((contingency) => {
          prevCopy[contingency] += newValue;
        });
        return prevCopy;
      });
    },
    []
  );
  return updateCampaignContingencies;
};

export default useUpdateCampaign;
