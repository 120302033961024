import React from "react";

import { MissionsTable } from "../../../Components/missionTable/missionsTable";

import ApiService from "../../../../Services/apiService";

const tableSetup = [
  { accessorKey: "fname", header: "First Name" },
  { accessorKey: "lname", header: "Last Name" },
  { accessorKey: "date", header: "Date" },
];

const UserVerification = ({ reloadHandler, unverifiedUsers }) => {
  const apiService = new ApiService();

  const userVerification = async (status, studentIds_usernames ) => {
    // let studentIds_usernames = event.target.id;
    // let studentIds_usernames_str = studentIds_usernames.split(",");
    studentIds_usernames = studentIds_usernames.map((id_username) => {
      id_username = id_username.split("_");
      return {
        studentId: id_username[0],
        username: id_username[1],
        studentDb: id_username[2],
      };
    });
    const [userVerificationResp, err] = await apiService.post({
      path: "/user_verification",
      data: {
        studentIds_usernames: studentIds_usernames,
        status: status,
      },
      dataType: "admin",
    });
    reloadHandler((prev) => prev + 1);
  };
  console.log(unverifiedUsers);
  return (
    <>
      <MissionsTable
        title={"Verify Users"}
        verify
        tooltipSelected
        checkbox
        data={unverifiedUsers}
        buttonHandler={userVerification}
        tableSetup={tableSetup}
      />
    </>
  );
};

export default UserVerification;
