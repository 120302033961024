import React from 'react';
import { Link } from "react-router-dom";
import styles from './mainMenu.module.scss';
import { Button } from '../../../Components/button/button';

export const MainMenu = (props) => {
  return (
    <div className={styles.menu}>
      {/* <Link className={styles.link} to="/home">
        Home
      </Link> */}
      <Link className={styles.link} to="/sign-up">
        Sign Up
      </Link>
      <Link className={styles.link} to="/login">
        Login
      </Link>
      <Link to={"/bookdemo"}>
        <Button >Book Demo</Button>
      </Link>
    </div>
  );
};
