import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AdminTemplate from "../Components/adminTemplate/AdminTemplate";
import { MissionsTable } from "../Components/missionTable/missionsTable";

import ApiService from "../../Services/apiService";

const UsersPage = () => {
  const apiService = new ApiService();
  const [usersInfo, setUsersInfo] = useState();
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(true);
  const navigate = useNavigate();

  const tableSetup = [
    { accessorKey: "fname", header: "First Name" },
    { accessorKey: "lname", header: "Last Name" },
    { accessorKey: "date", header: "Date" },
    { accessorKey: "last_mission", header: "Last Mission" },
  ];

  const clickHandler = (id) => {
    navigate(`/user/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userInfoResp, err] = await apiService.get({
          path: "/last_missions",
          dataType: "admin",
        });
        if (userInfoResp) setUsersInfo(userInfoResp.data.last_missions);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const mainContent = (
    <MissionsTable
      data={usersInfo}
      tableSetup={tableSetup}
      title="Students"
      clickHandler={clickHandler}
    />
  );
  return (
    <>
      <AdminTemplate pageContent={mainContent} />
    </>
  );
};

export default UsersPage;
