import { useCallback } from "react";
import ApiService from "../../Services/apiService";

const apiService = new ApiService();

const useUpdateInfo = () => {
  const updateInfo = useCallback(
    async (route, ItemId, path, value, arrayFilters) => {
      try {
        await apiService.post({
          path: route,
          dataType: "admin",
          data: {
            id: ItemId,
            path: path,
            value: value,
            arrayFilters: arrayFilters,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );
  return updateInfo;
};

export default useUpdateInfo;
