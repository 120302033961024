import React from 'react'
import { Link } from "react-router-dom";

import cssModule from '../achievementsPage.module.css'

const CarouselBlock = ({ bannerImg, title, statValue, button }) => {
  return (
    <div className={cssModule.carouselBlock} key={title}>
      <div className={cssModule.ach_carasolItem}>
        <div className={cssModule.ach_carasolItem_bkg}>
          <div className={cssModule.carouselImgContainer}>
            <img
              className={`${cssModule.bannerImg} ${cssModule.coinBanner}`}
              src={bannerImg}
              alt=""
            />
          </div>
          <div className={cssModule.carouselInner}>
            <div className={`text-center ${cssModule.box_text}`}>
              <div
                className={`heading_foam text-center text-uppercase lable ${cssModule.text}`}
              >
                {title}
              </div>
              <h3 className={`${cssModule.box_value} ${cssModule.text}`}>
                {statValue}
              </h3>
            </div>
            {button && <Link to={button.url}>
              <div className={cssModule.markMissionContainer}>

                {/* <img
                    className={cssModule.markMissionImg}
                    src={images["markMission"]}
                    alt=""
                  /> */}
                <div className={`${cssModule.carouselBlockButton}`}>
                  {button.text}
                </div>
              </div>
            </Link>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CarouselBlock