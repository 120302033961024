import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ApiService from "../../../Services/apiService";
import MainNavBar from "../../Components/mainNavbar/MainNavbar";
import MainFooter from "../../Components/mainFooter/MainFooter";
import Overlay from "../../../Components/overlay/Overlay";

import SignUpOnVerify from "./components/SignUpOnVerify";

import main_css from "../../../assets/main_css/main.module.css"

const VerifyPage = () => {
  const apiService = new ApiService();
  const params = useParams();
  const [dialog, setDialog] = useState({
    loading: true,
    verified: false,
  });
  const [contactModal, setContactModal] = useState(null);
  const [signUpDialog, setSignUpDialog] = useState({ open: false, content: null });

  useEffect(() => {
    setDialog({
      loading: true,
      verified: false,
    });

    const fetchData = async () => {
      try {
        const [response, error] = await apiService.post({
          path: "/verify_student",
          dataType: "sign_up",
          data: {
            token: params.token,
          },
        });

        if (!error) {
          setDialog({
            loading: false,
            verified: true,
          });
          
          // Parent create account if no parent account present
          if (response.data.data.parentEmail) {
            setTimeout(() => {
              setSignUpDialog({
                open: true,
                content: <SignUpOnVerify
                  mainText={"Parent Sign Up"}
                  subText={"Create an account to track your students progress"}
                  verifyToken={params.token}
                  def_email={response.data.data.parentEmail}
                  closeHandler={() => setSignUpDialog({
                    open: false,
                    content: null
                  })} />
              });
            }, 1000)
          }
        } else {
          setDialog({
            loading: false,
            verified: false,
          });
        }
      } catch (error) {
        setDialog({
          loading: false,
          verified: false,
        });
      }

    };


    fetchData();

    return () => {
    };
  }, []);

  const showContactModal = () => {
    const ModalContent = () => {
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');

      const sendEmail = async () => {
        // Call your API endpoint here
        // const response = await api.sendEmail(email, message);

        // Based on the response, you can add your if-else statement here
        // if (response.success) {
        //   // Do something
        // } else {
        //   // Do something else
        // }
      };

      return (
        <div>
          <h1>Contact Us</h1>
          <p>Contact us at: **Your Contact Number**</p>

          <h2>Email Us</h2>
          <input
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button onClick={sendEmail}>Send Email</button>
        </div>
      );
    };

    setContactModal(
      {
        Component: ModalContent,
        onClose: closeModal
      }
    );
  };

  const closeModal = () => {
    setContactModal(null);
  }

  const centerContent = dialog.loading ? (
    <>
      <h1>Hang Tight!</h1>
      <h2>while we verify your child's account</h2>
    </>
  ) : dialog.verified ? (
    <>
      <h1>Done!</h1>
      <h2>Your child's account is now verified</h2>
      <div style={{ padding: '20px' }} />
      <a className={main_css.basic_btn} href="/">
        Back to Home
      </a>
    </>
  ) : (
    <>
      <h1>Oops!</h1>
      <h2>Something went wrong</h2>
      <div style={{ padding: '5px' }} />
      <p>
        We are sorry, but we could not verify your students account! please
        contact support if this continues to happen
      </p>
      <div style={{ padding: '20px', display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
        <a className={main_css.basic_btn} href="/">
          Back to Home
        </a>
        <a className={main_css.basic_btn} href="/contactUs">
          Contact Support
        </a>
      </div>
    </>
  );

  const mainContent = (
    <div
      className={`${main_css.verifyPage}`}>
      <div className={`${main_css.verifyPageMessage}`}>
        {centerContent}
      </div>
    </div>
  );

  return (
    <>
      <MainNavBar />
      {signUpDialog.open && (
        <Overlay
          closeHandler={(close) =>
            setSignUpDialog({
              open: close,
              content: null,
            })
          }
          content={signUpDialog.content}
        />
      )}
      {mainContent}
      <MainFooter />
    </>
    // </div>
  );
};

export default VerifyPage;
