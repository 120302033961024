export function arrayToDictionary(array, keyField) {
  if (!Array.isArray(array)) {
    throw new Error("Input must be an array.");
  }

  if (typeof keyField !== "string") {
    throw new Error("Key field must be a string.");
  }

  const dictionary = {};
  array.forEach((item) => {
    if (!item.hasOwnProperty(keyField)) {
      throw new Error(
        `Key field '${keyField}' does not exist in one or more objects.`
      );
    }

    const keyValue = item[keyField];
    if (!dictionary[keyValue]) {
      dictionary[keyValue] = item;
    } else {
      console.log(`Key field '${keyField}' already exist in dictionary!`);
    }
  });
  return dictionary;
}

export function sortObjectByValues(obj, order = "") {
  if (order == "") {
    console.log("No order given to sort");
    return obj;
  }

  const sortedEntries = Object.entries(obj).sort((a, b) =>
    order === "desc" ? b[1] - a[1] : a[1] - b[1]
  );
  return new Map(sortedEntries);
}

export function unixDateConverter(unixDate) {
  let date = new Date(unixDate);
  date = date.toLocaleString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  if (date.toString() === "Invalid Date") {
    date = "";
  }
  return date;
}

export function formatDateString(dateString) {
  // Create a Date object from the string
  const date = new Date(dateString);

  // Use Intl.DateTimeFormat to format the date
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export function isISODateString(str) {
  // Regular expression to match ISO 8601 date strings
  const isoDatePattern =
    /^(\d{4}-[01]\d-[0-3]\d([T ]([0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?(Z|([+-][01]\d:[0-5]\d))?)?)?)?$/;
  return isoDatePattern.test(str);
}

export function shortNumFormat(num) {
  if (num < 1000) return num.toString();

  const units = ["", "k", "M", "B", "T"];
  const exponent = Math.floor(Math.log10(num) / 3);
  const unit = units[exponent];
  const scaledNum = num / Math.pow(1000, exponent);

  return `${parseFloat(scaledNum.toFixed(2))}${unit}`;
}

export function capitalizeWords(str) {
  if (str)
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  else return "";
}

export function gradeSuffix(grade) {
  let gradeValue =
    grade === 1
      ? "1st"
      : grade === 2
      ? "2nd"
      : grade === 3
      ? "3rd"
      : `${grade}th`;
  return gradeValue;
}

// Function to get the pageName from the URL
export function getPageNameFromURL(pageName) {
  // Get the current pathname
  const pathname = pageName;

  // Split the pathname by '/' and get the second element
  const parts = pathname.split('/');

  // Return the second element if it exists, otherwise return an empty string
  return parts.length > 1 ? parts[1] : '';
};

// Function to escape special characters for RegExp construction
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
export function regexBuilder(value) {
  const regexPattern = value.map(op => `(${escapeRegExp(op)})`)
    .join('|');
  const regex = new RegExp(regexPattern, 'g');

  return regex;
}
