import { RenderableContent } from "../../../../types"
import styles from "../creatorPage.module.scss"

type Props = {
  message: RenderableContent
  closeHandler: () => void
}

const ActionSuccessStatus = ({message, closeHandler}: Props) => {
  return (
    <div className={styles.mainContainer}>
    <div className={`${styles.innerContainer} ${styles.completed}`}>
      <div className={`${styles.mainText} text-center`}>{message}</div>
    </div>
    <button
      className={`${styles.btn} btn ${styles.continueBtn}`}
      onClick={() => closeHandler()}
    >
      Close
    </button>
  </div>
  )
}

export default ActionSuccessStatus