import { MissionsTable } from "../../../Components/missionTable/missionsTable";

type Props = {
  gameboards: Array<{ [key: string]: any }>;
};

const tableSetup = [
  { accessorKey: "name", header: "Name" },
  { accessorKey: "image", header: "Image" },
];

const Gameboard = ({ gameboards }: Props) => {
  return (
    <MissionsTable
      title="Gameboards"
      tableSetup={tableSetup}
      data={gameboards}
    />
  );
};

export default Gameboard;
