import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./Overlay.module.css";

const Overlay = ({ content, closeHandler }) => {
  return (
    <div className={styles.container}>
      <div className={styles.outerContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.closeIconWrapper}>
              <div className={styles.closeIconContainer}>
                <div className={styles.iconBorder}>
                  <IconButton onClick={() => closeHandler()}>
                    <CloseIcon className={styles.closeIcon} />
                  </IconButton>
                </div>{" "}
              </div>
            </div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
