import { ReactNode, useEffect, useState } from "react";

import styles from "../creatorPage.module.scss";
import { CreatorGameboardConfig, Data, GameboardNames } from "../types";
import GameNameStep from "./GameNameStep";
import useUpdateCreateGameDialog from "../hooks/useUpdateCreateGameDialog";
import SelectGameStep from "./SelectGameboardStep";
import { Mission, RenderableContent } from "../../../../types";
import SelectMissionsStep from "./SelectMissionsStep";
import CreateGameSummary from "./CreateGameSummary";

type Props = {
  gameboardNames: GameboardNames;
  currentMissions: Mission[];
  onClickAddGameHandler: (
    game: Partial<
      Pick<CreatorGameboardConfig, "gameboardId" | "missionIds" | "name">
    >
  ) => Promise<RenderableContent>;
};

const imgObj = {
  value: [
    {
      name: "",
      url: "",
    },
  ],
  err: "",
  new: false,
  display: styles.hidden,
  uploadReq: {
    required: false,
    nameRequired: false,
    min: 0,
    max: 0,
  },
};

const inputFieldObj = {
  value: "",
  err: "",
  display: styles.display,
};

const CreateGame = ({
  gameboardNames,
  currentMissions,
  onClickAddGameHandler,
}: Props) => {
  const updateCreateGameDialog = useUpdateCreateGameDialog();
  const [currentStepNum, setCurrentStepNum] = useState(0);
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [data, setData] = useState<Data>({
    gameName: {
      value: [""],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: [],
      requiredSubData: ["inputField"],
    },
    gameboard: {
      value: [""],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: ["gameboard"],
      requiredSubData: [],
    },
    missions: {
      value: [""],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: ["missions"],
      requiredSubData: [],
    },
    createGameSummary: {
      value: ["confirm"],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: [],
      requiredSubData: [],
    },
  });

  const stepsDataKeys = [
    "gameName",
    "gameboard",
    "missions",
    "createGameSummary",
  ];

  const steps = [
    <GameNameStep data={data} setData={setData} />,
    <SelectGameStep
      data={data}
      setData={setData}
      gameboardNames={gameboardNames}
    />,
    <SelectMissionsStep
      data={data}
      setData={setData}
      currentMissions={currentMissions}
    />,
    <CreateGameSummary
      data={data}
      setData={setData}
      currentMissions={currentMissions}
      gameboardNames={gameboardNames}
    />,
  ];

  useEffect(() => {
    updateCreateGameDialog(
      stepsDataKeys[currentStepNum],
      steps[currentStepNum],
      currentStepNum,
      stepsDataKeys.length,
      setCurrentStepNum,
      setDialogContent,
      data,
      setData,
      onClickAddGameHandler
    );
  }, [data, currentStepNum]);

  return dialogContent || null;
};

export default CreateGame;
