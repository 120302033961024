import React from "react";

const ContentBack = ({ content }) => {

    const curveStyle = {
        // position: 'absolute',
        // top: '50%', 
        width: '100%',
        zIndex: '-1'
    };

    return (
        // <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
        <div
            style={{
                backgroundColor: 'rgba(68, 94, 170, 0.50)',
                borderRadius: '10%',
                padding: '5%',
                height: '100%',
                display: 'flex',
                width: "100%",
                // margin: "auto",
            }}
        >
            {/* Curved line pointing down */}

            <div style={{
                backgroundColor: 'rgba(68, 94, 170, 0.75)',
                borderRadius: '5%',
                padding: '5%',
                height: '100%',
                width: "100%",
            }}>

                {content}

            </div>
            {/* Curved line pointing up */}

        </div>
        // </div>

    );
}


export default ContentBack;