import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from '@tanstack/react-query'
import Cookies from 'js-cookie';

import { validation } from "../../../../Util/Validation";

import FormInput from "../../../../Components/FormInput";
import DialogTemplate from "../../../../Components/DialogTemplate";
import ApiService from "../../../../Services/apiService";
import useBreakpoint from "../../../../Hooks/use-breakpoint";


import main_css from "../../../../assets/main_css/main.module.css";

const LoginForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { breakpoint } = useBreakpoint();
  const apiService = new ApiService(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [dialog, setDialog] = useState(null);

  let classes = "";

  if (loginError === true) {
    classes = "loginError";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDialog({
      mainText: `Hang Tight While we Log You In!`,
      subText: "Ready for todays journey?",
      duration: "0ms",
    });
    try {
      const [loginResp, error] = await apiService.post({
        path: "/",
        data: {
          username: email,
          password: password,
        },
        dataType: "login",
      });

      if (loginResp && loginResp.status === 200) {
        Cookies.set('user', JSON.stringify(loginResp.data), { expires: 1 });
        let name = loginResp.data.name.split(" ")[0]
        setDialog({
          mainText: `Welcome Back ${name}!`,
          subText: "Ready for todays journey?",
        });

        //Clear the query cache
        queryClient.clear();

        setTimeout(() => {
          if (loginResp.data.role === "student") navigate("/achievements");
          else navigate("/dashboard");
          if (breakpoint === 'mobile') {
            navigate(0);
          }
        }, 3000);
      } else if (
        error.response &&
        error.response.data.message === "Unverified"
      ) {
        setDialog({
          mainText: "Account not verified!",
          subText:
            "Please ask you parent to check their email.\nIf this issue continues please reach out to support.",
          duration: "1000ms",
          subTextClass: "mx-2",
          button1Text: 'Contact Us',
          onClickButton1: () => navigate("/contactUs"),
          closeHandler: () => setDialog(null),
        });
      } else if (
        error.response &&
        error.response.data.message === "Unauthorized"
      ) {
        setLoginError(true);
        setDialog(null);
      }
    } catch (err) {
      console.log(err);
      setDialog({
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: () => setDialog(null),
      });
    }
  };

  const mainContent = (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{ width: "100%", height: "100%" }}
          className="d-flex flex-column justify-content-center "
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className="d-flex flex-column align-items-center justify-content-center "
          >
            <h1 className={` ${main_css.title} ${main_css.noAfter}`} style={{ color: "white" }}>Login</h1>
            <div className="row text-center pt-5 mt-2">
              <div className="col-9 mx-auto">
                <h4 className="heading_foam" style={{ color: "white" }}>Existing User</h4>
                <div className="form-group mb-2 ajax-field">
                  <FormInput
                    type={"email"}
                    value={email}
                    placeholder={"Email"}
                    name={"email"}
                    onChangeHandler={setEmail}
                    message={validation.email.message}
                    pattern={validation.email.pattern}
                    classes={classes}
                  />
                </div>
              </div>
              <div className="col-9 mx-auto">
                <div className="ajax-field">
                  <FormInput
                    type={"password"}
                    value={password}
                    placeholder={"Password"}
                    name={"password"}
                    onChangeHandler={setPassword}
                    classes={classes}
                  />
                  <span className={`loginErrorText ${classes}`}>
                    Incorrect Email Or Password
                  </span>
                </div>
              </div>
              <div className="text-center">
                <button
                  id="login"
                  type="submit"
                  className={main_css.basic_btnInv}
                  style={{ marginBlock: '3%', padding: '5px 10px' }}
                >
                  Log In
                </button>
              </div>
              <div className="pt-xl-2">
                <Link
                  to="/forgot-password"
                  className="back_btn"
                  style={{ marginRight: "10px", color: "white" }}
                >
                  Forgot Password
                </Link>
                <Link to="/sign-up?_form=student" className="back_btn" style={{ color: "white" }}>
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {dialog && <DialogTemplate {...dialog} />}

      {mainContent}
    </>
  );
};

export default LoginForm;