type Props = {
  option: {
    name: string;
    customData?: Record<string, any>;
    value: string;
  };
};

const SelectInputOption = ({ option }: Props) => {
  return (
    <option key={option.name} {...option.customData} value={option.value}>
      {option.name}
    </option>
  );
};

export default SelectInputOption;
