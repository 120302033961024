import React, { useCallback, useState, useEffect } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import styles from './horizontalScroll.module.scss';
import clsx from 'clsx';

export const HorizontalScroll = (props) => {
  const ref = React.useRef(null);

  const [isOverflowing, setIsOverflowing] = useState(true);

  const checkOverflow = useCallback(() => {
    const content = ref.current;
    if (content) {
      const hasOverflow = content.scrollWidth > content.parentNode.clientWidth;
      setIsOverflowing(hasOverflow);
    }
  }, [ref]);

  useEffect(() => {
    const content = ref.current;
    let resizeObserver;

    if (content) {
      // Create a ResizeObserver to listen for changes in size
      resizeObserver = new ResizeObserver((entries) => {
        // Run the overflow check whenever a resize is observed
        for (let entry of entries) {
          checkOverflow();
        }
      });
      // Start observing the referenced element
      resizeObserver.observe(content);
    }

    // Clean up the observer on component unmount
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [checkOverflow]);

  const scrollLeft = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({ left: -40, behavior: 'smooth' });
    }
  }, []);

  const scrollRight = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({ left: 40, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={clsx(styles.horizontalScroll, props.className)}>
      {isOverflowing && (
        <IconChevronLeft className={clsx(styles.arrow, styles.leftArrow)} onClick={scrollLeft} />
      )}
      <div className={isOverflowing ? clsx(styles.content, 'no-scrollbar') : ''} ref={ref}>
        {props.children}
      </div>
      {isOverflowing && (
        <IconChevronRight className={clsx(styles.arrow, styles.rightArrow)} onClick={scrollRight} />
      )}
    </div>
  );
};