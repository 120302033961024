import React, { useState, useEffect, useRef } from "react";

const CharacterAnimateAudio = ({
  imgSrc,
  gifSrc,
  audioSrc,
  className,
  style,
}) => {
  const [src, setSrc] = useState(imgSrc);
  const audio = useRef(new Audio(audioSrc));

  useEffect(() => {
    if (audioSrc) {
      audio.current.addEventListener('canplaythrough', () => {
        // Audio is ready to play
      }, false);
    }

  }, [audioSrc]);

  useEffect(() => {
    if (audioSrc) {
      // Add event listener for when the audio ends
      audio.current.addEventListener("ended", () => setSrc(imgSrc));

      // Clean up event listener on component unmount
      return () => {
        // First make sure stop any playing audio
        audio.current.pause();
        audio.current.currentTime = 0;

        // Remove Listener
        audio.current.removeEventListener("ended", () => setSrc(imgSrc));
      };
    }
  }, [audioSrc, imgSrc]);

  const handleClick = () => {
    if (!gifSrc || !audioSrc) {
      // If gifSrc or audioSrc is null or empty, do nothing
      return;
    }

    if (src !== gifSrc) {
      setSrc(gifSrc);
      audio.current.play();
    } else {
      audio.current.pause();
      audio.current.currentTime = 0;
      setSrc(imgSrc);
    }
  };

  return (
    <img
      src={src}
      alt=""
      className={className}
      style={style}
      onClick={handleClick}
    />
  );
};

export default CharacterAnimateAudio;
