import React from "react";

import { flexRender } from "@tanstack/react-table";

import EditableRow from "./EditableRow";

import styles from "../missionsTable.module.scss";

import {
  unixDateConverter,
  shortNumFormat,
  gradeSuffix,
} from "../../../../Util/utils";

const TableBody = ({
  rows,
  clickHandler,
  selected,
  editable,
  updateDom,
  updateDomHandler,
  setRowEdit,
  editingBtnValue,
  checkValidation,
}) => {
  return (
    <tbody>
      {rows.map((row) => {
        const id = row.original.id ? row.original.id : row.original._id;
        if (row.original.date) {
          row.original.date = unixDateConverter(
            row.original.date,
            row.original.comDate
          );
        } else if (row.original.comDate) {
          row.original.comDate = unixDateConverter(row.original.comDate);
        } else if (row.original.grade && !isNaN(row.original.grade)) {
          row.original.grade = gradeSuffix(row.original.grade);
        } else if (
          row.original.rawData &&
          row.original.rawData.grade &&
          row.original.rawData.grade.value &&
          !isNaN(row.original.rawData.grade.value)
        ) {
          row.original.rawData.grade.value = gradeSuffix(
            row.original.rawData.grade.value
          );
        }

        return (
          <tr
            onClick={clickHandler ? () => clickHandler(id) : undefined}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => {
              if (
                cell.row.original.rawData &&
                Object.keys(cell.row.original.rawData).includes(
                  cell.column.columnDef.accessorKey
                )
              ) {
                const accessorKey = cell.column.columnDef.accessorKey;
                const original = cell.row.original;
                let value = original.rawData[accessorKey].value;
                if (!isNaN(value)) {
                  value = shortNumFormat(value);
                }
                cell.row.original[
                  accessorKey
                ] = `${value} ${original.rawData[accessorKey].suffix}`;
              }
              return (
                <td
                  className={
                    selected.includes(
                      cell.row.original.id || cell.row.original._id
                    )
                      ? styles.selected
                      : undefined
                  }
                  key={cell.id}
                >
                  {editable && row.original.editable ? (
                    <EditableRow
                      updateDom={updateDom}
                      updateDomHandler={updateDomHandler}
                      cell={cell}
                      checkValidation={checkValidation}
                      editingBtnValue={editingBtnValue}
                    />
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
