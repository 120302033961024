import { useQuery } from '@tanstack/react-query'
import Cookies from "js-cookie";

import ApiService from "../../Services/apiService";

const default_graphicConfig = {
  mainBkg: "./website/banner.png",
  modalBkg: "./CenterBox_lg.png",
  dialogBkg: "./CenterBox_lg.png",
  centerBoxBkg: "./CenterBox_lg.png",
  characters: [
    {
      "image": "./userSite/config/boyRight.png",
      "gif": "./userSite/config/boyRight.gif",
      "audio": "./userSite/config/boyRight.mp3",
      "pages": ["all"],
      "position": 0
    },
    {
      "image": "./userSite/config/girlLeft.png",
      "gif": "./userSite/config/girlLeft.gif",
      "audio": "./userSite/config/girlLeft.mp3",
      "pages": ["all"],
      "position": 1
    }
  ]
}

const default_sponsorConfig = null;

const useUserConfig = () => {
  const apiService = new ApiService(true);
  const role = Cookies.get("user") ? JSON.parse((Cookies.get("user") ?? "")).role : '';

  const fetchUserConfig = async () => {

    let userConfig = {
      graphicConfig: default_graphicConfig,
      sponsorConfig: default_sponsorConfig
    }

    if (role === 'student') {
      const [configResp, configError] = await apiService.get({
        path: "/user_config",
        dataType: "config",
      });
      if (!configError) {

        if (configResp.data.graphicConfig) {
          userConfig.graphicConfig = configResp.data.graphicConfig;
        }

        if (configResp.data.sponsorConfig) {
          userConfig.sponsorConfig = configResp.data.sponsorConfig;
        }


      } else {
        console.log(
          `Error Retriving Config Data:\n ${configError.toString()}`
        );

      }
    }

    return userConfig;
  };

  return useQuery({
    queryKey: ['userConfig'],
    queryFn: fetchUserConfig,
  });


};

export default useUserConfig;
