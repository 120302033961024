import { React, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import cssModule from "./questions.module.css";

const images = {
  submit: require("../../../../../assets/images/submit.png"),
};

const delimiter = ",";

const Question = ({ question, index, handleSubmit, defanswer }) => {
  const [answer, setAnswer] = useState(
    defanswer !== "" ? [...defanswer.split(delimiter)] : []
  );

  const handleInputChange = (event) => {
    setAnswer([event.target.value]);
  };

  const handleMCChange = (event) => {
    const option = event.target.value;
    setAnswer((prev) => {
      if (prev.includes(option)) {
        // If the option is already selected, remove it from the array
        return prev.filter((item) => item !== option);
      } else {
        // If the option is not selected, add it to the array
        return [...prev, option];
      }
    });
  };

  const handleButtonClick = () => {
    if (answer.length > 0) handleSubmit(index, answer.join(delimiter));
  };

  const questionChoice = {
    marginTop: "2%",
    backgroundColor: "#445eaa",
    alignContent: "center",
    justifyContent: "center",
    flex: 2,
    paddingBlock: "3%",
    paddingInline: "2%",
    width: "100%",
    // border: '1px solid red'
    "& label": {
      color: "white",
    },
    "&:hover label": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        borderWidth: "2px",
      },
    },
  };

  return (
    <div className={cssModule.questionsContainer}>
      <div className={cssModule.questionTitle}>{question.question}</div>
      {question.questionType === "multiple-choice" && (
        <RadioGroup className={cssModule.questionChoice}>
          {question.options.map((option, i) => (
            <FormControlLabel
              key={i}
              control={
                <Radio
                  checked={answer.includes(option)}
                  onClick={handleMCChange}
                  value={option}
                  sx={{
                    color: "white",
                    "& .MuiSvgIcon-root": {
                      fill: "white", // This will fill the whole bubble in white when not checked
                    },
                    "&.Mui-checked": {
                      color: "blue",
                      "& .MuiSvgIcon-root": {
                        fill: "white", // This will fill the whole bubble in white
                      },
                    },
                  }}
                />
              }
              label={
                <Typography className={cssModule.customTypography}>
                  {option}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      )}

      {question.questionType === "fill-in" && (
        <FormControl
          key={index}
          sx={{
            ...questionChoice,
            "& label": {
              color: "white",
            },
            '& label.Mui-focused': {
              color: 'white',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white',
            },
            "&:hover label": {
              color: "white",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
              "&:hover fieldset": {
                borderColor: "white",
                borderWidth: "2px",
              },
            },
          }}
        >
          <TextField
            label={"Your Answer"}
            onChange={handleInputChange}
            value={answer[0]}
            inputProps={{
              style: {
                color: 'white', // Change text color of user's input
              },
            }}
          />
        </FormControl>
      )}

      {question.questionType === "drop-down" && (
        <FormControl key={index} sx={questionChoice}>
          <FormLabel
            sx={{
              color: "white",
              '&.Mui-focused': {
                color: 'white',
              }
            }}
            component="legend"
          >
            Choose an Answer
          </FormLabel>
          <Select
            value={answer.length > 0 ? answer[0] : ""}
            onChange={handleInputChange}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: "#445eaa", // Background color for the dropdown menu
                },
              },
            }}
            sx={{
              "& .MuiSelect-select": {
                color: "white", // Change text color
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "white", // Change border color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "white", // Change border color when focused
              },
            }}
          >
            {question.options.map((option, i) => (
              <MenuItem key={i} value={option} sx={{ color: "white" }}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {defanswer === "" && (
        // <Button
        //   onClick={handleButtonClick}
        //   style={{
        //     backgroundColor: "purple",
        //     color: "white",
        //     borderRadius: "20px",
        //     fontSize: "24px",
        //     fontFamily: "tintco_demoregular",
        //     "&:hover": {
        //       backgroundColor: "darkpurple",
        //     },
        //     height: "60px",
        //     width: "100px",
        //   }}
        // >
        //   Submit
        // </Button>
        <div className={cssModule.submitContainer}>
          <img
            className={cssModule.submitBtn}
            id=""
            onClick={handleButtonClick}
            src={images["submit"]}
            alt="Close"
          />
        </div>
      )}
    </div>
  );
};

export default Question;
