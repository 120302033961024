import { SetState } from "../../../../types";
import { CreatorInputField, Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
};

const inputFields: CreatorInputField[] = [
  {
    placeholder: "New Game Name",
    subField: "inputField",
  },
];

const GameNameStep = ({ data, setData }: Props) => {
  return (
    <CreatorDialogTemplate
      name="gameName"
      data={data}
      setData={setData}
      mainText="Give Your Game A Name"
      inputField={inputFields}
    />
  );
};

export default GameNameStep;
