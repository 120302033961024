import { useCallback } from "react";
import ApiService from "../../../../Services/apiServiceT";
import { Mission } from "../../../../types";

type AddMissionResponse = {
  id: string;
};

const useAddMission = () => {
  const apiService = new ApiService();

  const addMission = useCallback(
    async (
      mission: Omit<Mission, "_id">,
      addToGame: string
    ): Promise<AddMissionResponse> => {
      try {
        const [response, error] = await apiService.post<AddMissionResponse>({
          path: "/add_mission",
          dataType: "admin",
          data: { mission, addToGame },
        });

        if (error) {
          console.error("Error adding mission:", error);
          throw error;
        }

        if (!response || !response.data) {
          throw new Error("No response from API");
        }

        return response.data;
      } catch (error) {
        console.error("Error adding mission:", error);
        throw error;
      }
    },
    []
  );

  return addMission;
};

export default useAddMission;
