import { RenderableContent, SetState } from "../../../../types";
import { CreatorGameboardConfig, Data } from "../types";
import useNavigateDialog from "./useNavigateDialog";
import useSetDialogHook from "./useSetDialogHook";

const useUpdateCreateGameDialog = () => {
  const navigateDialog = useNavigateDialog();
  const setDialogHook = useSetDialogHook();
  const updateCreateGameDialog = (
    name: string,
    currentStep: RenderableContent,
    currentStepNum: number,
    stepLength: number,
    setCurrentStepNum: SetState<number>,
    setDialogContent: SetState<RenderableContent>,
    data: Data,
    setData: SetState<Data>,
    onClickAddGameHandler: (
      game: Partial<
        Pick<CreatorGameboardConfig, "gameboardId" | "missionIds" | "name">
      >
    ) => Promise<RenderableContent>
  ) => {
    let continueBtn: "Next" | "Create" = "Next";
    let handleContinueBtnClick: () => void;
    if (stepLength - 1 === currentStepNum) {
      continueBtn = "Create";
      handleContinueBtnClick = async () => {
        const game = {
          name: data.gameName.inputField?.value,
          gameboardId: data.gameboard.value[0],
          missionIds: data.missions.value,
        };
        const content = await onClickAddGameHandler(game);
        setDialogContent(content);
      };
    } else {
      handleContinueBtnClick = () => {
        navigateDialog(
          name,
          data,
          setData,
          currentStepNum,
          setCurrentStepNum,
          1,
          stepLength
        );
      };
    }

    const setDialogHookProps = {
      setDialogContent,
      currentStep,
      handleContinueBtnClick,
      continueBtn,
      name,
      data,
      setData,
      currentStepNum,
      setCurrentStepNum,
      stepLength,
    };

    setDialogHook(setDialogHookProps);
  };
  return updateCreateGameDialog;
};

export default useUpdateCreateGameDialog;
