import React from "react";

import Steps from "./components/Steps/Steps";
import Missions from './components/Missions/Missions'

const Campaigns = ({ campaign, role }) => {
  const campaignTypes = {
    steps: <Steps role={role} currentCampaign={campaign} />,
    missions: <Missions role={role} currentCampaign={campaign} />,
  };

  return <>{campaignTypes[campaign.type]}</>;
};

export default Campaigns;
