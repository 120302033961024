import { useCallback } from "react";

import { SetState } from "../../../../types";
import { Data } from "../types";

import styles from "../creatorPage.module.scss";

const useNavigateDialog = () => {
  const navigateDialog = useCallback(
    (
      name: string,
      data: Data,
      setData: SetState<Data>,
      currentStep: number,
      setCurrentStep: SetState<number>,
      navigate: number,
      stepLength: number
    ) => {
      let error: boolean = false;
      const checkfieldSet = (data_field: {
        value: string[] | number[];
      }): boolean => {
        let value: string | number;

        if (typeof data_field.value === "string" || !data_field.value) {
          value = data_field?.value;
        } else {
          value = data_field.value[0];
        }
        if (!data_field?.value || value === "choose" || value === "") {
          return false;
        } else {
          return true;
        }
      };

      const nameKey = name as keyof Data;
      const nameData = data[nameKey] as {
        requiredData: string[];
        requiredSubData: string[];
      };

      const requiredData = nameData.requiredData;
      const requiredSubData = nameData.requiredSubData;
      const uploadReq = data[nameKey].img?.uploadReq;
      const imageValue = data[nameKey].img?.value?.filter(
        (value: { name: string; url: string }) => value.url !== ""
      );

      const imageName = data[nameKey].img.value?.filter(
        (value: { name: string; url: string }) => value.name !== ""
      );

      if (navigate > 0) {
        if (
          uploadReq.required &&
          (!imageValue.length ||
            imageValue.length < uploadReq.min ||
            (uploadReq.nameRequired &&
              (!imageName.length || imageName.length < uploadReq.min)))
        ) {
          setData((prevData) => ({
            ...prevData,
            [nameKey]: {
              ...prevData[nameKey],
              img: {
                ...prevData[nameKey].img,
                err: styles.err,
              },
            },
          }));
          error = true;
        }
        if (requiredData && requiredData.length > 0) {
          let requiredErrors: boolean = false;
          requiredData.forEach((field) => {
            const fieldKey = field as keyof Data;
            const regex =
              "pattern" in data[fieldKey]
                ? new RegExp(data[fieldKey].pattern as string)
                : null;
            if (
              !checkfieldSet(data[fieldKey]) ||
              (regex && !regex?.test(data[fieldKey].value[0]))
            ) {
              setData((prevData) => ({
                ...prevData,
                [field]: {
                  ...prevData[fieldKey],
                  err: styles.err,
                },
              }));
              requiredErrors = true;
              return;
            }
          });
          if (requiredErrors) error = true;
        }
        if (requiredSubData && requiredSubData.length > 0) {
          let requiredErrors: boolean = false;
          requiredSubData.forEach((subField) => {
            const dataAny = data[nameKey] as any;
            const regex =
              "pattern" in dataAny[subField]
                ? new RegExp(dataAny[subField].pattern)
                : null;
            if (
              !checkfieldSet(dataAny[subField]) ||
              (regex && !regex?.test(dataAny[subField].value))
            ) {
              setData((prevData) => ({
                ...prevData,
                [nameKey]: {
                  ...prevData[nameKey],
                  [subField]: {
                    ...(prevData[nameKey] as any)[subField],
                    err: styles.err,
                  },
                },
              }));
              requiredErrors = true;
              return;
            }
          });
          if (requiredErrors) error = true;
        }
        if (error) return;
      }

      const newStep = currentStep + navigate;
      if (newStep >= 0 && newStep < stepLength) {
        setCurrentStep(newStep);
      }
    },
    []
  );
  return navigateDialog;
};

export default useNavigateDialog;
