import React from 'react';
import styles from '../homePage.module.scss';
import coreValues1 from '../../../../assets/images/website/coreValues1.png';
import coreValues2 from '../../../../assets/images/website/coreValues2.png';
import coreValues3 from '../../../../assets/images/website/coreValues3.png';

export const CoreValuesSection = (props) => {
  return (
    <div className={styles.coreValuesSection}>
      <h1 className={styles.header}>Our core values</h1>
      <div className={styles.values}>
        <img src={coreValues1} alt="Feel great" />
        <img src={coreValues2} alt="Spread Kindness" />
        <img src={coreValues3} alt="Build Resilience" />
      </div>
    </div>
  );
};
