import ApiService from "../../../../Services/apiServiceT";
import { RenderableContent, SetState } from "../../../../types";
import ActionSuccessStatus from "../components/ActionSuccessStatus";
import { CreatorData, CreatorGameboardConfig, GameboardNames } from "../types";

const useAddGameHandler = () => {
  const apiService = new ApiService();
  let message: RenderableContent = <></>;

  const addGameHandler = async (
    game: Partial<
      Pick<CreatorGameboardConfig, "gameboardId" | "missionIds" | "name">
    >,
    setDialogContent: SetState<{ open: boolean; content: RenderableContent }>,
    setCreatorData: SetState<CreatorData | null>,
    gameboardNames: GameboardNames
  ) => {
    try {
      const [createGameResp, error] = await apiService.post<{ id: string }>({
        path: "/add_game",
        dataType: "admin",
        data: game,
      });
      if (!createGameResp) {
        message = (
          <>
            Something went wrong. <br></br> Please try again.
          </>
        );
      } else {
        message = (
          <>
            Completed <br></br> Mission Successfully <br></br> Added
          </>
        );
        setCreatorData((prev) => {
          console.log(createGameResp.data);
          const gameboard = gameboardNames.find(
            (gameboardName) => gameboardName.id === game.gameboardId
          );

          return {
            ...prev!,
            gameboardConfigs: [
              ...prev?.gameboardConfigs!,
              {
                name: game.name,
                _id: createGameResp.data.id,
                gameboardId: game.gameboardId!,
                missionIds: game.missionIds!,
                studentIds: [] as string[],
                teacherIds: [] as string[],
                campaignId: "",
                gameboardName: gameboard?.name!,
                totalMissions: game.missionIds?.length!,
              },
            ],
          };
        });
      }
    } catch (error) {
      message = (
        <>
          Something went wrong. <br></br> Please try again.
        </>
      );
    }
    return (
      <ActionSuccessStatus
        message={message}
        closeHandler={() => setDialogContent({ open: false, content: null })}
      />
    );
  };
  return addGameHandler;
};

export default useAddGameHandler;
