import { React } from 'react'
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

import AppRoutes from './AppRoutes'

// Create a client for cacheing
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 60 * 24, // Default stale time in milliseconds (e.g., 10 minutes)
            cacheTime: 1000 * 60 * 60 * 24, // Default cache time in milliseconds (e.g., 1 minute)
        },
    },
})

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AppRoutes />
        </QueryClientProvider>
    );
}

export default App;

