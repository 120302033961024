import React from "react";

const MainPageTemplate = ({ content, charSize }) => {
  const images = {
    boyRight_Standing: require("../../assets/images/website/boyStanding.png"),
    girlLeft_Standing: require("../../assets/images/userSite/config/girlLeftChabbad.png"),
  };

  const mainContent = (  
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
      id="section1"
    >
      <div 
      >
        <div style={{ height: "100%" }}>
          <div className="d-flex row align-items-end justify-content-center fp-tableCell">
            {/* Character 1 */}
            <div
              className="right_boy hey character1_play text-center col-2 "
              style={{ justifyContent: "end" }}
            >
              <img
                id="thegirl"
                src={images.boyRight_Standing}
                alt=""
                className="img-fluid"
                style={{
                  height: "auto",
                  width: [charSize],
                }}
              />
            </div>

            {/* Main Form */}
            {content}

            {/* Character 2 */}
            <div className="text-center mx-xxl-1 left_girl hey character2_play col-lg-2 col-xl-2">
              <img
                id="theImg"
                src={images.girlLeft_Standing}
                alt=""
                className="img-fluid"
                style={{
                  height: "auto",
                  width: [charSize],
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <>{mainContent}</>;
};

export default MainPageTemplate;
