import React, { useEffect } from "react";
import SelectInputOption from "./SelectInputOption";
import { CreatorInputField, CurrentCategories, Data } from "../types";
import CreatorDialogTemplate from "./CreatorDialogTemplate";
import useUpdateData from "../hooks/useUpdateData";

import styles from "../creatorPage.module.scss";
import { RenderableContent } from "../../../../types";

type Props = {
  data: Data;
  currentCategories: CurrentCategories;
  setData: React.Dispatch<React.SetStateAction<Data>>;
};

const CategoryStep = ({
  data,
  currentCategories,
  setData,
}: Props): RenderableContent => {
  const updateData = useUpdateData();
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateData(data, e.target.value, "category", "", setData, [
      { name: "", url: "" },
    ]);
  };

  useEffect(() => {
    if (data.category.value[0] === "new") {
      setData((prev) => {
        if (!("category" in prev)) return prev;
        return {
          ...prev,
          category: {
            ...prev.category,
            requiredSubData: ["inputField"],
            inputField: {
              ...prev.category.inputField,
              display: styles.display,
              value: prev.category.inputField?.value ?? "",
              err: prev.category.inputField?.err ?? "",
            },
          },
        };
      });
    } else {
      setData((prev) => {
        if (!("category" in prev)) return prev;
        return {
          ...prev,
          category: {
            ...prev.category,
            requiredSubData: [],
            inputField: {
              ...prev.category.inputField,
              value: prev.category.inputField?.value ?? "",
              err: prev.category.inputField?.err ?? "",
              display: styles.hidden,
            },
          },
        };
      });
    }
  }, [data.category.value]);

  const firstOption = (
    <SelectInputOption
      key={"Choose"}
      option={{ name: "Select A Category", value: "choose" }}
    />
  );
  const middleOptions = currentCategories
    .map((category) => (
      <SelectInputOption
        key={category.name}
        option={{
          name: category.name,
          value: category.name,
          customData: { "data-img": category.img },
        }}
      />
    ))
    .filter((option) => option.props.option.value);
  const lastOption = (
    <SelectInputOption
      key={"new"}
      option={{ name: "New category name", value: "new" }}
    />
  );
  const options = [firstOption, middleOptions, lastOption];
  const selectField = [
    {
      options: options,
      handleChange: handleChange,
    },
  ];

  const inputField: CreatorInputField[] = [
    {
      placeholder: "New Category Name",
      subField: "inputField",
    },
  ];

  return (
    <CreatorDialogTemplate
      name="category"
      data={data}
      setData={setData}
      mainText="Select A Category"
      selectField={selectField}
      inputField={inputField}
    />
  );
};

export default CategoryStep;
