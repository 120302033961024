import React from "react";

import styles from "../missionsTable.module.scss";

const CustomBtnComponent = ({ text, rowId, clickHandler }) => {
  return (
    <button className={styles.btn} onClick={() => clickHandler(rowId)}>
      {text}
    </button>
  );
};

export default CustomBtnComponent;
