import React from "react";

const SignupRadioButtons = ({ onChangeForm, active, formTypes }) => {

    const MainContent = () => {
        return (
            <>
                {formTypes.map(element => {
                    let isActive = false
                    if (active === element) {
                        isActive = true
                    }
                    return (
                        <div key={element} className="form-check form-check-inline pe-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id={element}
                                value={element}
                                onClick={onChangeForm}
                                defaultChecked={isActive}
                            />
                            <label htmlFor={element} className="form-check-label radio-button-lable h5" style={{ color: "white" }}>{element}</label>
                        </div>
                    )
                })}
            </>
        );
    };

    return (
        <>
            <MainContent />
        </>
    );
};

export default SignupRadioButtons;
