import useUpdateData from "../hooks/useUpdateData";

import { CreatorGameboardConfig, Data } from "../types";
import { SetState } from "../../../../types";

import SelectInputOption from "./SelectInputOption";
import CreatorDialogTemplate from "./CreatorDialogTemplate";

type Props = {
  data: Data;
  setData: SetState<Data>;
  gameboardConfigs: CreatorGameboardConfig[];
};

const AddToGame = ({ data, setData, gameboardConfigs }: Props) => {
  const updateData = useUpdateData();
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateData(data, e.target.value, "addToGame", "", setData, [
      { name: "", url: "" },
    ]);
  };

  const lastOptions = gameboardConfigs.map((game) => (
    <SelectInputOption
      key={game._id}
      option={{ name: game.name!, value: game._id }}
    />
  ));

  const options = [
    <SelectInputOption
      key={"choose"}
      option={{ name: "Select A Game", value: "choose" }}
    />,
    ...lastOptions,
  ];

  const subText = "Optional: You can add your new mission to an existing game!";

  return (
    <CreatorDialogTemplate
      name="addToGame"
      mainText="Add To Game"
      subText={subText}
      data={data}
      setData={setData}
      selectField={[{ options, handleChange }]}
    />
  );
};

export default AddToGame;
