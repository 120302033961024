import React from "react";

import MainNavBar from "../../Components/mainNavbar/MainNavbar";
import LoginForm from "./components/LoginForm";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import ContentBack from "../../Components/contentBack/v1/ContentBack";
import "../../Components/LandingPageTemplate";
import MainPageTemplate from "../../Components/LandingPageTemplate";

const LoginFormPages = ({ formType }) => {
  const formsList = {
    login: <LoginForm />,
    forgotPassword: <ForgotPasswordForm />,
    resetPassword: <ResetPasswordForm />,
  };

  // const mainContent = (
  //   <div id="section1">
  //     <div
  //       className=" row align-items-end justify-content-center fp-tableCell"
  //       style={{ height: "75%", }}
  //     >
  //       {/* Character 1 */}
  //       <div
  //         className="right_boy hey character1_play text-center col-2"
  //         style={{ justifyContent: "end" }}
  //       >
  //         <img
  //           id="thegirl"
  //           src={images.boyRight_Standing}
  //           alt=""
  //           className="img-fluid"
  //           style={{
  //             height: "auto",
  //             width: '70%',
  //           }}
  //         />
  //       </div>

  //       {/* Main Form */}
  //       <div className="col-lg-4" style={{marginTop: "5%" }}>
  //         <ContentBack content={formsList[formType]} />
  //       </div>

  //       {/* Character 2 */}
  //       <div
  //         className="text-center mx-xxl-1 left_girl hey character2_play col-lg-2 col-xl-2"
  //       >
  //         <img
  //           id="theImg"
  //           src={images.girlLeft_Standing}
  //           alt=""
  //           className="img-fluid"
  //           style={{
  //             height: "auto",
  //             width: '70%',
  //           }}
  //         />
  //       </div>

  //     </div>
  //   </div>
  // );

  const content = (
    //       {/* Main Form */}
    <div className="col-lg-4" 
    // style={{ marginTop: "5%" }}
    >
      <ContentBack content={formsList[formType]} />
    </div>
  );

  const mainContent = <MainPageTemplate content={content} charSize={"70%"} divSize={"73%"} />;

  return (
    <>
      <MainNavBar />
      {mainContent}
    </>
  );
};

export default LoginFormPages;
