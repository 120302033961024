import React from "react";
import IconButton from "@mui/material/IconButton";
import { ArrowDownward } from "@mui/icons-material";

import styles from "./FullPageScroll.module.css";

const Arrow = ({ index }) => {
  const scrollToTables = (index) => {
    const id = index + 1;

    const tables = document.querySelector(`#fullPageScrollId_${id}`);
    if (tables) {
      tables.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={styles.arrowBox}>
      <div className={styles.arrowContainer}>
        <IconButton onClick={() => scrollToTables(index)}>
          <ArrowDownward className={styles.arrow} />
        </IconButton>
      </div>
    </div>
  );
};

const FullPageScroll = ({ sections }) => {
  const sectionCreator = (section, index) => {
    let mainDivClass;
    let contentContainerClass;
    if (!section.scrollable && section.dynamic) {
      mainDivClass = `${styles.pageSection} ${styles.dynamicContainer}`;
      contentContainerClass = styles.dynamicContainerContent;
    } else if (!section.scrollable) {
      mainDivClass = styles.pageSection;
    } else if (section.scrollable) {
      mainDivClass = styles.scrollablePageSection;
    }

    return (
      <div key={`fullPageScrollId_${index}`} id={`fullPageScrollId_${index}`} className={mainDivClass}>
        {section.header && <div className={styles.headerContainer} >{section.header}</div>}
        <div className={contentContainerClass}>{section.value}</div>
        {sections.length > index+1 && <Arrow index={index} />}
        
      </div>
    );
  };

  return (
    <div className={styles.fullPageScroll}>
      {sections.map((section, index) => {
        return sectionCreator(section, index);
      })}
    </div>
  );
};

export default FullPageScroll;
