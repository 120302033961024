import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useAnimation } from 'framer-motion';

import { CollectTile } from './collect-tile';
import { QuestTileStatuses } from '../../../../Models/questModel';

import styles from '../questPage.module.scss';

import modalBg from '../../../../assets/images/userSite/quests/tile-modal-background.png';
import popup from '../../../../assets/images/userSite/quests/mission-popup.png';
import questLocked from '../../../../assets/images/userSite/quests/quest_locked.png';
import starImage from '../../../../assets/images/userSite/quests/star.png';

const images = require.context("../../../../assets/images/userSite", true);

export const TileModal = (props) => {
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    // ensure the modal is within bounds

    function updateOnSize() {
      const rect = ref.current.getBoundingClientRect();

      if (window.innerWidth > 767) {
        if (rect.right > window.innerWidth) {
          ref.current.style.right = `50%`;
          ref.current.style.left = 'unset';
          ref.current.querySelector('img').style.transform = 'scaleX(-1)';
        }

        if (rect.bottom > window.innerHeight) {
          ref.current.style.bottom = `50%`;
          ref.current.style.top = 'unset';
          ref.current.querySelector('img').style.transform = 'scaleY(-1)';
        }
      }
    }

    window.addEventListener('resize', updateOnSize);

    updateOnSize();

    return () => {
      window.removeEventListener('resize', updateOnSize);
    };
  }, []);

  return (
    <div className={clsx(styles.tileModal)} ref={ref}>
      {/* <div className={clsx(styles.tileModalBackdrop)}></div> */}

      {/* <IconX className={clsx(styles.tileModalClose)} onClick={props.onClose}></IconX> */}

      <div className={clsx(styles.tileModalContent)}>
        <img src={modalBg} alt="" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1em'
          }}
        >
          <img src={popup} alt="" style={{ width: '100px', height: '100px', marginTop: '-50px' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '85%',
            marginLeft: '11%',
            marginTop: '0',
            fontSize: '.90em',
            textAlign: 'center'
          }}>
            {props.status === QuestTileStatuses.Locked && <span>To unlock this quest complete other quest first</span>}
            {props.status === QuestTileStatuses.Inprogress &&
              <>
                <span>To complete this quest:</span>
                <ul>
                  {Object.keys(props.questDesc).map((key) => (
                    <li key={key}>{props.questDesc[key]}</li>
                  ))}
                </ul>
              </>}
          </div>
          {/* <button style={{ background: '#f6db0f', color: 'hsl(var(--brand))' }}>Practice More</button> */}
        </div>
      </div>
    </div>
  );
};


const Tile = (props) => {
  const ref = React.useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    if (props.isSelected && props.status == QuestTileStatuses.Completed) {
      controls
        .start({
          rotate: [0, 360],
        })
        .then();
    } else {
      controls.set({ rotate: 0 });
      controls.stop();
    }

    // If completed make call to add to Rewards

  }, [props.isSelected, props.status]);

  return (
    <div
      className={clsx(styles.tile, {
        [styles.selectedTile]: props.isSelected,
        [styles.completedTile]: props.status == QuestTileStatuses.Completed,
        [styles.collectedTile]: props.status == QuestTileStatuses.Collected,
      })}
      ref={ref}
    >
      <div
        className={styles.tileInner}
        data-role="tile"
        onClick={(e) => {
          e.stopPropagation();
          props.onClick(e);
        }}
      >
        {props.status == QuestTileStatuses.Completed && <img src={starImage} className={styles.lockedImage}></img>}
        {(props.status == QuestTileStatuses.Inprogress || props.status == QuestTileStatuses.Locked) && <img src={questLocked} className={styles.lockedImage}></img>}
        <img src={images(props.image)} className={
          clsx({
            [styles.unlockedImage]: props.status != QuestTileStatuses.Collected,
            [styles.collectedImage]: props.status == QuestTileStatuses.Collected
          })}></img>
      </div>


      {/* Tile modal for tiles 'inprogress' and 'locked' */}
      {(props.status == QuestTileStatuses.Inprogress || props.status == QuestTileStatuses.Locked) && <TileModal {...props} />}

      {/* Completed Tiles clicked animation */}
      {props.status == QuestTileStatuses.Completed && <CollectTile {...props} imageSrc={images(props.image)} onTileCollected={props.onTileCollected} />}

    </div>
  );
};

export const QuestBoard = (props) => {
  return (
    <>
      <div className={clsx(styles.questBoard, styles.glassBg)}>
        <div className={styles.tilesWrapper}>
          {props.tiles.map((tile) => (
            <Tile
              {...tile}
              key={tile.id}
              isSelected={tile.id === props.activeTile?.id && tile.status != QuestTileStatuses.Locked && tile.status != QuestTileStatuses.Collected}
              onClick={(e) => {
                props.onTileClicked(tile);
              }}
              onTileCollected={props.onTileCollected}
            />
          ))}
        </div>
        <div className={styles.mobileTileModal}>
          {props.activeTile && (props.activeTile.status == QuestTileStatuses.Inprogress || props.activeTile.status == QuestTileStatuses.Locked) && <TileModal {...props.activeTile} />}
        </div>
      </div>
    </>
  );
};
