import { React } from "react";

import MainNavBar from "../Components/mainNavbar/MainNavbar";
import MainFooter from "../Components/mainFooter/MainFooter";


import main_css from "../../assets/main_css/main.module.css"

const NotFoundPage = () => {

  const centerContent = (
    <>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <div style={{ padding: '5px' }} />
      <p>We're sorry, but the page you were looking for doesn't exist.</p>
      <div style={{ padding: '20px', display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
        <a className={main_css.basic_btn} href="/">
          Back to Home
        </a>
        <a className={main_css.basic_btn} href="/contactUs">
          Contact Support
        </a>
      </div>
    </>
  );

  const mainContent = (
    <div
      className={`${main_css.verifyPage}`}>
      <div className={`${main_css.verifyPageMessage}`}>
        {centerContent}
      </div>
    </div>
  );

  return (
    <>
      <MainNavBar />
      {mainContent}
      <MainFooter />
    </>
  );
};

export default NotFoundPage;
