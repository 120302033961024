import React from "react";

import styles from "../missionsTable.module.scss";

const EditBtn = ({ editBtnClickHandler, rowId, editingBtnValue }) => {
  return (
    <button
      className={styles.btn}
      onClick={() => editBtnClickHandler(rowId.original.id)}
    >
      {editingBtnValue[rowId.original.id] &&
      editingBtnValue[rowId.original.id].btnValue
        ? "Save"
        : "Edit"}
    </button>
  );
};

export default EditBtn;
