import { SetState } from "../../../../types";
import { Data } from "../types";

import styles from "../creatorPage.module.scss";

const useUpdateData = () => {
  const updateData = (
    data: Data,
    selectedValue: string | number,
    fieldName: string,
    subField: string,
    setData: SetState<Data>,
    images: Array<{
      name: string;
      url: string;
    }>
  ) => {
    if (
      typeof selectedValue !== "number" &&
      !isNaN(parseInt(selectedValue)) &&
      parseInt(selectedValue).toString() === selectedValue
    ) {
      selectedValue = parseInt(selectedValue);
    }

    const fieldNameKey = fieldName as keyof Data;
    let imageUploadDisplay: string;
    if (selectedValue !== "" && data[fieldNameKey].value[0] === selectedValue) {
      return;
    }

    imageUploadDisplay = styles.display;

    if (selectedValue === "choose") {
      imageUploadDisplay = styles.hidden;
    }

    if (subField !== "") {
      setData((prev) => {
        return {
          ...prev,
          [fieldName]: {
            ...prev[fieldNameKey],
            err: "",
            img: {
              ...prev[fieldNameKey].img,
              err: "",
              display: imageUploadDisplay,
            },
            [subField]: {
              ...(prev[fieldNameKey] as any)[subField],
              value: selectedValue,
              err: "",
            },
          },
        };
      });
    } else {
      if (
        selectedValue === `choose` ||
        (fieldName === "taskOption" && selectedValue === "message")
      ) {
        imageUploadDisplay = styles.hidden;
      } else {
        imageUploadDisplay = styles.display;
      }
      setData((prevData) => {
        return {
          ...prevData,
          [fieldName]: {
            ...prevData[fieldNameKey],
            value: [String(selectedValue)],
            err: "",
            img: {
              ...prevData[fieldNameKey].img,
              err: "",
              display: imageUploadDisplay,
              value: images,
            },
          },
        };
      });
    }
  };
  return updateData;
};

export default useUpdateData;
