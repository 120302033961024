import axios, { AxiosInstance, AxiosResponse } from "axios";
import { backend_url, basePaths } from "../config";
import { BasePathKeys } from "../types";

type ApiResponse<T> = [AxiosResponse<T> | null, any | null];

export default class ApiService {
  private baseUrl: string;
  private instance: AxiosInstance;

  constructor(auth: boolean = false, baseUrl: string = backend_url ?? "") {
    this.baseUrl = baseUrl;
    this.instance = axios.create({ withCredentials: true });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          window.location.pathname !== "/login" &&
          !window.location.pathname.startsWith("/sign-up")
        ) {
          window.location.href = "/login"; // Redirect to login page
        }
        return Promise.reject(error);
      }
    );

    // if (auth){
    //   axios.defaults.headers.common["session_token"] = `${Cookies.get("session_token")}`;
    // }
  }

  /*
   * For the following functions, the inputs and outputs are defined as follows:
   *
   * @param dataType:  The backend table we are sending the data to. The options are defined in config.js
   * @param path:      The path for getting, updating, creating or deleting
   * @param data:      The data to send to the backend
   *
   * @return result:   The data retrieved from backend or the error
   */

  async post<T>({
    path,
    data = {},
    dataType,
  }: {
    path: string;
    data?: any;
    dataType?: BasePathKeys;
  }): Promise<ApiResponse<T>> {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.post<T>(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async patch<T>({
    path,
    data = {},
    dataType,
  }: {
    path: string;
    data?: any;
    dataType?: BasePathKeys;
  }): Promise<ApiResponse<T>> {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.patch<T>(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async put<T>({
    path,
    data = {},
    dataType,
  }: {
    path: string;
    data?: any;
    dataType?: BasePathKeys;
  }): Promise<ApiResponse<T>> {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.put<T>(
        `${this.baseUrl}/${rootPath}${path}`,
        data
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async get<T>({
    path,
    data = {},
    dataType,
  }: {
    path: string;
    data?: any;
    dataType?: BasePathKeys;
  }): Promise<ApiResponse<T>> {
    try {
      const rootPath = dataType ? basePaths[dataType] : "";
      const results = await this.instance.get<T>(
        `${this.baseUrl}/${rootPath}${path}`,
        { params: data }
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }

  async delete<T>({
    path,
    data = {},
    dataType,
  }: {
    path: string;
    data?: any;
    dataType?: BasePathKeys;
  }): Promise<ApiResponse<T>> {
    try {
      const rootPath = dataType ? basePaths[dataType] : dataType;
      const results = await this.instance.delete<T>(
        `${this.baseUrl}/${rootPath}${path}`,
        { data }
      );
      return [results, null];
    } catch (error) {
      return [null, error];
    }
  }
}
