import React from "react";

import DropdownBtn from "../../../../Components/dropdownBtn/DropdownBtn"

const TableDropdownBtn = ({ ids, buttonHandler, selectSingle }) => {
  const handleClick = (action) => {
    buttonHandler(action, ids);
    ids.forEach((id) => {
      selectSingle(id);
    });
  };
  return (
    <DropdownBtn
      dropdownButton={{
        text: "Verify",
        classes: "text-light",
        btns: [
          {
            text: "Verify",
            handleClick: () => handleClick("Verify", ids),
          },
          {
            text: "Delete",
            handleClick: () => handleClick("Delete", ids),
          },
        ],
      }}
    />
  );
};

export default TableDropdownBtn;
