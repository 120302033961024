import { React } from 'react'
import { Dialog } from '@mui/material'

import useUserConfig from '../../../../UserPages/Hooks/useUserConfig';
import FormInput from '../../../../Components/FormInput';
import ParentSignUpForm from '../../SignUpPage/components/ParentSignUpForm';

const assets = require.context("../../../../assets/images", true);

const images = {
  close: require("../../../../assets/images/close.png"),
  dialogBkg: './CenterBox_lg.png'
}

const styles = {
  closeIcon: {
    width: 'clamp(25px, 7vw, 40px)',
    height: 'clamp(25px, 7vw, 40px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#445eaa',
    color: 'white',
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
  },
  text: {
    color: '#fdeaff',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Montserrat',
  },
  mainText: {
    fontSize: 'clamp(24px, 3vw, 40px)',
  },
  subText: {
    fontSize: 'clamp(16px, 2.5vw, 20px)',
  },
};

const SignUpOnVerify = ({ classes = "", mainText, mainTextClass, subText, subTextClass, closeHandler, duration = '1000ms', verifyToken,def_email }) => {

  const { data: userConfig, isLoading } = useUserConfig();

  const dialogBkg = isLoading ? images.dialogBkg : userConfig.graphicConfig.dialogBkg;

  return (
    <>
      <Dialog
        className={`dialogMui ${classes}`}
        open={true}
        PaperProps={{
          style: {
            animationDuration: duration,
            backgroundImage: isLoading ? 'none' : `url(${assets(`${dialogBkg}`)})`
          },
        }}>
        {
          closeHandler &&
          <div className="ms-5 ps-5 text-center" style={{ cursor: "pointer", position: "absolute", top: "-2%", right: '7%', }}>
            <div id="closeModal" style={styles.closeIcon} onClick={closeHandler} src={images["close"]} alt="Close">&#10005;</div>
          </div>
        }
        <div
          style={{ height: "100%" }} className="d-flex flex-column align-items-center justify-content-center ">
          <div className="mx-1 px-1">
            <p className={`text-center ${mainTextClass}`} style={{ ...styles.mainText, ...styles.text }}>{mainText}</p>
          </div>
          <div className="pt-2 mx-1 px-2">
            <p className={`text-center ${subTextClass}`} style={{ ...styles.subText, ...styles.text }}>{subText}</p>
          </div>
          <div style={{
            marginTop: '2%',
            overflowY: 'scroll'
          }}>
            <ParentSignUpForm verifyToken={verifyToken} def_email={def_email}/>
          </div>

        </div>

      </Dialog>
    </>
  )
}

export default SignUpOnVerify;