import { ReactNode, useEffect, useState } from "react";
import {
  Data,
  GameboardNames,
  CurrentCategories,
  CreatorGameboardConfig,
} from "../types";

import styles from "../creatorPage.module.scss";

import useUpdateCreatorDialog from "../hooks/useUpdateCreatorDialog";
import { Mission, RenderableContent } from "../../../../types";
import TaskStep from "./TaskStep";
import CategoryStep from "./CategoryStep";
import TaskOptionStep from "./TaskOptionStep";
import TaskCompletedStep from "./TaskCompletedStep";
import AddToGame from "./AddToGame";
import CreateMissionSummary from "./CreateMissionSummary";
import CategoryIconStep from "./CategoryIconStep";
import OptionsIconsStep from "./OptionsIconsStep";

type Props = {
  missions: Mission[];
  gameboardConfigs: CreatorGameboardConfig[];
  addMissionHandler: (
    mission: Omit<Mission, "_id">,
    addToGame: string
  ) => Promise<RenderableContent>;
  currentCategories: CurrentCategories;
  currentRewardTypes: string[];
};

const imgObj = {
  value: [],
  err: "",
  new: false,
  display: styles.hidden,
  uploadReq: {
    required: false,
    nameRequired: false,
    min: 0,
    max: 0,
  },
};

const inputFieldObj = {
  value: "",
  err: "",
  display: styles.display,
};

const taskCompletedRewardType = {
  value: "",
  err: "",
  display: styles.hidden,
};

const taskCompletedSubObj = {
  value: null,
  err: "",
  display: styles.hidden,
  pattern: "^[0-9]+$",
};

const CreateMission = ({
  gameboardConfigs,
  addMissionHandler,
  currentCategories,
  currentRewardTypes,
}: Props) => {
  const updateDialogContent = useUpdateCreatorDialog();
  const [currentStepNum, setCurrentStepNum] = useState(0);
  const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
  const [data, setData] = useState<Data>({
    category: {
      value: ["choose"],
      err: "",
      img: { ...imgObj },
      inputField: { ...inputFieldObj },
      requiredData: ["category"],
      requiredSubData: [],
    },
    categoryIcon: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        uploadReq: {
          required: true,
          nameRequired: false,
          min: 1,
          max: 1,
        },
      },
      inputField: { ...inputFieldObj },
      requiredData: [],
      requiredSubData: [],
    },
    task: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["task"],
      requiredSubData: [],
    },
    taskOption: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["taskOption"],
      requiredSubData: [],
    },
    optionsIcons: {
      value: [""],
      err: "",
      img: {
        ...imgObj,
        uploadReq: {
          required: true,
          nameRequired: true,
          min: 1,
          max: 10,
        },
      },
      requiredData: [],
      requiredSubData: [],
    },
    taskCompleted: {
      value: [""],
      err: "",
      img: { ...imgObj },
      requiredData: ["taskCompleted"],
      requiredSubData: [
        "rewardType",
        "rewardAmount",
        "maxAllowed",
        "resetPeriod",
      ],
      rewardType: { ...taskCompletedRewardType },
      rewardAmount: { ...taskCompletedSubObj },
      maxAllowed: { ...taskCompletedSubObj },
      resetPeriod: { ...taskCompletedSubObj },
    },
    addToGame: {
      value: ["None"],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
    createMissionSummary: {
      value: ["confirm"],
      err: "",
      img: { ...imgObj },
      requiredData: [],
      requiredSubData: [],
    },
  });

  const stepsDataKeys = [
    "category",
    "categoryIcon",
    "task",
    "taskOption",
    "optionsIcons",
    "taskCompleted",
    "addToGame",
    "createMissionSummary",
  ];

  const step = [
    <CategoryStep
      data={data}
      currentCategories={currentCategories}
      setData={setData}
    />,
    <CategoryIconStep data={data} setData={setData} />,
    <TaskStep data={data} setData={setData} />,
    <TaskOptionStep data={data} setData={setData} />,
    <OptionsIconsStep
      data={data}
      setData={setData}
      setCurrentStepNum={setCurrentStepNum}
    />,
    <TaskCompletedStep
      data={data}
      setData={setData}
      currentRewardTypes={currentRewardTypes}
    />,
    <AddToGame
      data={data}
      setData={setData}
      gameboardConfigs={gameboardConfigs}
    />,
    <CreateMissionSummary
      data={data}
      setData={setData}
      gameboardConfigs={gameboardConfigs}
    />,
  ];

  useEffect(() => {
    updateDialogContent(
      stepsDataKeys[currentStepNum],
      step[currentStepNum],
      currentStepNum,
      stepsDataKeys.length,
      setCurrentStepNum,
      setDialogContent,
      data,
      setData,
      addMissionHandler
    );
  }, [data, currentStepNum]);

  return dialogContent || null;
};

export default CreateMission;
