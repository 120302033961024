import { React, useState, useEffect } from "react";
import MissionVerification from "../../Components/missionVerification/MissionVerification";
import UserVerification from "./components/UserVerification";
import { MissionsTable } from "../../Components/missionTable/missionsTable";
import AdminTemplate from "../../Components/adminTemplate/AdminTemplate";

import ApiService from "../../../Services/apiService";

const recentMissionsTableSetup = [
  { accessorKey: "fname", header: "First Name" },
  { accessorKey: "lname", header: "Last Name" },
  { accessorKey: "mission", header: "Mission" },
];

const AdminPanelPage = () => {
  const apiService = new ApiService(true);

  const [missionsTotal, SetMissionsTotal] = useState([]);
  const [unverifiedMissions, SetUnverifiedMissions] = useState([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState([]);
  const [recentMissions, SetRecentMissions] = useState([]);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let timeframe = Date.now();
      timeframe = new Date(timeframe);
      timeframe = timeframe.toLocaleDateString();
      timeframe = `${timeframe}, 12:00:00 AM`;
      timeframe = new Date(timeframe).getTime();

      try {
        const [dashboardDataResp, err] = await apiService.get({
          path: "/dashboard_data",
          data: {
            campaigns: ["steps"],
            timeframe: timeframe,
          },
          dataType: "admin",
        });
        if (dashboardDataResp) {
          SetMissionsTotal(dashboardDataResp.data.carousel);
          SetUnverifiedMissions([
            ...dashboardDataResp.data.unverified_missions,
          ]);
          setUnverifiedUsers(dashboardDataResp.data.unverified_users);
          SetRecentMissions(dashboardDataResp.data.recent_missions);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [reload]);

  const mainContent = (
    <>
      {unverifiedUsers.length > 0 && (
        <UserVerification
          unverifiedUsers={unverifiedUsers}
          reloadHandler={setReload}
        />
      )}
      {unverifiedMissions.length > 0 && (
        <MissionVerification
          unverifiedMissions={unverifiedMissions}
          reloadHandler={setReload}
        />
      )}

      <MissionsTable
        tableSetup={recentMissionsTableSetup}
        data={recentMissions}
        title="Today's Completed Missions"
      />
    </>
  );

  return <AdminTemplate carousel={missionsTotal} pageContent={mainContent} />;
};

export default AdminPanelPage;
