import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router";

import DialogTemplate from "../../../../Components/DialogTemplate";
import FormInput from "../../../../Components/FormInput";

import ApiService from "../../../../Services/apiService";
import { validation } from "../../../../Util/Validation";

import main_css from "../../../../assets/main_css/main.module.css";

const Profile = () => {
  const apiService = new ApiService();
  const navigate = useNavigate();

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [parentValidation, setParentValidation] = useState({
    pattern: validation.email.pattern,
    message: validation.email.message,
  });
  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    // setLoadingDialogOpen(true);
    const fetchData = async () => {
      const data = {};

      const [up_to_date, cached_data] = [false, null]; //await getCached(cacheName);
      if (!up_to_date) {
        const [userResp, userError] = await apiService.get({
          path: "/get_one_by_id",
          dataType: "user",
        });

        if (!userError) {
          data.fname = userResp.data.name.split(" ")[0];
          data.lname = userResp.data.name.split(" ")[1];
          data.email = userResp.data.username;
        } else {
          console.log(
            `Error Retriving Mission Data:\n ${userError.toString()}`
          );
        }

        if (userResp !== null && userResp.data.parentIds !== undefined) {
          const [parentResp, parentError] = await apiService.get({
            path: "/get_one_by_id",
            dataType: "user",
            data: {
              id: userResp.data.parentIds,
              role: "parent",
            },
          });

          if (!parentError) {
            data.parentEmail = parentResp.data.username;
            data.parentPhone = parentResp.data.phone;
          } else {
            console.log(
              `Error Retriving Mission Data:\n ${userError.toString()}`
            );
          }
        }

        // putCache(cacheName, {
        //   stats: stats,
        //   missions : userResp.missions
        // });

        setFname(data.fname);
        setLname(data.lname);
        setEmail(data.email);
        setParentEmail(data.parentEmail);
        setParentPhone(data.parentPhone);
      } else {
        if (cached_data) {
          setFname(cached_data.profile.fname);
          setLname(cached_data.profile.lname);
          setEmail(cached_data.profile.email);
          setParentEmail(cached_data.profile.parentEmail);
          setParentPhone(cached_data.profile.parentPhone);
        }
      }

      // setLoadingDialogOpen(false);
    };

    fetchData();

    return () => {
      //handleLoadingDialogClose();
      // this now gets called when the component unmounts
    };
  }, []);

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setDialog({
      mainText: "Updating Profile...",
      subText: "Please wait while we update your account",
    });
    try {
      const [response, error] = await apiService.post({
        path: "/update_user",
        dataType: "user",
        data: {
          name: fname + " " + lname,
          username: email,
          parentEmail: parentEmail,
          phone: parentPhone,
        },
      });

      if (response.data.message === "updated") {
        setDialog({
          mainText: "Profile Updated",
          subText: "Your profile has been updated successfully",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });
        setTimeout(() => {
          handleCloseDialog();
        }, 1500);
      } else if (response.data.message === "same") {
        console.log("Profile update failed!");
        setDialog({
          mainText: "Profile is the Same!",
          subText: "Your profile has not been updated!\nPlease try again!",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });
      } else {
        throw new Error("Profile update failed!");
      }
    } catch (error) {
      console.log(error);
      setDialog({
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: handleCloseDialog,
      });

      setTimeout(() => {
        setDialog(null);
      }, 5000);
    }
  };

  const handleCloseDialog = () => {
    setDialog(null);
  };

  const mainContent = (
    <div
      className="tab-pane fade show active"
      aria-labelledby="home-tab"
    >
      <form onSubmit={handleUpdateUser}>
        <div className="px-2 px-sm-5">
          <div className="">
            <h4 className={`text-center ${main_css.themeFontFamily} ${main_css.themeColor_Bold}`} style={{ fontWeight: 'bold' }}>Student Profile</h4>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group ajax-field">
                  <FormInput
                    type={"text"}
                    value={fname}
                    placeholder={"First Name"}
                    classes={"changePassInput"}
                    name={"first_name"}
                    onChangeHandler={setFname}
                  // disabled
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group ajax-field">
                  <FormInput
                    type={"text"}
                    value={lname}
                    classes={"changePassInput"}
                    placeholder={"Last Name"}
                    name={"last_name"}
                    onChangeHandler={setLname}
                  // disabled
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="form-group ajax-field">
                <FormInput
                  type={"text"}
                  value={email}
                  placeholder={"Student Email"}
                  classes={"changePassInput"}
                  name={"email"}
                  onChangeHandler={setEmail}
                // disabled
                />
              </div>
            </div>
            <div
              className="foam_inner_div border1"
              style={{ marginTop: "17px", paddingTop: "17px" }}
            >
              <h4 className={`text-center ${main_css.themeFontFamily} ${main_css.themeColor_Bold}`} style={{ fontWeight: 'bold' }}>Parent Information</h4>
              <div className="row pt-3">
                <div className="col-12 col-lg-6">
                  <div className="form-group ajax-field">
                    <FormInput
                      type={"email"}
                      value={parentEmail}
                      placeholder={"Parent Email"}
                      classes={"changePassInput"}
                      name={"parent_email_id"}
                      onChangeHandler={setParentEmail}
                      message={parentValidation.message}
                      pattern={parentValidation.pattern}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6 mb-0">
                  <div className="form-group ajax-field">
                    <FormInput
                      type={"tel"}
                      placeholder={"Phone Number"}
                      classes={"changePassInput"}
                      value={parentPhone}
                      name={"phone"}
                      required={false}
                      onChangeHandler={setParentPhone}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className={main_css.basic_btnInv}
              style={{ padding: '5px 10px', marginTop: '5%' }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {dialog && <DialogTemplate {...dialog} />}
      {mainContent}
    </>
  );
};

export default Profile;
