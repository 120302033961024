import React from "react";

import MainNavBar from "../Components/mainNavbar/MainNavbar";
import MainFooter from "../Components/mainFooter/MainFooter";

import home from "../../assets/main_css/home.module.css";
import main_css from "../../assets/main_css/main.module.css";

function PrivacyPage() {
  return (
    <div>
      <MainNavBar />
      <section className={home.inner_pages}>
        <div className={home.container}>
          <h2 className={`${main_css.title} text-center`}>Privacy Policy</h2>
          <div className={home.policy}>
            <span class="fw-bold mb-2">Introduction</span>
            <p>
              Thank you for visiting our website{" "}
              <a href="www.thesupermeclub.com" target="_blank">
                www.thesupermeclub.com
              </a>{" "}
              (our "Website"). The SuperME Club Ltd, and its affiliates
              (collectively, "Company" or “us” or "we") respect your child’s
              privacy and are committed to protecting it through our compliance
              with this policy.
              <br />
              <br />
              This Website is built for the use and enjoyment of children under
              the age of thirteen. The US federal Children's Online Privacy
              Protection Act of 1998 and its rules (collectively, "COPPA")
              require us to inform parents/legal guardians (as used in this
              policy, "parents") about our practices for collecting, using, and
              disclosing personal information from children under the age of 13
              ("children"). It also requires us to obtain verifiable consent
              from a child's parent for certain collection, use, and disclosure
              of the child's personal information. We also adhere to additional
              international and US state data protection laws, as outlined
              below.
              <br />
              <br />
              This policy describes (a) the types of information we may collect
              from your child or that your child may provide when they visit our
              Website; and (b) our practices for collecting, using, maintaining,
              protecting, and disclosing that information. This policy applies
              to information we collect on this Website and in email, text, and
              other electronic messages between you and/or your child and this
              Website. It is our practice to also follow these same policies and
              practices for non-digital information supplied by your child
              and/or you to our Company.
              <br />
              <br />
              <span class="fw-bold">
                More specifically, this policy notifies parents of:{" "}
              </span>
            </p>

            <p>
              <ul>
                <li>The types of information we may collect from children.</li>
                <li>How we use the information we collect.</li>
                <li>Our practices for disclosing that information.</li>
                <li>
                  Our practices for notifying and obtaining parents' consent
                  when we collect personal information from children, including
                  how a parent may
                </li>{" "}
                <li>revoke consent.</li>
                <li>
                  All operators that collect or maintain information from
                  children through this Website.
                </li>
              </ul>
            </p>
            <p>
              This Website and our related services may only be accessed and use
              by your child after we receive “verifiable parental consent” from
              you as your child’s parent or legal guardian. As part of the
              process of your granting verifiable parental consent, you have
              agreed to the terms and parameters of this privacy policy. <br />
              <br />
              Please read this policy carefully to understand our policies and
              practices regarding your child’s information and how we will treat
              it. If you do not agree with our policies and practices, your
              choice is not to grant consent and not to let your child use our
              Website.
              <br />
              <br />
              To the extent that teens (i.e., 14 years or older) or adults are
              granted access to our Website, such teens and adults shall be
              required to adhere to this privacy policy, excluding the need for
              obtaining prior verifiable parental consent. No teen or adult
              shall do anything to undermine the terms of this privacy policy
              while accessing this Website or any other matters related to the
              services of this Website.
              <br />
              <br />
              <span class="fw-bold">Description of Parental Rights.</span>
              <br />
              This policy outlines how to exercise your parental rights. Please
              know that:
              <ul>
                <li>
                  We will not require a child to disclose more information than
                  is reasonably necessary to participate in an activity;
                </li>
                <li>
                  As a parent you can review your child’s personal information,
                  direct us to delete it, and refuse to allow any further
                  collection or use of your child’s information; and
                </li>
                <li>
                  As a parent, through our verifiable parental consent process,
                  you will be given the opportunity to agree to the collection
                  and use of your child’s information.
                </li>
                <li>
                  If you have any questions, instructions, or other concerns,
                  please contact us at{" "}
                  <a href="mailto:privacy@thesupermeclub.com">
                    privacy@thesupermeclub.com
                  </a>
                  .
                </li>
              </ul>
            </p>
            <p>
              <span class="fw-bold">
                Accessing and Correcting Your Child's Personal Information
              </span>
              <br />
              At any time, you may review the child's personal information
              maintained by us, require us to correct or delete the personal
              information, and/or refuse to permit us from further collecting or
              using the child's information. <br />
              You can review, change, or delete your child's personal
              information by:
              <br />
              <ul>
                <li>
                  Logging into your child's account and visiting his or her
                  account profile page.
                </li>
                <li>
                  {" "}
                  Sending us an email at{" "}
                  <a href="mailto:privacy@thesupermeclub.com">
                    privacy@thesupermeclub.com
                  </a>
                  . If you chose to email us, in order to protect your privacy
                  and security, we shall require you to take certain steps or
                  provide additional information to verify your identity before
                  we provide any information or make corrections.
                </li>
              </ul>
            </p>

            <p>
              <span class="fw-bold">
                Information We Collect About Your Child and How We Collect It
              </span>
              <br />
              Children can access many parts of the Website and its content and
              use many of its features without providing us with personal
              information. However, some content and features require us to
              collect certain information, including personal information, from
              them. In addition, we use certain technologies, such as cookies,
              to automatically collect information from our users (including
              children) when they visit or use the Website.
              <br />
              <br />
              We only collect as much information about a child as is reasonably
              necessary for the child to participate in an activity, and we do
              not condition his or her participation on the disclosure of more
              personal information than is reasonably necessary.
              <br />
              <br />
              We may collect several types of information from and about users
              of our Website, including information:
              <br />
              <ul>
                <li>
                  By which your child may be personally identified, such as
                  name, postal address, email address, telephone number, or any
                  other identifier by which your child may be contacted online
                  or offline] ("<b>personal information</b>");
                </li>
                <li>
                  That is about your child but individually does not identify
                  your child; and/or
                </li>
                <li>
                  About your internet connection, the equipment your child uses
                  to access our Website, and usage details.
                </li>{" "}
              </ul>
            </p>

            <p>
              <span class="fw-bold">We may collect this information:</span>
              <ul>
                <li>
                  Directly from your child when your child provides it to us.
                </li>
                <li>
                  Automatically as your child navigates through the site.
                  Information collected automatically may include usage details,
                  IP addresses, and information collected through cookies and
                  other tracking technologies.
                </li>
                <li>
                  From third parties, for example, our business partners and
                  your child’s school.
                </li>{" "}
              </ul>
            </p>
            <p>
              <span class="fw-bold mb-0">
                Information Your Child Provides to Us.
              </span>
              The information we collect on or through our Website may include:
              <ul>
                <li>
                  Information that your child or you provide by filling in forms
                  on our Website. This includes information provided at the time
                  of registering to use our Website, subscribing to our service,
                  posting material, or requesting further services, or when you
                  or your child reports a problem with our Website. A child must
                  provide us with the following information to register with
                  this website: the child's first and last name, age, grade,
                  classroom designation, and a parent's email address. We also
                  require the child to create a member name and password. We may
                  request additional information from your child, but this
                  information is optional. We specify whether information is
                  required or optional when we request it. In addition, we may
                  also ask, with your additional verifiable parental consent,
                  your child for information when your child enters a contest or
                  promotion sponsored by us.
                </li>
                <li>
                  Records and copies of your child’s or your correspondence
                  (including email addresses), if one of you contacts us.
                </li>
                <li>
                  Responses to surveys that we might ask your child or you to
                  complete for research purposes.
                </li>
                <li>
                  Details of transactions you or your child carries out through
                  our Website and of the fulfillment of your respective orders.
                  You may be required to provide financial information before
                  placing an order through our Website.
                </li>
                <li>Your child or your search queries on the Website.</li>
                <li>
                  We may enable registered users to communicate with others on
                  or through this Website and/or Service through chat, email,
                  and posting to public areas of the Website and our related
                  services ("<b>Social Features</b>"). The nature of these
                  features allows children to disclose personal information
                  about themselves. We do not monitor or review this content
                  before it is posted, and we do not control the actions of
                  third parties with whom your child shares his or her
                  information. We encourage parents to educate their children
                  about safe internet use and to monitor their children's use of
                  social features.
                </li>
                <li>
                  Your child or you also may provide information to be published
                  or displayed (hereinafter, "<b>posted</b>") on public areas of
                  the Website, or transmitted to other users of the Website or
                  third parties (collectively, "<b>User Contributions</b>").
                  Your User Contributions are posted on and transmitted to
                  others at your own risk. Although your child or you may set
                  certain privacy settings for such information by logging into
                  your account profile, please be aware that no security
                  measures are perfect or impenetrable. Additionally, we cannot
                  control the actions of other users of the Website with whom
                  your child or you may choose to share your User Contributions.
                  Therefore, we cannot and do not guarantee that your User
                  Contributions will not be viewed by unauthorized persons.
                </li>
              </ul>
            </p>

            <p>
              <span class="fw-bold mb-0">
                Information We Collect Through Automatic Data Collection
                Technologies.
              </span>
              As your child or you navigate through and interact with our
              Website, we may use automatic data collection technologies to
              collect certain information about your respective equipment,
              browsing actions, and patterns, including:
              <ul>
                <li>
                  {" "}
                  Details of your child or your visits to our Website, including
                  traffic data location data, logs, and other communication data
                  and the resources that you access and use on the Website.
                </li>
                <li>
                  Information about your child or your computer and internet
                  connection, including your child’s or your IP address,
                  operating system, and browser type.
                </li>
              </ul>
              <p>
                We may combine non-personal information we collect through these
                technologies with personal information about you or your child
                that we collect online.
                <br />
                <br />
                For information about our automatic information collection
                practices, including how you can opt out of certain information
                collection, see the "Automatic Information Collection and
                Tracking" and "Choices About How We Use and Disclose Your
                Information" the sections below
                <br />
                <br />
                The information we collect automatically may be only statistical
                data and may or may not include personal information, or we may
                maintain it or associate it with personal information we collect
                in other ways or receive from third parties. It helps us to
                improve our Website and to deliver a better and more
                personalized service, including by enabling us to:
                <br />
                <br />
              </p>
              <ul>
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your child or your preferences,
                  allowing us to customize our Website according to your child’s
                  or your individual interests.
                </li>
                <li>Speed up your child or your searches.</li>
                <li>
                  Recognize your child or you when either of you return to our
                  Website.
                </li>
                <li>
                  The technologies we use for this automatic data collection may
                  include:
                </li>
              </ul>
            </p>

            <p>
              <span class="fw-bold">Cookies (or browser cookies).</span>
              A cookie is a small file placed on the hard drive of your
              computer. Your child or you may refuse to accept browser cookies
              by activating the appropriate setting on your respective browsers.
              However, if either of you select this setting you respectively may
              be unable to access certain parts of our Website. Unless you have
              adjusted your child or your browser settings so that it will
              refuse cookies, our system will issue cookies when your child or
              you direct your respective browser to our Website. <br />
              <br />
              Cookies can be "Persistent" or "Session" Cookies. Persistent
              Cookies remain on your personal computer or mobile device when
              your child or you go offline, while Session Cookies are deleted as
              soon as your child or you close your respective web browser. For
              the record, we use both session and persistent Cookies for the
              purposes set out below:s
            </p>
            <span class="fw-bold">Necessary / Essential Cookies</span>
            <ul>
              <li>Type: Session Cookies</li>
              <li>Administered by: Us</li>
              <li>
                Purpose: These cookies are essential to provide your child and
                you with services available through the Website and to enable
                your child and you to use some of its features. They help to
                authenticate users and prevent fraudulent use of user accounts.
                Without these cookies, the services that your child and you have
                asked for cannot be provided, and we only use these cookies to
                provide your child and you with those services.
              </li>
            </ul>
            <span class="fw-bold">Functionality Cookies</span>
            <ul>
              <li>Type: Persistent Cookies</li>
              <li>Administered by: Us</li>
              <li>
                Purpose: These cookies allow us to remember choices your child
                and you make when your child and you use the Website, such as
                remembering your child’s login details or language preference.
                The purpose of these cookies is to provide your child and you
                with a more personal experience and to avoid your child and you
                having to re-enter your respective preferences every time one of
                you use the Website.
              </li>
            </ul>
            <p>
              As of now we do not use any tracking mechanism for cookies on the
              Website.
            </p>
            <p>
              <strong>Web Beacons.</strong> Pages of our Website and/or our
              emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of
              certain website content and verifying system and server
              integrity).
            </p>
            <p>
              <strong>Flash Cookies. </strong> Certain features of our Website
              may use local stored objects (or Flash cookies) to collect and
              store information about your child or your preferences and
              navigation to, from, and on our Website. Flash cookies are not
              managed by the same browser settings as are used for browser
              cookies. For information about managing your child or your privacy
              and security settings for Flash cookies, see Choices About How We
              Use and Disclose Your Information.
              <br />
              <br />
              In addition, please know that we do not collect personal
              information automatically, but we may tie this information to
              personal information about your child or you that we collect from
              other sources or your child or your provide to us.
            </p>
            <span class="fw-bold">
              Third-Party Use of Cookies and Other Tracking Technologies
            </span>
            <p>
              Some content or applications, including advertisements, on the
              Website are served by third-parties, including advertisers, ad
              networks and servers, content providers, and application
              providers. These third parties may use cookies to collect
              information about you when you use our website. The information
              they collect may be associated with your child’s personal
              information or they may collect information, including personal
              information, about your child’s online activities over time and
              across different websites and other online services. They may use
              this information to provide your child or you with interest-based
              (behavioral) advertising or other targeted content. <br />
              We do not control these third parties' tracking technologies or
              how they may be used. If you have any questions about an
              advertisement or other targeted content, you should contact the
              responsible provider directly. For information about how you can
              opt out of receiving targeted advertising from many providers, see
              Choices About How We Use and Disclose Your Information.
            </p>
            <span class="fw-bold">How We Use Your Child’s Information</span>
            <p>
              We may use the personal information we collect from your child to:{" "}
              <br />
              <ul>
                <li>
                  {" "}
                  register him or her with the Website and our related services;
                </li>
                <li>
                  communicate with him or her about activities or features of
                  the Website that may be of interest; or
                </li>
                <li>
                  track his or her performance in games or other activities.
                </li>
              </ul>
            </p>

            <p>
              We use the information we collect automatically through technology
              (see Automatic Information Collection and Tracking) and other
              non-personal information we collect to improve our Website and to
              deliver a better and more personalized experience by enabling us
              to:
            </p>
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about the child's preferences, allowing us to
                customize the content according to individual interests.
              </li>
              <li>Speed up your searches.</li>
            </ul>

            <p>
              We use information that we collect about your child or that your
              child provides to us, including any personal information:
              <ul>
                <li>To present our Website and its contents to you.</li>
                <li>
                  To provide your child or you with information, products, or
                  services that either of you request from us.
                </li>
                <li>
                  To fulfill any other purpose for which your child or you
                  provide it.
                </li>
                <li>
                  To provide your child or you with notices about your account,
                  including expiration and renewal notices.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>
                <li>
                  To notify you about changes to our Website or any products or
                  services we offer or provide though it.
                </li>
                <li>
                  To allow your child or you to participate in interactive
                  features on our Website.
                </li>
                <li>
                  In any other way we may describe when your child or you
                  provide the information.
                </li>
                <li>For any other purpose with your verifiable consent.</li>
              </ul>
            </p>

            <p>
              We may also use your information to contact you about our own,
              your school’s and other third-parties' goods and services that may
              be of interest to you. If you do not want us to use your
              information in this way, please [check the relevant box located on
              the form on which we collect your data (the registration
              form)/adjust your user preferences in your account profile. For
              more information, see Choices About How We Use and Disclose Your
              Information.
              <br />
              We may use the information we have collected from you to enable us
              to display advertisements to our advertisers' target audiences.
              Even though we do not disclose your personal information for these
              purposes without your consent, if you click on or otherwise
              interact with an advertisement, the advertiser may assume that you
              meet its target criteria.
            </p>

            <span class="fw-bold">
              Our Practices for Disclosing Children's Information
            </span>
            <p>
              We do not share, sell, rent, or transfer children's personal
              information other than as described in this section.
              <br />
              We may disclose aggregated information about many of our users,
              and unaggregated information that does not identify any individual
              or device. In addition, we may disclose children's personal
              information:
              <br />
              <br />
              <ul>
                <li>
                  To fulfill the purpose for which your child or you provide it;
                </li>
                <li>
                  For any other purpose disclosed by us when your child or you
                  provide the information;
                </li>
                <li>
                  If we are required to do so by law or legal process, such as
                  to comply with any court order or subpoena or to respond to
                  any government or regulatory request.
                </li>
                <li>
                  To enforce or apply our terms of use and other agreements,
                  including for billing and collection purposes;
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of Company, our
                  customers or others, including to:
                </li>
                <li>protect the safety of a child;</li>
                <li>protect the safety and security of the Website; or</li>
                <li>enable us to take precautions against liability.</li>
                <li>
                  To law enforcement agencies or for an investigation related to
                  public safety.
                </li>
                <li>To our subsidiaries and affiliates;</li>
                <li>
                  To contractors, service providers, and other third parties we
                  use to support our business and who are bound by contractual
                  obligations to keep personal information confidential and use
                  it only for the purposes for which we disclose it to them;
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of the Company, our
                  customers, or others. This includes exchanging information
                  with other companies and organizations for the purposes of
                  fraud protection and credit risk reduction;
                </li>
                <li>
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization; and
                </li>
                <li>
                  dissolution, or other sale or transfer of some or all of the
                  Company's assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  personal information held by the Company about our Website
                  users is among the assets transferred.
                </li>
              </ul>
            </p>
            <span class="fw-bold">
              Your Additional, Independent Choices About How We Use and Disclose
              Your Information
            </span>
            <p>
              We strive to provide you with choices regarding the personal
              information your child provides to us. We have created mechanisms
              to provide you with the following control over your child and your
              information:{" "}
            </p>
            <ul>
              <li>
                <b>Tracking Technologies and Advertising.</b> You can set your
                child and/or your browser to refuse all or some browser cookies,
                or to alert you when cookies are being sent. To learn how you
                can manage your Flash cookie settings, visit the Flash player
                settings page on Adobe's website. If your child or you disable
                or refuse cookies, please note that some parts of this Website
                may then be inaccessible or not function properly.
              </li>

              <li>
                <b>
                  Disclosure of Your Information for Third-Party Advertising.
                </b>{" "}
                The Company does not disclose your information to third-party
                advertisers.{" "}
              </li>

              <li>
                <b>Promotional Offers from the Company.</b> If you do not wish
                to have your email address/contact information used by the
                Company to promote our own or third parties' products or
                services, you can opt-out by at any other time by logging into
                the Website and adjusting your user preferences in your account
                profile by checking or unchecking the relevant boxes or by
                sending us an email stating your request to opt-
                <a href="mailto:out@thesupermeclub.com">
                  out@thesupermeclub.com
                </a>
                . If we have sent you a promotional email, you may send us a
                return email asking to be omitted from future email
                distributions. This opt out does not apply to information
                provided to the Company as a result of legal compliance or other
                business operational needs.
              </li>

              <li>
                <b>Targeted Advertising.</b> If you do not want us to use
                information that we collect or that you provide to us to deliver
                advertisements according to our advertisers' target-audience
                preferences, you can opt-out by emailing us at opt-
                <a href="mailto:out@thesupermeclub.com">
                  out@thesupermeclub.com
                </a>
                . [For this opt-out to function, you must have your browser set
                to accept all browser cookies.
              </li>

              <li>
                <b>Social Features.</b> The Website offers social features,
                including[instant messaging, chat, email, posting on message
                boards, and any other features that may enable children
                communicate with others on and/or through the Website. We do not
                control the types of information your child can send or post
                through them. We encourage parents to educate their children
                about safety online and to carefully monitor their children's
                use of social features to ensure they do not disclose their
                personal information through them.
              </li>

              <li>
                We do not control third parties' collection or use of your
                information to serve interest-based advertising off of our
                Website. However, these third parties may provide you with ways
                to choose not to have your information collected or used in this
                way. You can opt out of receiving targeted ads from members of
                the Network Advertising Initiative ("NAI") on the NAI's website.
              </li>
            </ul>

            <span class="fw-bold">
              Operators That Collect or Maintain Information from Children
            </span>
            <p>
              The following are all operators that may collect or maintain
              personal information from children through the Website: [IN A
              CHART, LIST OF OPERATOR NAMES AND THEIR POSTAL ADDRESSES,
              TELEPHONE NUMBERS, AND EMAIL ADDRESSES].
              <br />
              OR
              <br />A list of all operators that may collect or maintain
              personal information from children through the Website is
              available here. Please direct your inquiries about any operator's
              privacy practices and use of children's information to the
              operator at its contact information provided above or email us at{" "}
              <a href="mailto:privacy@thesupermeclub.com">
                privacy@thesupermeclub.com
              </a>
              .{" "}
            </p>
            <span class="fw-bold">Data Security</span>
            <p>
              We have implemented measures designed to secure your child’s and
              your personal information from accidental loss and from
              unauthorized access, use, alteration, and disclosure. [All
              information you provide to us is stored on our secure servers
              behind firewalls. Any payment transactions [and [OTHER
              INFORMATION]] will be encrypted [using SSL technology].] <br />
              The safety and security of your child’s information also depends
              on you. Where we have given you child and you (or where you have
              chosen) a password for access to certain parts of our Website,
              your child and you are responsible for keeping this password
              confidential. We ask your child nor you share your password with
              anyone. <br />
              Unfortunately, the transmission of information via the internet is
              not completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our Website. Any transmission
              of any personal information is at your own risk. We are not
              responsible for circumvention of any privacy settings or security
              measures contained on the Website.
            </p>
            <span class="fw-bold">
              Your Child’s International and State Privacy Rights
            </span>
            <p>
              International and US state consumer privacy laws may provide their
              residents with additional rights regarding our use of their
              personal information.{" "}
            </p>

            <span class="fw-bold">United States</span>
            <p>
              In the US, if your child is a resident of one of the following
              states, please know we will do the following:
              <br />
              Colorado, Connecticut, Virginia, and Utah each provide their state
              residents with rights to:
              <br />
              <ul>
                <li>Confirm whether we process their personal information.</li>
                <li>Access and delete certain personal information.</li>
                <li>Data portability.</li>
                <li>
                  Opt-out of personal data processing for targeted advertising
                  and sales.
                </li>
                <li>
                  Colorado, Connecticut, and Virginia also provide their state
                  residents with rights to:
                </li>
                <li>
                  Correct inaccuracies in their personal information, taking
                  into account the information's nature processing purpose.
                </li>
                <li>
                  Opt-out of profiling in furtherance of decisions that produce
                  legal or similarly significant effects.{" "}
                </li>
              </ul>
              <p>
                Nevada provides its residents with a limited right to opt-out of
                certain personal information sales. Residents who wish to
                exercise this sale opt-out rights may submit a request to this
                designated address&nbsp;
                <a href="mailto:privacy@thesupermeclub.com">
                  privacy@thesupermeclub.com
                </a>
                . However, please know we do not currently sell data triggering
                that statute's opt-out requirements.
                <br />
                <br />
                For children who are residents of New York State, we adhere to
                the requirements found in the Department of Education’s Parents
                Bill of Rights for Data Privacy and Security, found at
                https://www.schools.nyc.gov/school-life/know-your-rights/parents-bill-of-rights-for-data-privacy-and-security,
                namely:
              </p>
              <ul>
                <li>
                  Your child’s personal information will not be sold or released
                  for any marketing or other commercial purposes.
                </li>
                <li>If your child is under age 18:</li>
                <li>
                  You have the right to inspect and review the complete contents
                  of your child’s content found on the Website within forty-five
                  (45) days of our receiving your request and verifying your
                  identity.
                </li>
                <li>
                  You also have the right to request changes to your child’s
                  information on the Website when you believe such information
                  may be inaccurate, misleading, or violate your child’s
                  privacy.
                </li>
                <li>
                  You have the right to be notified if a breach or unauthorized
                  release of your child’s personal information occurs.
                </li>
                <li>
                  You have the right to make complaints about possible breaches
                  and unauthorized disclosures of your child’s personal
                  information and to have such complaints addressed. We will
                  provide you with a response no more than sixty (60) calendar
                  days from when we receive your complaint. If more time is
                  needed, we will provide an explanation to you, along with an
                  approximate date for a response.
                </li>
                <li>
                  This section is subject to change. Please check back here
                  frequently for updates. If your state is not listed, please
                  note that the Company will be compliant with all applicable
                  state laws.
                </li>
              </ul>
            </p>
            <span class="fw-bold">United Kingdom</span>
            <p>
              If your child is a resident of the UK, please know that we follow
              the UK’s ‘data protection principles’ making sure your child’s
              information is:
              <ul>
                <li>used fairly, lawfully and transparently;</li>
                <li>used for specified, explicit purposes;</li>
                <li>
                  used in a way that is adequate, relevant and limited to only
                  what is necessary;
                </li>
                <li>accurate and, where necessary, kept up to date;</li>
                <li>kept for no longer than is necessary; or</li>
                <li>
                  handled in a way that ensures appropriate security, including
                  protection against unlawful or unauthorized processing,
                  access, loss, destruction or damage.
                </li>
              </ul>
            </p>
            <p>
              In the case of more sensitive information, we add additional
              protections. The UK views the following information as being
              “sensitive information:”
            </p>
            <ul>
              <li>race;</li>
              <li>ethnic background;</li>
              <li>political opinions;</li>
              <li>religious beliefs;</li>
              <li>trade union membership;</li>
              <li>genetics;</li>
              <li>biometrics (where used for identification);</li>
              <li>health; or</li>
              <li>sex life or orientation.</li>
            </ul>
            <p>
              Under the UK Data Protection Act 2018, you have the right to find
              out what information the government and other organizations store
              about your child. These include the right to:
            </p>
            <ul>
              <li>be informed about how your data is being used;</li>
              <li>access personal data;</li>
              <li>have incorrect data updated;</li>
              <li>have data erased;</li>
              <li>stop or restrict the processing of your data;</li>
              <li>
                data portability (allowing you to get and reuse your data for
                different services); or
              </li>
              <li>
                object to how your data is processed in certain circumstances.
              </li>
            </ul>
            <p>
              You also have rights when we use your child’s personal data for:
            </p>
            <ul>
              <li>
                automated decision-making processes (without human involvement);
                or
              </li>
              <li>
                profiling, for example to predict your behavior or interests.
              </li>
            </ul>
            <span class="fw-bold">European Union</span>
            <p>
              If your child is a resident of the EU, please know that we follow
              the EU’s data protection laws as outlined in the General Data
              Protection Regulation. On behalf of your child, you have:
            </p>
            <ul>
              <li>The right to be informed</li>
              <li>The right of access</li>
              <li>The right to rectification</li>
              <li>The right to erasure</li>
              <li>The right to restrict processing</li>
              <li>The right to data portability</li>
              <li>The right to object</li>
              <li>
                Rights in relation to automated decision making and profiling.
              </li>
            </ul>
            <p>
              To make such a request about your child’s particular international
              or state rights not listed above, please send an email to{" "}
              <a href="mailto:privacy@thesupermeclub.com">
                privacy@thesupermeclub.com
              </a>
              .
            </p>
            <span class="fw-bold">
              Accessing and Correcting Your Child’s Information
            </span>
            <p>
              You can review and change your child’s personal information by
              logging into the Website and visiting your child’s account profile
              page.
              <br />
              <br />
              You may also send us an email at{" "}
              <a href="mailto:privacy@thesupermeclub.com">
                privacy@thesupermeclub.com
              </a>{" "}
              to request access to, correct or delete any personal information
              that your child or you have provided to us. We cannot delete your
              child’s personal information except by also deleting your child’s
              user account. We may not accommodate a request to change
              information if we believe the change would violate any law or
              legal requirement or cause the information to be incorrect.
              <br />
              <br />
              If you delete your User Contributions from the Website, copies of
              your User Contributions may remain viewable in cached and archived
              pages, or might have been copied or stored by other Website users
              (e.g., your User Contributions you place in group chats).{" "}
            </p>

            <span class="fw-bold">Changes to Our Privacy Policy</span>
            <p>
              It is our policy to post any changes we make to our privacy policy
              on this page with a notice that the privacy policy has been
              updated on the Website home page. If we make material changes to
              how we treat our users' personal information, we will notify you,
              as your child’s parent, by email to your email address specified
              in your account or through a notice on the Website home page. The
              date the privacy policy was last revised is identified at the top
              of the page. As your child’s parent, you are responsible for
              ensuring we have an up-to-date active and deliverable email
              address for you, and for periodically visiting our Website and
              this privacy policy to check for any changes.
            </p>
            <span class="fw-bold">Contact Information</span>
            <p>
              To ask questions or comment about this privacy policy and our
              privacy practices, or to register a complaint or concern, please
              email us at{" "}
              <a href="mailto:privacy@thesupermeclub.com">
                privacy@thesupermeclub.com
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <MainFooter />
    </div>
  );
}

export default PrivacyPage;
