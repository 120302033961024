import React from 'react';
import styles from './button.module.scss';

export const Button = (props) => {
  return (
    <a href={props.href} >
      <button {...props} className={styles.button + ' ' + props.className}>
        {props.children}
      </button>
    </a>
  );
};
