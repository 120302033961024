import Dialog from "@mui/material/Dialog";
import Modal from "@mui/material/Modal";
import React from 'react';

import missionsCSS from "../missionsPage.module.scss";

const images = {
  close: require('../../../../assets/images/close.png'),
  success: require('../../../../assets/images/userSite/achievements_banner.png'),
};

const CompletedDialog = ({ open, handleClose, mission }) => {

  return (
    <Dialog open={open} onClose={handleClose}
      PaperProps={{
        className: missionsCSS.mission_modal_dialog,
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflow: 'hidden',
          justifyContent: 'center',
        },
      }}
    >
      <div className={` ${missionsCSS.modal_content}`}>

        <div className={missionsCSS.close_btn} onClick={() => handleClose("completed")} alt="Close">&#10005;</div>

        <div className={missionsCSS.modal_details}>
          <div className={missionsCSS.modalCat}>
            {mission['category']}
          </div>
          <div className={missionsCSS.modalTask}>
            {mission['task']}
          </div>
        </div>
        <div className={missionsCSS.model_result}>
          <img src={images["success"]} alt='' className='' style={{}} />
          <div>Task Completed!</div>
        </div>
      </div>
    </Dialog>
  );
};

export default CompletedDialog;
