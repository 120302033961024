import React from "react";


import cssModule from "./contentBack.module.css";
const images = require.context("../../../../assets/images", true);

const ContentBack = ({
    pageContent,
    Navbar = null,
    Footer = null,
    footerClass,
    bgClass = "form_page_main_div",
    centerDivCol = "col-12",
    mainDivClass = "defaultMainDiv",
    background = "./website/banner.png"
}) => {

    return (
        <div className={cssModule.container} style={{ backgroundImage: `url(${images(`${background}`)})` }}>
            {Navbar && <nav class={cssModule.navbar}>
                <Navbar />
            </nav>}
            <main class={cssModule.content}>
                <div className={cssModule[mainDivClass]}>

                    {/* {mainContent} */}
                    <div
                        className={`col-lg-8 col-xl-6 ${centerDivCol} text-center ${cssModule.centerMainDiv}`}
                    >
                        <div className={cssModule.centerWrapper} >{pageContent}</div>
                    </div>

                </div>
            </main>
            {Footer && <footer className={cssModule.footer}>
                <Footer />
            </footer>}
        </div>

    );
}


export default ContentBack;