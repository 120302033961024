import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import FormInput from "../../../../Components/FormInput";
import ApiService from "../../../../Services/apiService";
import DialogTemplate from "../../../../Components/DialogTemplate";

import { validation } from "../../../../Util/Validation";

import main_css from "../../../../assets/main_css/main.module.css";

const ResetPasswordForm = () => {
  const apiService = new ApiService();
  const navigate = useNavigate("/");
  const params = useParams();
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassVali, setConfirmPassVali] = useState({
    pattern: validation.password.pattern,
    message: validation.password.message,
  });
  const [dialog, setDialog] = useState({
    open: false,
    mainText: "",
    subText: "",
    duration: "0ms",
    // closeHandler: null,
  });

  let classes = "";

  useEffect(() => {
    if (newPass !== confirmPass) {
      setConfirmPassVali({
        pattern: "err",
        message: "Your passwords do not match",
      });
    } else {
      setConfirmPassVali({
        pattern: validation.password.pattern,
        message: validation.password.message,
      });
    }
  }, [newPass, confirmPass]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Reseting Password...");

    try {
      const [response, error] = await apiService.post({
        path: "/reset_password",
        dataType: "login",
        data: {
          token: params.token,
          newPass: newPass,
          confirmPass: confirmPass,
        },
      });

      if (!error) {
        //show a modal confirmation
        setDialog({
          open: true,
          mainText: "Password Reseted",
          subText: "Your password has been reset successfully!",
          duration: "1000ms",
        });

        setTimeout(() => {
          //   navigate("/profile");
          navigate("/login");
        }, 1500);
      } else {
        //show modal with error
        setDialog({
          open: true,
          mainText: "Password Reset failed!",
          subText: "Your password has not been reset\nPlease try again!",
          duration: "1000ms",
          closeHandler: handleCloseDialog,
        });
      }
    } catch (error) {
      setDialog({
        open: true,
        mainText: "Something went wrong!",
        subText:
          "Please try again!\nIf this issue continues please reach out to Support",
        duration: "1000ms",
        closeHandler: handleCloseDialog,
      });

      setTimeout(() => {
        setDialog({
          open: false,
          mainText: "",
          subText: "",
          duration: "",
          closeHandler: null,
        });
      }, 5000);
    }
  };

  const handleCloseDialog = () => {
    setDialog({
      open: false,
      mainText: "",
      subText: "",
      duration: "",
      closeHandler: null,
    });
  };

  const mainContent = (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div
          style={{ width: "100%" }}
          className="d-flex flex-column justify-content-center "
        >
          <div
            style={{ height: "100%", width: "100%" }}
            className="d-flex flex-column align-items-center justify-content-center "
          >
            <h1 className={main_css.title} style={{ color: "white" }}>Reset Password</h1>
            <div className="row text-center pt-5 mt-2">
              <div className="col-9 mx-auto">
                <div className="form-group mb-2 ajax-field">
                  <FormInput
                    type={"password"}
                    value={newPass}
                    placeholder={"New Password"}
                    name={"newPass"}
                    onChangeHandler={setNewPass}
                    pattern={validation.password.pattern}
                    message={validation.password.message}
                  />
                </div>
              </div>
              <div className="col-9 mx-auto">
                <div className="ajax-field">
                  <FormInput
                    type={"password"}
                    value={confirmPass}
                    placeholder={"Confirm Password"}
                    name={"confirmPass"}
                    onChangeHandler={setConfirmPass}
                    pattern={confirmPassVali.pattern}
                    message={confirmPassVali.message}
                  />
                  <span className={`loginErrorText ${classes}`}>
                    Incorrect Email Or Password
                  </span>
                </div>
              </div>
              <div className="pt-xl-2 pt-xxl-4">
                <button type="submit" className={main_css.basic_btnInv}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {dialog.open === true && <DialogTemplate {...dialog} />}
      {mainContent}
    </>
  );
};

export default ResetPasswordForm;
