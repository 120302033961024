import React from "react";
import { useNavigate } from "react-router";
import {
  IconAffiliateFilled,
  IconLayoutDashboardFilled,
  IconMenu2,
  IconPaintFilled,
  IconUserCircle,
  IconUsers,
  IconLogout,
} from "@tabler/icons-react";
import { Link, useLocation } from "react-router-dom";
import styles from './sideMenu.module.scss';

import ApiService from "../../../Services/apiService";

import Cookies from "js-cookie";

export const MenuItem = (props) => {
  const location = useLocation();

  const handleClick = (e) => {
    if (location.pathname === props.to) {
      e.preventDefault();
      window.location.reload();
    }
  };

  return (
    <Link to={props.to} onClick={handleClick}>
      <div className={styles.item}>
        <props.icon className={styles.icon} />
        <span className={styles.label}>{props.title}</span>
      </div>
    </Link>
  );
};

export const MenuItemButton = (props) => {
  return (
    <div className={styles.item} onClick={props.onClick}>
      <props.icon className={styles.icon} />
      <span className={styles.label}>{props.title}</span>
    </div>
  );
};

export const SideMenu = (props) => {
  const navigate = useNavigate();
  const apiService = new ApiService();
  let cookie = Cookies.get("user");
  cookie = JSON.parse(cookie);
  const name = cookie.name;

  const logoutHandler = async () => {
    try {
      const [response, error] = await apiService.get({
        path: "/",
        dataType: "logout",
      });
      console.log(response);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.sideMenu}>
      <div className={styles.title}>
        <h3>{name.split(" ")[0]}</h3>
        <IconMenu2 className={styles.menuIcon} />
      </div>
      <div className={styles.menu}>
        <MenuItem title="Dashboard" icon={IconLayoutDashboardFilled} to='/dashboard' />
        <MenuItem title="Users" icon={IconUsers} to="/users" />
        <MenuItem title="Campaigns" icon={IconAffiliateFilled} to="/campaigns" />
        {cookie.role !== 'parent' && <MenuItem title="Creator" icon={IconPaintFilled} to="/creator" />}
       {/* <MenuItem title="Profile" icon={IconUserCircle} to="/admin-profile" /> */}
        <div style={{ paddingTop: "0.5rem" }}>
          <MenuItemButton title="Logout" icon={IconLogout} onClick={logoutHandler} />
        </div>
        {/* <div style={{ padding: "0.5rem" }}>
          <Button style={{ paddingTop: "0.25rem", paddingBottom: "0.25rem" }}>
            Need Support?
          </Button>
        </div> */}
      </div>
    </div>
  );
};