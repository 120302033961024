import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { height, maxHeight, minHeight } from "@mui/system";

const images = {
  arrowLeft: require("../../assets/images/arrowLeft.png"),
  arrowRight: require("../../assets/images/arrowRight.png"),
};

const styles = {
  container: {
    display: "flex",
    scrollSnapType: "x mandatory",
    width: "100%",
    height: "100%",
    alignItems: "center",
    overflow: "hidden",
  },

  slide: {
    position: "relative",
    width: "100%",
    height: "100%",
    flexShrink: 0,
    marginLeft: "20px",
    marginRight: "20px",
    scrollSnapAlign: "center",
    display: "flex",
  },

  rightArrow: {
    position: "absolute",
    top: 400,
    fontSize: "50px",
  },

  arrow: {
    position: "absolute",
  },

  arrowLeft: {
    top: "50%",
    left: "5%",
  },

  arrowRight: {
    top: "50%",
    right: "5%",
  },
};

function CarouselTest({ items, startIndex, disableLeft }) {
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  let random = Math.floor(Math.random() * 10000 + 1);

  const moveSlide = (direction) => {
    if (direction === "left") {
      if (currentIndex === 0) {
        setCurrentIndex(items.length - 1);
      } else {
        setCurrentIndex((prev) => {
          return prev - 1;
        });
      }
    } else {
      if (currentIndex === items.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prev) => {
          return prev + 1;
        });
      }
    }
  };

  useEffect(() => {
    const next = document.getElementById(`${random}_${currentIndex}`);
    next.scrollIntoView({ behavior: "smooth", inline: "start" });
  }, [currentIndex]);

  return (
    <>

      <div>
        <IconButton
          style={{ ...styles.arrow, ...styles.arrowLeft }}
          onClick={() => moveSlide("left")}
        >
          <img src={images.arrowLeft} width="25" height="auto" alt="" />
          {/* <ArrowBackIosIcon /> */}
        </IconButton>
      </div>
      <div>
        <IconButton
          style={{ ...styles.arrow, ...styles.arrowRight }}
          onClick={() => moveSlide("right")}
        >
          <img src={images.arrowRight} width="25" height="auto" alt="" />
          {/* <ArrowForwardIosIcon /> */}
        </IconButton>
      </div>
      <div style={styles.container}>
        {items.map((item, index) => (
          <div style={styles.slide} id={`${random}_${index}`}>
            {item}
          </div>
        ))}
        {!disableLeft && <div style={styles.button}></div>}
      </div>
    </>
  );
}

export default CarouselTest;
