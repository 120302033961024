import { React, useState } from "react";

import "../../../assets/main_css/main.css";
import main_css from "../../../assets/main_css/main.module.css";

import MainNavBar from "../../Components/mainNavbar/MainNavbar";
import RadioButtons from "./components/SignupRadioButtons";
import StudentSignUpForm from "./components/StudentSignUpForm";
import SchoolSignUpForm from "./components/SchoolSignUpForm";
import TeacherSignUpForm from "./components/TeacherSignUpForm";
import ParentSignUpForm from "./components/ParentSignUpForm";
import ContentBack from "../../Components/contentBack/v1/ContentBack";
import MainPageTemplate from "../../Components/LandingPageTemplate";

const FormPages = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const [formType, setFormType] = useState(
    params.get("_form") ? params.get("_form") : "student"
  );
  const [accessToken, setAccessToken] = useState(
    params.get("_token") ? params.get("_token") : null
  );

  const formChangerHandler = (event) => {
    setFormType(event.target.value);
  };
  const formsList = {
    student: {
      lable: "student",
      form: <StudentSignUpForm token={accessToken} />,
    },
    school: {
      lable: "school",
      form: <SchoolSignUpForm />,
    },
    teacher: {
      lable: "teacher",
      form: <TeacherSignUpForm token={accessToken} />,
    },
    parent: {
      lable: "parent",
      form: <ParentSignUpForm token={accessToken} />,
    },
  };

  let capitalizedFormType =
    formType.charAt(0).toUpperCase() + formType.slice(1);

  const centerContent = (
    <div className="" style={{ height: '100%' }}>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <div className="">
          <RadioButtons
            onChangeForm={formChangerHandler}
            active={formType}
            formTypes={["student", "parent"]}
          />
        </div>
        <div className="">
          <RadioButtons
            onChangeForm={formChangerHandler}
            active={formType}
            formTypes={["teacher", "school"]}
          />
        </div>
      </div>
      <div className="text-center" style={{ marginTop: "2%" }}>
        <h2
          className={`pb-sm-3 mb-0 ${main_css.title} ${main_css.noAfter}`}
          style={{ color: "white" }}
        >
          {`${capitalizedFormType} Sign Up`}
        </h2>
      </div>
      {/* <div className="text-center">
        <p
          className={`${home.paragraph}  ${home.bold} mx-5 mx-sm-0 mt-0`}
          style={{ letterSpacing: "0", color: "white" }}
        >
          {" "}
          Please provide the following information:
        </p>
      </div> */}
      <div
        className={`form_content ${formType}`}
        style={{ paddingRight: "10px", marginTop: "2%" }}
      >
        {formsList[formType]["form"]}
      </div>
    </div>
  );

  // const mainContent = (
  //   <div id="section1">
  //     <div className="d-flex flex-row align-items-center justify-content-evenly fp-tableCell"
  //       style={{ height: "100%" }}
  //     >

  //       {/* Character 1 */}
  //       <div
  //         className="text-center col-2 px-xxl-5 col-lg-3 col-xxl-3 left_girl_section"
  //       // style={{ maxHeight: '50%' }}
  //       >
  //         <img
  //           id="thegirl"
  //           src={images.girlLeft_Standing}
  //           alt=""
  //           className="img-fluid"
  //           style={{
  //             width: '65%'
  //           }}
  //         />
  //       </div>

  //       {/* Main Form */}
  //       {/* text-center col-8 col-sm-10 col-md-7 col-lg-6 px-xl-5 mx-xl-5 d-flex align-items-center */}
  //       <div className="text-center col-lg-6 col-xxl-6" style={{ maxHeight: '80%', marginTop: '5%' }}>
  //         <ContentBack content={centerContent} />
  //       </div>

  //       {/* Character 2 */}
  //       <div
  //         className="text-center col-2 px-xxl-5 col-lg-3 col-xxl-3 right_boy_section"
  //       >
  //         <img
  //           id="theImg"
  //           src={images.boyRight_Standing}
  //           alt=""
  //           className="img-fluid"
  //           style={{
  //             width: '65%'
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );
  const content = (
      <div
      className="text-center col-lg-6 col-xxl-6"
      style={{maxHeight: "70%", marginTop: "5%",}}
    >
      <ContentBack content={centerContent} />
    </div>
  );
  const mainContent = <MainPageTemplate content={content} />;

  return (
    <>
      <MainNavBar />
      {mainContent}
    </>
  );
};

export default FormPages;
