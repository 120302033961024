import { React, useState, useEffect, useContext } from "react";

import InfoTable from "../../../Components/infoTable/InfoTable";

import { InfoContext } from "../AdminCampaignsPage";
import { unixDateConverter, capitalizeWords } from "../../../../Util/utils";
import useUpdateInfo from "../../../Hooks/useUpdateInfo";
import useUpdateCampaign from "../../../Hooks/useUpdateCampaign";

const CampaignInfoTable = ({
  campaignId,
  campaignGoalType,
  name,
  startDate,
  endDate,
  progress,
  goalCompleted,
  role,
}) => {
  const { setCurrentCampaign } = useContext(InfoContext);
  const goalType = capitalizeWords(campaignGoalType);
  const campaignArrayFilter = [{ "campaign._id": campaignId }];
  const [campaignInfo, setCampaignInfo] = useState();
  const updateInfo = useUpdateInfo();
  const updateCampaignContingencies = useUpdateCampaign();

  const campaignStarted = startDate - Date.now() < 0;

  useEffect(() => {
    setCampaignInfo([
      {
        label: "Name",
        value: name,
        editable: role !== "parent" ? true : false,
        path: ["campaigns.$[campaign].name"],
        type: "string",
      },
      {
        label: "Start Date",
        value: unixDateConverter(startDate),
        editable: role !== "parent" && !campaignStarted ? true : false,
        path: ["campaigns.$[campaign].startDate"],
        noneTableContingencies: ["startDate"],
        type: "date",
      },
      {
        label: "End Date",
        value: unixDateConverter(endDate),
        editable: role !== "parent" ? true : false,
        path: ["campaigns.$[campaign].endDate"],
        noneTableContingencies: ["endDate"],
        type: "date",
      },
      {
        label: "Progress",
        suffix: goalType,
        value: progress.toLocaleString(),
        editable: role !== "parent" ? true : false,
        path: ["campaigns.$[campaign].progress"],
        noneTableContingencies: ["progress"],
        type: "number",
      },
      {
        label: "Goal",
        suffix: isNaN(goalCompleted) ? "" : goalType,
        value: goalCompleted.toLocaleString(),
        editable: role !== "parent" && !isNaN(goalCompleted) ? true : false,
        path: ["campaigns.$[campaign].goalCompleted"],
        noneTableContingencies: ["goalCompleted"],
        type: "number",
      },
    ]);
  }, [progress]);

  const route = "/update_campaign_stats";
  const arrayFilters = campaignArrayFilter;
  const onSaveHandler = ({
    itemId,
    path,
    newValue,
    noneTableContingencies,
  }) => {
    updateCampaignContingencies(
      setCurrentCampaign,
      noneTableContingencies,
      newValue
    );
    updateInfo(route, itemId, path, newValue, arrayFilters);
  };

  return (
    <>
      {campaignInfo && (
        <InfoTable
          tableTitle={"Campaign Information"}
          tableInfo={campaignInfo}
          onSaveHandler={onSaveHandler}
        />
      )}
    </>
  );
};

export default CampaignInfoTable;
